import showToast from '@/mixins/showToast'
import {
  required, helpers, numeric, integer, email,
} from 'vuelidate/lib/validators'

export default {
  mixins: [showToast],
  data() {
    return {
      decimalPlaces: 2,
      states: [
        'AK',
        'AL',
        'AR',
        'AS',
        'AZ',
        'CO',
        'CA',
        'CT',
        'DC',
        'DE',
        'FL',
        'FM',
        'GA',
        'GU',
        'HI',
        'IA',
        'ID',
        'IL',
        'IN',
        'KS',
        'KY',
        'LA',
        'MA',
        'MD',
        'ME',
        'MH',
        'MI',
        'MN',
        'MO',
        'MP',
        'MS',
        'MT',
        'NC',
        'ND',
        'NE',
        'NH',
        'NJ',
        'NM',
        'NV',
        'NY',
        'OH',
        'OK',
        'OR',
        'PA',
        'PR',
        'PW',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VA',
        'VI',
        'VT',
        'WA',
        'WI',
        'WV',
        'WY',
      ],
      saving: false,
      // applicationStatuses: [
      //   'Started',
      //   'Waiting For Review',
      //   'Submitted',
      //   'Title Work Ordered',
      //   'Initial Disclosures Signed',
      //   'Appraisal Ordered',
      //   'File Submitted to Final Underwriting Review',
      //   'Clear to Close',
      //   'Closing Disclosure Sent',
      //   'Closed',
      // ],
      sectionStatuses: [
        'Approved',
        'Has errors',
        'Need more data',
      ],
      loanApplication: {
        id: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        alternative_names: null,
        credit_type: null,
        marital_status: null,
        number_of_dependants: null,
        dependants_ages: [],
        social_security_number: null,
        number_of_borrowers: null,
        initials: null,
        is_tin: false,
        date_of_birth: null,
        citizenship: null,
        current_address_years: null,
        current_address_months: null,
        housing: null,
        rent_per_month: null,
        other_borrower_names: null,
        applicant_home_phone: null,
        applicant_cell_phone: null,
        applicant_work_phone: null,
        applicant_ext: null,
        applicant_email: null,
        applicant_street: null,
        applicant_unit: null,
        applicant_city: null,
        applicant_state: null,
        applicant_zip: null,
        applicant_country: null,
        former_address_applies: false,
        former_street: null,
        former_unit: null,
        former_city: null,
        former_state: null,
        former_zip: null,
        former_country: null,
        former_address_years: null,
        former_address_months: null,
        former_housing: null,
        former_rent_per_month: null,
        mailing_address_applies: false,
        mailing_street: null,
        mailing_unit: null,
        mailing_city: null,
        mailing_state: null,
        mailing_zip: null,
        mailing_country: null,
        business_name: null,
        business_phone: null,
        business_street: null,
        business_unit: null,
        business_city: null,
        business_state: null,
        business_zip: null,
        business_country: null,
        business_position: null,
        business_start_date: null,
        business_duration_years: null,
        business_duration_months: null,
        employed_by_family: false,
        business_owner: false,
        business_stake: null,
        business_monthly_income: null,
        business_base: null,
        business_overtime: null,
        business_bonus: null,
        business_commission: null,
        business_military_entitlement: null,
        business_other: null,
        business_gross_income_total: null,
        current_employment_applies: false,
        additional_business_name: null,
        additional_business_phone: null,
        additional_business_street: null,
        additional_business_unit: null,
        additional_business_city: null,
        additional_business_state: null,
        additional_business_zip: null,
        additional_business_country: null,
        additional_business_title: null,
        additional_business_start_date: null,
        additional_business_duration_years: null,
        additional_business_duration_months: null,
        additional_business_employed_by_family: false,
        additional_business_owner: false,
        additional_business_stake: null,
        additional_business_monthly_income: null,
        additional_business_base: null,
        additional_business_overtime: null,
        additional_business_bonus: null,
        additional_business_commission: null,
        additional_business_military_entitlement: null,
        additional_business_other: null,
        additional_business_gross_income_total: null,
        additional_employment_applies: false,
        previous_business_name: null,
        previous_business_phone: null,
        previous_business_street: null,
        previous_business_unit: null,
        previous_business_city: null,
        previous_business_state: null,
        previous_business_zip: null,
        previous_business_country: null,
        previous_business_title: null,
        previous_business_start_date: null,
        previous_business_end_date: null,
        previous_business_owner: false,
        previous_business_income: null,
        previous_employment_applies: false,
        other_income_sources: [],
        total_monthly_income: null,
        other_income_source_applies: false,
        assets: [],
        total_account_cash_value: null,
        other_assets: [],
        total_asset_cash_value: null,
        liabilities: [],
        other_liabilities: [],
        other_assets_applies: true,
        liabilities_applies: false,
        other_liabilities_applies: false,
        i_own_a_property: false,
        property_address: null,
        property_unit: null,
        property_city: null,
        property_state: null,
        property_zip: null,
        property_country: null,
        property_value: null,
        property_status: null,
        property_intended_occupancy: null,
        property_monthly_insurance: null,
        property_monthly_rental_income: null,
        property_net_monthly_rental_income: null,
        property_loan_applies: false,
        property_mortgage_loans: null,
        property_one_address: null,
        property_one_unit: null,
        property_one_city: null,
        property_one_state: null,
        property_one_zip: null,
        property_one_country: null,
        property_one_value: null,
        property_one_status: null,
        property_one_intended_occupancy: null,
        property_one_monthly_insurance: null,
        property_one_monthly_rental_income: null,
        property_one_net_monthly_rental_income: null,
        property_one_loan_applies: false,
        property_one_mortgage_loans: null,
        property_one_applies: false,
        property_two_address: null,
        property_two_unit: null,
        property_two_city: null,
        property_two_state: null,
        property_two_zip: null,
        property_two_country: null,
        property_two_value: null,
        property_two_status: null,
        property_two_intended_occupancy: null,
        property_two_monthly_insurance: null,
        property_two_monthly_rental_income: null,
        property_two_net_monthly_rental_income: null,
        property_two_loan_applies: false,
        property_two_mortgage_loans: null,
        property_two_applies: false,
        property_loans: [],
        property_one_loans: null,
        property_two_loans: null,
        loan_amount: null,
        loan_purpose: null,
        purpose: null,
        property_address_street: null,
        property_address_unit: null,
        property_address_city: null,
        property_address_state: null,
        property_address_zip: null,
        property_address_country: null,
        property_number_of_units: null,
        loan_property_value: null,
        occupancy: null,
        fha_sec_residence: null,
        use_property_space_for_business: null,
        is_manufactured_home: null,
        creditor_name_one: null,
        list_type_one: null,
        loan_monthly_payment_one: null,
        amount_to_be_withdrawn_one: null,
        loan_credit_limit_one: null,
        creditor_name_two: null,
        list_type_two: null,
        loan_monthly_payment_two: null,
        amount_to_be_withdrawn_two: null,
        loan_credit_limit_two: null,
        other_new_mortgage_applies: false,
        expected_monthly_rental_income: null,
        expected_net_monthly_rental_income: null,
        rental_income_applies: false,
        gifts_asset_type_one: null,
        gifts_deposit_type_one: null,
        gifts_sources_one: null,
        gifts_cash_value_one: null,
        gifts_asset_type_two: null,
        gifts_deposit_type_two: null,
        gifts_sources_two: null,
        gifts_cash_value_two: null,
        gifts_applies: false,
        sec_five_a: null,
        have_ownership_interest: false,
        type_of_property_owned: null,
        property_join_type: null,
        sec_five_b: null,
        sec_five_c: null,
        amount_of_money: null,
        sec_five_d: null,
        sec_five_d_2: null,
        sec_five_e: null,
        sec_five_f: null,
        sec_five_g: null,
        sec_five_h: null,
        sec_five_i: null,
        sec_five_j: null,
        sec_five_k: null,
        sec_five_l: null,
        sec_five_m: null,
        bankruptcy: [],
        organization_name: null,
        organization_address: null,
        loan_originator: null,
        state_license: null,
        loan_originator_name: null,
        loan_originator_one: null,
        state_license_one: null,
        organization_email: null,
        organization_phone: null,
        military_service: null,
        duty_expiration_date: null,
        serving_on_active_duty: false,
        retired: null,
        national_guard: null,
        serving_spouse: null,
        ethnicity: [],
        latino_ethnicity: [],
        other_ethnicity: null,
        borrower_sex: null,
        principal_tribe: null,
        race_asian: [],
        other_asian: null,
        race: [],
        race_hawain: [],
        ethnicity_observation: null,
        sex_observation: null,
        race_observation: null,
        demographic_info_provided_through: null,
        other_race: null,
        attachments: [],
        section_one_status: null,
        section_one_comments: null,
        section_two_status: null,
        section_two_comments: null,
        section_three_status: null,
        section_three_comments: null,
        section_four_status: null,
        section_four_comments: null,
        section_five_status: null,
        section_five_comments: null,
        section_seven_status: null,
        section_seven_comments: null,
        section_eight_status: null,
        section_eight_comments: null,
      },
    }
  },
  validations() {
    return {
      loanApplication: {
        id: { required },
        first_name: { required },
        last_name: { required },
        alternative_names: { },
        credit_type: { required },
        marital_status: { required },
        number_of_dependants: {
          required,
          integer,
        },
        dependants_ages: {
          $each: {
            age: {
              required,
              integer,
            },
          },
        },
        current_address_years: { required },
        current_address_months: { required },
        housing: this.loanApplication.housing === 'rent' ? { required } : {},
        rent_per_month: this.loanApplication.housing === 'rent' ?{ required }:{},
        social_security_number: { required },
        number_of_borrowers: this.loanApplication.credit_type === 'joint' ? {
          required,
          integer,
        } : {},
        initials: this.loanApplication.credit_type === 'joint' ? { required } : {},
        is_tin: { required },
        date_of_birth: { required },
        citizenship: { required },
        other_borrower_names: this.loanApplication.credit_type === 'joint' ? { required } : {},
        applicant_home_phone: { required },
        applicant_cell_phone: { required },
        applicant_work_phone: { required },
        applicant_ext: { },
        applicant_email: {
          required,
          email,
        },
        applicant_street: { required },
        applicant_unit: { },
        applicant_city: { required },
        applicant_state: { required },
        applicant_zip: { required },
        applicant_country: { required },
        former_address_applies: {  },
        former_street: this.loanApplication.former_address_applies ? { required } : {},
        former_unit: this.loanApplication.former_address_applies ? { } : {},
        former_city: this.loanApplication.former_address_applies ? { required } : {},
        former_state: this.loanApplication.former_address_applies ? { required } : {},
        former_zip: this.loanApplication.former_address_applies ? { required } : {},
        former_country: this.loanApplication.former_address_applies ? { required } : {},
        former_address_years: this.loanApplication.former_address_applies ? { required } : {},
        former_address_months: this.loanApplication.former_address_applies ? { required } : {},
        former_housing: this.loanApplication.former_address_applies ? { required } : {},
        former_rent_per_month: this.loanApplication.former_address_applies ? { required } : {},
        mailing_address_applies: {  },
        mailing_street: this.loanApplication.mailing_address_applies ? { required } : {},
        mailing_unit: this.loanApplication.mailing_address_applies ? { } : {},
        mailing_city: this.loanApplication.mailing_address_applies ? { required } : {},
        mailing_state: this.loanApplication.mailing_address_applies ? { required } : {},
        mailing_zip: this.loanApplication.mailing_address_applies ? { required } : {},
        mailing_country: this.loanApplication.mailing_address_applies ? { required } : {},
        business_name: this.loanApplication.current_employment_applies ? { required } : {},
        business_phone: this.loanApplication.current_employment_applies ? { required } : {},
        business_street: this.loanApplication.current_employment_applies ? { required } : {},
        business_unit: this.loanApplication.current_employment_applies ? { } : {},
        business_city: this.loanApplication.current_employment_applies ? { required } : {},
        business_state: this.loanApplication.current_employment_applies ? { required } : {},
        business_zip: this.loanApplication.current_employment_applies ? { required } : {},
        business_country: this.loanApplication.current_employment_applies ? { required } : {},
        business_position: this.loanApplication.current_employment_applies ? { required } : {},
        business_start_date: this.loanApplication.current_employment_applies ? { required } : {},
        business_duration_years: this.loanApplication.current_employment_applies ? { required } : {},
        business_duration_months: this.loanApplication.current_employment_applies ? { required } : {},
        employed_by_family: this.loanApplication.current_employment_applies ? { required } : {},
        business_owner: this.loanApplication.current_employment_applies ? { required } : {},
        business_stake: this.loanApplication.business_owner && this.loanApplication.current_employment_applies ? { required } : {},
        business_monthly_income: this.loanApplication.business_owner && this.loanApplication.current_employment_applies ? {
          required,
          numeric,
        } : {},
        business_base: this.loanApplication.current_employment_applies ? { required } : {},
        business_overtime: this.loanApplication.current_employment_applies ? { required } : {},
        business_bonus: this.loanApplication.current_employment_applies ? { required } : {},
        business_commission: this.loanApplication.current_employment_applies ? { required } : {},
        business_military_entitlement: this.loanApplication.current_employment_applies ? { required } : {},
        business_other: this.loanApplication.current_employment_applies ? { required } : {},
        business_gross_income_total: this.loanApplication.current_employment_applies ? { required } : {},
        current_employment_applies: {  },
        additional_business_name: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_phone: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_street: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_unit: this.loanApplication.additional_employment_applies ? { } : {},
        additional_business_city: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_state: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_zip: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_country: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_title: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_start_date: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_duration_years: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_duration_months: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_employed_by_family: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_owner: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_stake: this.loanApplication.additional_business_owner ? { required } : {},
        additional_business_monthly_income: this.loanApplication.additional_business_owner ? {
          required,
          numeric,
        } : {},
        additional_business_base: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_overtime: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_bonus: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_commission: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_military_entitlement: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_other: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_business_gross_income_total: this.loanApplication.additional_employment_applies ? { required } : {},
        additional_employment_applies: {  },
        previous_business_name: this.loanApplication.previous_employment_applies ? { required } : {},
        previous_business_phone: this.loanApplication.previous_employment_applies ? { required } : {},
        previous_business_street: this.loanApplication.previous_employment_applies ? { required } : {},
        previous_business_unit: this.loanApplication.previous_employment_applies ? { } : {},
        previous_business_city: this.loanApplication.previous_employment_applies ? { required } : {},
        previous_business_state: this.loanApplication.previous_employment_applies ? { required } : {},
        previous_business_zip: this.loanApplication.previous_employment_applies ? { required } : {},
        previous_business_country: this.loanApplication.previous_employment_applies ? { required } : {},
        previous_business_title: this.loanApplication.previous_employment_applies ? { required } : {},
        previous_business_start_date: this.loanApplication.previous_employment_applies ? { required } : {},
        previous_business_end_date: this.loanApplication.previous_employment_applies ? { required } : {},
        previous_business_owner: this.loanApplication.previous_employment_applies ? { required } : {},
        previous_business_income: this.loanApplication.previous_employment_applies ? { required } : {},
        previous_employment_applies: {  },
        other_income_sources: this.loanApplication.other_income_source_applies ? {
          $each: {
            income: {
              required,
              numeric,
            },
            source: { required },
          },
        } : {},
        total_monthly_income: this.loanApplication.other_income_source_applies ? {
          required,
          numeric,
        } : {},
        other_income_source_applies: this.loanApplication.other_income_source_applies ? { required } : {},
        assets: {
          $each: {
            account_type: { required },
            financial_institution: { required },
            account_number: {
              required,
              integer,
            },
            market_value: {
              required,
              numeric,
            },
          },
        },
        total_account_cash_value: { required },
        other_assets: this.loanApplication.other_assets_applies ? {
          $each: {
            asset_type: { required },
            cash_value: { required },
          },
        } : {},
        total_asset_cash_value: this.loanApplication.other_assets_applies ? { required } : {},
        liabilities: this.loanApplication.liabilities_applies ? {
          $each: {
            account_type: { required },
            company_name: { required },
            account_number: {
              required,
              integer,
            },
            unpaid_balance: {
              required,
              numeric,
            },
            paid_of_before_closing: { required },
            monthly_payment: {
              required,
              numeric,
            },
          },
        } : {},
        other_liabilities: this.loanApplication.other_liabilities_applies ? {
          $each: {
            type: { required },
            monthly_payment: { required },
          },
        } : {},
        other_assets_applies: { required },
        liabilities_applies: { required },
        other_liabilities_applies: { required },
        i_own_a_property: { required },
        property_address: this.loanApplication.i_own_a_property ? { required } : {},
        property_unit: this.loanApplication.i_own_a_property ? { } : {},
        property_city: this.loanApplication.i_own_a_property ? { required } : {},
        property_state: this.loanApplication.i_own_a_property ? { required } : {},
        property_zip: this.loanApplication.i_own_a_property ? { required } : {},
        property_country: this.loanApplication.i_own_a_property ? { required } : {},
        property_value: this.loanApplication.i_own_a_property ? { required } : {},
        property_status: this.loanApplication.i_own_a_property ? { required } : {},
        property_intended_occupancy: this.loanApplication.i_own_a_property ? { required } : {},
        property_monthly_insurance: this.loanApplication.i_own_a_property ? { required } : {},
        property_monthly_rental_income: this.loanApplication.i_own_a_property ? { required } : {},
        property_net_monthly_rental_income: this.loanApplication.i_own_a_property ? { required } : {},
        property_loan_applies: { required },
        // property_mortgage_loans: { required },
        property_one_address: this.loanApplication.property_one_applies ? { required } : {},
        property_one_unit: this.loanApplication.property_one_applies ? { } : {},
        property_one_city: this.loanApplication.property_one_applies ? { required } : {},
        property_one_state: this.loanApplication.property_one_applies ? { required } : {},
        property_one_zip: this.loanApplication.property_one_applies ? { required } : {},
        property_one_country: this.loanApplication.property_one_applies ? { required } : {},
        property_one_value: this.loanApplication.property_one_applies ? { required } : {},
        property_one_status: this.loanApplication.property_one_applies ? { required } : {},
        property_one_intended_occupancy: this.loanApplication.property_one_applies ? { required } : {},
        property_one_monthly_insurance: this.loanApplication.property_one_applies ? { required } : {},
        property_one_monthly_rental_income: this.loanApplication.property_one_applies ? { required } : {},
        property_one_net_monthly_rental_income: this.loanApplication.property_one_applies ? { required } : {},
        property_one_loan_applies: { required },
        // property_one_mortgage_loans: { required },
        property_one_applies: this.loanApplication.property_two_applies ? { required } : {},
        property_two_address: this.loanApplication.property_two_applies ? { required } : {},
        property_two_unit: this.loanApplication.property_two_applies ? { } : {},
        property_two_city: this.loanApplication.property_two_applies ? { required } : {},
        property_two_state: this.loanApplication.property_two_applies ? { required } : {},
        property_two_zip: this.loanApplication.property_two_applies ? { required } : {},
        property_two_country: this.loanApplication.property_two_applies ? { required } : {},
        property_two_value: this.loanApplication.property_two_applies ? { required } : {},
        property_two_status: this.loanApplication.property_two_applies ? { required } : {},
        property_two_intended_occupancy: this.loanApplication.property_two_applies ? { required } : {},
        property_two_monthly_insurance: this.loanApplication.property_two_applies ? { required } : {},
        property_two_monthly_rental_income: this.loanApplication.property_two_applies ? { required } : {},
        property_two_net_monthly_rental_income: this.loanApplication.property_two_applies ? { required } : {},
        property_two_loan_applies: this.loanApplication.property_two_applies ? { required } : {},
        // property_two_mortgage_loans: { required },
        property_two_applies: { required },
        property_loans: this.loanApplication.property_loan_applies ? {
          $each: {
            creditor_name: { required },
            account_number: {
              required,
              integer,
            },
            monthly_mortgage_payment: {
              required,
              numeric,
            },
            unpaid_balance: {
              required,
              numeric,
            },
            type: { required },
            credit_limit: {
              required,
              numeric,
            },
            to_be_paid_off_before_closing: { required },
          },
        } : {},
        property_one_loans: this.loanApplication.property_one_loan_applies ? {
          $each: {
            creditor_name: { required },
            account_number: {
              required,
              integer,
            },
            monthly_mortgage_payment: {
              required,
              numeric,
            },
            unpaid_balance: {
              required,
              numeric,
            },
            type: { required },
            credit_limit: {
              required,
              numeric,
            },
            to_be_paid_off_before_closing: { required },
          },
        } : {},
        property_two_loans: this.loanApplication.property_two_loan_applies ? {
          $each: {
            creditor_name: { required },
            account_number: {
              required,
              integer,
            },
            monthly_mortgage_payment: {
              required,
              numeric,
            },
            unpaid_balance: {
              required,
              numeric,
            },
            type: { required },
            credit_limit: {
              required,
              numeric,
            },
            to_be_paid_off_before_closing: { required },
          },
        } : {},
        loan_amount: { required },
        loan_purpose: { required },
        purpose: this.loanApplication.loan_purpose === 'Other' ? { required } : {},
        property_address_street: { required },
        property_address_unit: { },
        property_address_city: { required },
        property_address_state: { required },
        property_address_zip: { required },
        property_address_country: { required },
        property_number_of_units: {
          required,
          integer,
        },
        loan_property_value: {
          required,
          numeric,
        },
        occupancy: { required },
        fha_sec_residence: {  },
        use_property_space_for_business: { required },
        is_manufactured_home: { required },
        creditor_name_one: this.loanApplication.other_new_mortgage_applies ? { required } : {},
        list_type_one: this.loanApplication.other_new_mortgage_applies ? { required } : {},
        list_type_two: this.loanApplication.other_new_mortgage_applies ? { required } : {},
        loan_monthly_payment_one: this.loanApplication.other_new_mortgage_applies ? {
          required,
          numeric,
        } : {},
        amount_to_be_withdrawn_one: this.loanApplication.other_new_mortgage_applies ? {
          required,
          numeric,
        } : {},
        loan_credit_limit_one: this.loanApplication.other_new_mortgage_applies ? {
          required,
          numeric,
        } : {},
        creditor_name_two: this.loanApplication.other_new_mortgage_applies ? { } : {},
        loan_monthly_payment_two: this.loanApplication.other_new_mortgage_applies ? { } : {},
        amount_to_be_withdrawn_two: this.loanApplication.other_new_mortgage_applies ? { } : {},
        loan_credit_limit_two: this.loanApplication.other_new_mortgage_applies ? { } : {},
        other_new_mortgage_applies: {  },
        expected_monthly_rental_income: this.loanApplication.rental_income_applies ? {
          required,
          numeric,
        } : {},
        expected_net_monthly_rental_income: this.loanApplication.rental_income_applies ? {
          required,
          numeric,
        } : {},
        rental_income_applies: {  },
        gifts_asset_type_one: this.loanApplication.gifts_applies ? { required } : {},
        gifts_deposit_type_one: this.loanApplication.gifts_applies ? { required } : {},
        gifts_sources_one: this.loanApplication.gifts_applies ? { required } : {},
        gifts_cash_value_one: this.loanApplication.gifts_applies ? {
          required,
          numeric,
        } : {},
        gifts_asset_type_two: this.loanApplication.gifts_applies ? { } : {},
        gifts_deposit_type_two: this.loanApplication.gifts_applies ? { } : {},
        gifts_sources_two: this.loanApplication.gifts_applies ? { } : {},
        gifts_cash_value_two: this.loanApplication.gifts_applies ? {
          numeric,
        } : {},
        gifts_applies: {  },
        sec_five_a: { required },
        have_ownership_interest: this.loanApplication.sec_five_a === 'YES' ? { required } : {},
        type_of_property_owned: this.loanApplication.have_ownership_interest === 'YES' ? { required } : {},
        property_join_type: this.loanApplication.have_ownership_interest === 'YES' ? { required } : {},
        sec_five_b: { required },
        sec_five_c: { required },
        amount_of_money: this.loanApplication.sec_five_c === 'YES' ? {
          required,
          numeric,
        } : {},
        sec_five_d: { required },
        sec_five_d_2: { required },
        sec_five_e: { required },
        sec_five_f: { required },
        sec_five_g: { required },
        sec_five_h: { required },
        sec_five_i: { required },
        sec_five_j: { required },
        sec_five_k: { required },
        sec_five_l: { required },
        sec_five_m: { required },
        bankruptcy: this.loanApplication.sec_five_m === 'YES' ? { required } : {},
        // organization_name: { required },
        // organization_address: { required },
        // loan_originator: { required },
        // state_license: { required },
        // loan_originator_name: { required },
        // loan_originator_one: { required },
        // state_license_one: { required },
        // organization_email: {
        //   required,
        //   email,
        // },
        // organization_phone: { required },
        military_service: { required },
        duty_expiration_date: this.loanApplication.military_service === 'YES' ? { required } : {},
        serving_on_active_duty: { required },
        retired: { required },
        national_guard: { required },
        serving_spouse: { required },
        // ethnicity: { required },
        // latino_ethnicity: this.loanApplication.ethnicity.includes('latino') ? { required } : {},
        // other_ethnicity: { required },
        // borrower_sex: { required },
        // principal_tribe: this.loanApplication.race.includes('american') ? { required } : {},
        // race_asian: this.loanApplication.race.includes('asian') ? { required } : {},
        // other_asian: { required },
        // race: { required },
        // race_hawain: this.loanApplication.race.includes('pacific') ? { required } : {},
        // other_race: this.loanApplication.race_hawain.includes('Other Pacific Islander – Print race:') ? { required } : {},
        // section_one_status: { required },
        // section_one_comments: { required },
        // section_two_status: { required },
        // section_two_comments: { required },
        // section_three_status: { required },
        // section_three_comments: { required },
        // section_four_status: { required },
        // section_four_comments: { required },
        // section_five_status: { required },
        // section_five_comments: { required },
        // section_seven_status: { required },
        // section_seven_comments: { required },
        // section_eight_status: { required },
        // section_eight_comments: { required },
      },
    }
  },
  methods: {
    parseToFloat(value) {
      return parseFloat(value).toFixed(this.decimalPlaces)
    },
    getVariant(status) {
      switch (status) {
        case 'Approved':
          return 'success'
        case 'Has errors':
          return 'danger'
        default:
          return 'warning'
      }
    },
    saveChangesToServer() {
      this.saving = true
      this.$store.dispatch('auth/updateApplication')
        .then(res => {
          this.saving = false
          this.showSuccess('Saved successfully')
        })
        .catch(err => {
          this.saving = false
          this.showError('Failed to save application')
        })
    },
  },
}
