<template>
  <b-modal
    v-model="showModal"
    :centered="centered"
    content-class="shadow"
    :title="title"
    :ok-title="okText"
    :cancel-title="cancelText"
    :hide-footer="hideFooter"
    :size="size"
    :modal-class="`modal-${variant}`"
    :ok-disabled="okDisabled"
    @ok="handleOkClick"
    @hide="handleClose"
  >
    <b-overlay
      :show="showOverlay"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
      variant="white"
      opacity="0.8"
    >
      <b-card-text style="max-height: 70vh; overflow-y: auto; overflow-x: hidden;">
        <slot />
        <div style="visibility: hidden">
          Empty Space
        </div>
      </b-card-text>
    </b-overlay>
    <template slot="modal-footer">
      <slot name="modal-actions">
        <b-row class="float-right">
          <hwa-button
            v-if="!okOnly"
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            :label="cancelText"
            @onClick="handleClose"
          />
          <hwa-button
            :label="okText"
            :disabled="okDisabled"
            :loading="loading"
            :loading-text="loadingText"
            @onClick="handleOkClick"
          />
        </b-row>
      </slot>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BCardText, BRow, BOverlay,
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'

export default {
  name: 'HwaModal',
  components: {
    HwaButton,
    BModal,
    BCardText,
    BRow,
    BOverlay,
    // BCol,
  },
  props: {
    title: { type: String, default: 'Modal' },
    okText: { type: String, default: 'Yes' },
    loadingText: { type: String, default: 'Loading...' },
    cancelText: { type: String, default: 'Cancel' },
    size: { type: String, default: 'sm' },
    variant: { type: String, default: 'primary' },
    okOnly: { type: Boolean, default: false },
    hideFooter: { type: Boolean, default: false },
    okDisabled: { type: Boolean, default: false },
    show: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    showOverlay: { type: Boolean, default: false },
    centered: { type: Boolean, default: true },
    is_data_change: { type: Boolean, default: false },
  },
  data() {
    return {
      showModal: false,
    }
  },
  watch: {
    show(val) {
      this.showModal = val
    },
  },
  methods: {
    handleOkClick() {
      this.$emit('onOk')
    },
    handleClose(e) {
      if (this.is_data_change === true) {
        e.preventDefault()
      }
      this.$emit('onClose')
    },
  },
}
</script>

<style scoped>
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /*background: #ffffff;*/
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    /*background: #d2a761;*/
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #d2a761;
    border-radius: 10px;
  }
</style>
