<template>
  <div>

    <div class="row">
      <div
        class="col-md-12 d-flex justify-content-between mb-3"
      >
        <h3>Notes</h3>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-primary"
          @click="openAddNoteModal"
        >
          New Note
        </b-button>
        <!-- <div
          class="note_card__box plus-box"
          @click="openAddNoteModal"
        >
          <div class="img-box  plus-icon">
            <feather-icon
              icon="PlusIcon"
              size="60"
            />
          </div>
          <span class="title_text">New Note</span>
        </div> -->
      </div>

      <div
        v-for="(item, index) in notes"
        :key="index"
        class="col-md-12 mb-2"
        @click.stop="showNote(item)"
      >
        <div class="note_card__box">
          <div
            v-b-tooltip.top="'delete note'"
            class="delete_icon"
            @click.stop="deleteNote(item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </div>

          <h3>{{ item.title }}</h3>

          <div
            class="mt-2"
            v-html="item.notes"
          />

          <div class="created_by d-flex mt-3">
            <h5 class="text-primary">
              Created By: {{ item.created_by && item.created_by.first_name }} {{ item.created_by && item.created_by.last_name }}
            </h5>
            <h5 class="ml-2 text-primary">
              Updated At: {{ item.updated_at }}
            </h5>
          </div>
          <!-- <div
            class="img-box"
            @click="showNote(item)"
          >
            <img
              :src="fileImage"
              alt="file"
              class="img-fluid file_image"
            >
          </div> -->
          <!-- <span
            class="title_text"
            @click="showNote(item)"
          >{{ item.title | setTruncate(20, "...") }}</span> -->
        </div>
      </div>
    </div>

    <b-modal
      id="new-note-modal"
      title="New Notes"
      hide-footer
      size="huge"
    >
      <validation-observer ref="simpleRules">

        <validation-provider
          #default="{ errors }"
          name="Title"
          rules="required"
        >
          <b-form-input
            v-model="note_title"
            :state="errors.length > 0 ? false:null"
            placeholder="Title"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

        <div
          id="app"
          class="mt-1"
        >
          <div class="document-editor__toolbar" />
          <div class="document-editor__editable-container">
            <ckeditor
              v-model="note"
              :editor="editor"
              @ready="onReady"
            />
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('new-note-modal')"
          >
            Cancel
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-primary"
            @click="validationForm"
          >
            Save
          </b-button>
        </div>
      </validation-observer>
    </b-modal>

    <b-modal
      id="edit-note-modal"
      title="Edit Note"
      size="huge"
      scrollable
      @hide="hideModalDetails"
    >
      <div id="app">
        <div class="document-editor__toolbar" />
        <div class="document-editor__editable-container">
          <ckeditor
            id="hwa-cke-editor"
            v-model="note"
            :editor="editor"
            @ready="onReady"
          />
        </div>
      </div>

      <template #modal-footer>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <!-- Button with custom close trigger value -->
        <div class="note-modal-footer">
          <div
            v-if="!enable_edit_title_form"
            class="content-view"
            @click="editTitle"
          >
            <img
              :src="fileImage"
              alt="file"
              class="img-fluid file_image"
            >
            <span
              v-b-tooltip.top="'click to edit title'"
            >{{ note_details.title | setTruncate(20, "...") }}</span>
          </div>

          <div
            v-if="enable_edit_title_form"
            class="edit-form"
          >
            <b-form-input
              v-model="note_title"
              size="sm"
            />
          </div>
        </div>

        <div
          v-if="!enable_edit_title_form"
          class="d-flex align-items-center"
          style="flex:1; font-size: 14px;"
        >
          <h5 class="text-primary">
            Created By: {{ note_details.created_by && note_details.created_by.first_name }} {{ note_details.created_by && note_details.created_by.last_name }}
          </h5>
          <h5 class="ml-2 text-primary">
            Updated At: {{ note_details.updated_at }}
          </h5>
        </div>

        <b-button
          v-if="!enable_edit_title_form"
          size=""
          variant="outline-success"
          @click="updateNote"
        >
          Save Changes
        </b-button>
        <div
          v-if="enable_edit_title_form"
          class="d-flex align-items-center"
        >
          <b-button
            size="sm"
            variant="outline-secondary"
            class="mr-1"
            @click="closeEditTitle"
          >
            Cancel
          </b-button>
          <b-button
            size="sm"
            variant="outline-success"
            @click="updateNote"
          >
            Save
          </b-button>
        </div>

      </template>
    </b-modal>

  </div>
</template>

<script>

import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import {
  VBTooltip, BButton, BModal, BFormInput,
} from 'bootstrap-vue'
// import HwaInput from '@/layouts/components/HwaInput.vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment'
import fileImage from '../../../assets/images/filetxt.png'

export default {
  components: {
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: ['mortgage_id', 'application'],
  data() {
    return {
      editor: DecoupledEditor,
      note: '',
      fileImage,
      note_title: '',
      required,
      enable_edit_title_form: false,
      note_details: null,
      mortgage: {},
    }
  },
  computed: {
    notes: {
      get() {
        return this.$store.state.notes.notes
      },
      set(value) {
        return value
      },
    },
    user() {
      return this.$store.state.auth.auth.user
    },
  },
  mounted() {
    this.mortgage = this.application
    this.loadNote()
  },
  methods: {
    loadNote() {
      this.$store.dispatch('notes/allNotes', this.mortgage_id)
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          const data = {
            title: this.note_title,
            mortgage_id: this.mortgage_id,
            notes: this.note,
            created_by: this.user.id,
          }
          this.$store.dispatch('notes/storeNote', data).then(res => {
            this.loadNote()
            this.note_title = ''
            // this.note = '<p>Write your note here</p>'
            this.$bvModal.hide('new-note-modal')
          })
        }
      })
    },
    openAddNoteModal() {
      this.$bvModal.show('new-note-modal')
    },
    date(data) {
      return moment(data).format('ddd DD-MMM-YY')
    },
    hideModalDetails() {
      this.note_title = ''
    },
    updateNote() {
      const data = this.note_details
      data.title = this.note_title
      data.notes = this.note
      this.$store.dispatch('notes/updateNote', data).then(res => {
        this.note_details = data
        this.enable_edit_title_form = false
      })
    },
    deleteNote(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('notes/deleteNote', id).then(res => {
            this.loadNote()
          })
        }
      })
    },
    editTitle() {
      this.enable_edit_title_form = true
    },
    closeEditTitle() {
      this.enable_edit_title_form = false
    },
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement(),
      )
    },
    showNote(data) {
      this.note_details = data
      this.note_title = data.title
      this.note = data.notes
      this.$bvModal.show('edit-note-modal')
    },
  },
}
</script>

<style scoped>
    .document-editor {
        border: 1px solid var(--ck-color-base-border);
        border-radius: var(--ck-border-radius);

        /* Set vertical boundaries for the document editor. */
        max-height: 700px;

        /* This element is a flex container for easier rendering. */
        display: flex;
        flex-flow: column nowrap;
    }

    .document-editor__toolbar {
        /* Make sure the toolbar container is always above the editable. */
        z-index: 1;

        /* Create the illusion of the toolbar floating over the editable. */
        box-shadow: 0 0 5px hsla( 0,0%,0%,.2 );

        /* Use the CKEditor CSS variables to keep the UI consistent. */
        border-bottom: 1px solid var(--ck-color-toolbar-border);
    }

    /* Adjust the look of the toolbar inside the container. */
    .document-editor__toolbar .ck-toolbar {
        border: 0;
        border-radius: 0;
    }

    /* Make the editable container look like the inside of a native word processor application. */
    .document-editor__editable-container {
        padding: calc( 2 * var(--ck-spacing-large) );
        background: var(--ck-color-base-foreground);

        /* Make it possible to scroll the "page" of the edited content. */
        overflow-y: auto;
    }

    .document-editor__editable-container .ck-editor__editable {
        /* Set the dimensions of the "page". */
        width: 28.8cm;
        min-height: 17cm;

        /* Keep the "page" off the boundaries of the container. */
        padding: 1cm 2cm 2cm;

        border: 1px hsl( 0,0%,82.7% ) solid;
        border-radius: var(--ck-border-radius);
        background: white;

        /* The "page" should cast a slight shadow (3D illusion). */
        box-shadow: 0 0 5px hsla( 0,0%,0%,.1 );

        /* Center the "page". */
        margin: 0 auto;
        margin-top: 20px;
    }

    /* Set the default font for the "page" of the content. */
    .document-editor .ck-content,
    .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    /* Adjust the headings dropdown to host some larger heading styles. */
    .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        line-height: calc( 1.7 * var(--ck-line-height-base) * var(--ck-font-size-base) );
        min-width: 6em;
    }

    /* Scale down all heading previews because they are way too big to be presented in the UI.
    Preserve the relative scale, though. */
    .document-editor .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
        transform: scale(0.8);
        transform-origin: left;
    }

    /* Set the styles for "Heading 1". */
    .document-editor .ck-content h2,
    .document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
        font-size: 2.18em;
        font-weight: normal;
    }

    .document-editor .ck-content h2 {
        line-height: 1.37em;
        padding-top: .342em;
        margin-bottom: .142em;
    }

    /* Set the styles for "Heading 2". */
    .document-editor .ck-content h3,
    .document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
        font-size: 1.75em;
        font-weight: normal;
        color: hsl( 203, 100%, 50% );
    }

    .document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
        color: var(--ck-color-list-button-on-text);
    }

    /* Set the styles for "Heading 2". */
    .document-editor .ck-content h3 {
        line-height: 1.86em;
        padding-top: .171em;
        margin-bottom: .357em;
    }

    /* Set the styles for "Heading 3". */
    .document-editor .ck-content h4,
    .document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
        font-size: 1.31em;
        font-weight: bold;
    }

    .document-editor .ck-content h4 {
        line-height: 1.24em;
        padding-top: .286em;
        margin-bottom: .952em;
    }

    /* Set the styles for "Paragraph". */
    .document-editor .ck-content p {
        font-size: 1em;
        line-height: 1.63em;
        padding-top: .5em;
        margin-bottom: 1.13em;
    }

    /* Make the block quoted text serif with some additional spacing. */
    .document-editor .ck-content blockquote {
        font-family: Georgia, serif;
        margin-left: calc( 2 * var(--ck-spacing-large) );
        margin-right: calc( 2 * var(--ck-spacing-large) );
    }

</style>
