<template>
  <div class="">
    <!--    <test-generate id="section_one"/>-->
    <b-card class="">
      <div class="mb-1 d-flex justify-content-between">
        <strong> Mortgage Files </strong>
        <!-- <hwa-button
          v-if="$can('create-new-mortgage-application','hwa')"
          icon="PlusIcon"
          label="New Mortgage"
          @onClick="openMortgageModal()"
        /> -->
        <hwa-button
          v-if="$can('create-new-mortgage-application', 'hwa')"
          icon="PlusIcon"
          label="New Mortgage File"
          @onClick="openMortgageModal()"
        />
      </div>
      <b-table
        ref="mortgageTable"
        small
        :fields="fields"
        :items="mortgages"
        responsive="sm"
        selectable
        select-mode="single"
        @row-clicked="onRowSelected"
      >
        <template #cell(application.application_id)="data">
          <span v-if="Boolean(data.item.marked_as_deleted) === false"> {{ data.item.application.application_id }}
          </span>
          <del
            v-else
            class="text-gray"
          > {{ data.item.application.application_id }} </del>
        </template>
        <template
          #cell(amount)="data"
          class="text-danger"
        >
          <span v-if="Boolean(data.item.marked_as_deleted) === false"> {{ formartCurrency(data.item.amount) }} </span>
          <del
            v-else
            class="text-gray"
          > {{ formartCurrency(data.item.amount) }} </del>
        </template>
        <template #cell(name)="data">
          <div class="notification_view_box">
            <span
              v-if="Boolean(data.item.marked_as_deleted) === false"
              class="name_view"
            >
              {{ data.item.first_name }}
              {{ data.item.last_name }}
            </span>
            <del
              v-else
              class="text-gray"
            > {{ data.item.first_name }}
              {{ data.item.last_name }} </del>
          </div>

        </template>
        <template #cell(status)="data">
          <span v-if="Boolean(data.item.marked_as_deleted) === false"> {{ data.item.status }} </span>
          <del
            v-else
            class="text-gray"
          > {{ data.item.status }} </del>
        </template>
        <template #cell(opened_date)="data">
          <div class="notification_view_box">
            <span v-if="Boolean(data.item.marked_as_deleted) === false"> {{ data.item.opened_date }} </span>
            <del
              v-else
              class="text-gray"
            > {{ data.item.opened_date }} </del>

            <div
              v-if="data.item.unread_messages !== 0"
              class="main_notify_box"
              style="margin-left:40px !important"
            >
              <feather-icon
                icon="BellIcon"
                size="22"
              />
              <div class="count_box">
                {{ data.item.unread_messages }}
              </div>

            </div>
          </div>

        </template>
      </b-table>
    </b-card>

    <b-modal
      id="mortgage-file-modal"
      :title="`File Number: ${application_id} - Borrower: ${mortgage.first_name} ${mortgage.last_name}`"
      hide-footer
      size="xl"
      @hide="handleMortgageFileModalClose"
    >
      <b-tabs
        v-model="tabIndex"
        content-class="mt-3"
        class="mortgage-tab"
      >
        <b-tab
          title="Overview"
          active
          :disabled="is_application_change ? true : false"
        >
          <!-- <div class="split_modal_page">
            <div class="body"></div>
            <div class="footer"></div>
          </div> -->
          <!-- overview content start -->
          <div class="split_modal_page">
            <div class="body">
              <div class="overview-box">
                <!-- overview item start -->
                <div class="overview-view-item">
                  <div class="icon-box">
                    <feather-icon
                      class="icon-view"
                      icon="HashIcon"
                      size="25"
                    />
                  </div>
                  <div class="content-view">
                    <h5>File Number</h5>
                    <span>{{ application_id }}</span>
                  </div>
                </div>
                <!-- overview item end -->

                <!-- overview item start -->
                <div class="overview-view-item">
                  <div class="icon-box">
                    <feather-icon
                      class="icon-view"
                      icon="AlertCircleIcon"
                      size="25"
                    />
                  </div>
                  <div class="content-view">
                    <h5>Status</h5>
                    <span>{{ mortgage.status }}</span>
                  </div>
                </div>
                <!-- overview item end -->

                <!-- overview item start -->
                <div class="overview-view-item">
                  <div class="icon-box">
                    <feather-icon
                      class="icon-view"
                      icon="CalendarIcon"
                      size="25"
                    />
                  </div>
                  <div class="content-view">
                    <h5>Status Date</h5>
                    <span>{{ mortgage.opened_date }}</span>
                  </div>
                </div>
                <!-- overview item end -->

                <!-- overview item start -->
                <div class="overview-view-item">
                  <div class="icon-box">
                    <feather-icon
                      class="icon-view"
                      icon="DollarSignIcon"
                      size="25"
                    />
                  </div>
                  <div class="content-view">
                    <h5>Loan Amount</h5>
                    <span>{{ formartCurrency(mortgage.amount) }}</span>
                  </div>
                </div>
                <!-- overview item end -->
              </div>
              <!-- overview content end -->

              <!-- overview content start -->
              <div class="overview-box">
                <!-- overview item start -->
                <div class="overview-view-item">
                  <div class="icon-box">
                    <feather-icon
                      class="icon-view"
                      icon="UserIcon"
                      size="25"
                    />
                  </div>
                  <div class="content-view">
                    <h5>Borrower</h5>
                    <span>{{ mortgage.first_name }} {{ mortgage.last_name }}</span>
                  </div>
                </div>
                <!-- overview item end -->
                <!-- overview item start -->
                <div
                  v-if="mortgage.loan_originator"
                  class="overview-view-item"
                >
                  <div class="icon-box">
                    <feather-icon
                      class="icon-view"
                      icon="UserIcon"
                      size="25"
                    />
                  </div>
                  <div class="content-view">
                    <h5>Loan Originator </h5>
                    <span>{{ mortgage.loan_originator.first_name }} {{ mortgage.loan_originator.last_name }}</span>
                  </div>
                </div>
                <!-- overview item end -->
                <!-- overview item start -->
                <div
                  v-if="mortgage.selected_licensee"
                  class="overview-view-item"
                >
                  <div class="icon-box">
                    <feather-icon
                      class="icon-view"
                      icon="UserIcon"
                      size="25"
                    />
                  </div>
                  <div class="content-view">
                    <h5> Real Estate Licensee </h5>
                    <span>{{ licensee.first_name }} {{ licensee.last_name }}</span>
                  </div>
                </div>
                <div
                  v-else
                  class="overview-view-item"
                  style="visibility: hidden;"
                />
                <!-- overview item end -->
                <div
                  class="overview-view-item"
                  style="visibility: hidden;"
                />
              </div>
              <!-- overview content end -->

            </div>
            <div class="footer">

              <div class="mortgage-details-footer">

                <hwa-button
                  v-b-tooltip.top="'Delete Mortgage'"
                  variant="danger"
                  class="mr-1"
                  :label="Boolean(mortgage.marked_as_deleted) === true ? 'Undo Delete' : 'Delete'"
                  :disabled="mortgageLoading"
                  @onClick="deleteMortgage()"
                />

                <div class="d-flex align-item-center sub-buttons">
                  <hwa-button
                    v-b-tooltip.top="'Edit Mortgage'"
                    variant="primary"
                    :loading="downloading"
                    :loading-text="'Edit'"
                    label="Edit"
                    @onClick="openEditMorgage()"
                  />
                </div>
              </div>
            </div>
          </div>

        </b-tab>
        <b-tab
          title="Mortgage Application"
          @click="enableTabView"
        >
          <Applications
            :application_id="application_id"
            :check_application_change="check_application_change"
            :is_application_change="is_application_change"
          />
        </b-tab>
        <!-- <b-tab title="Details">

        </b-tab> -->
        <b-tab
          title="Files"
          :disabled="is_application_change ? true : false"
        >
          <div class="file_upload_heading">
            <h4>Files</h4>
            <!-- <div
              class="file_upload_button"
              @click="$bvModal.show('file-upload-modal')"
            >
              <feather-icon
                class="icon-view"
                icon="PlusIcon"
              />
            </div> -->
          </div>
          <div class="file_upload_box">
            <div class="file_upload_row">
              <div class="file-col">
                <span class="heading">Preview</span>
              </div>
              <div class="file-col">
                <span class="heading">File Name</span>
              </div>
              <div class="file-col">
                <span class="heading">Uploaded By</span>
              </div>
              <div class="file-col">
                <span class="heading">Date</span>
              </div>
              <div class="file-col">
                <span class="heading">Action</span>
              </div>
            </div>

            <div
              v-for="(item, index) in mortgage.application && mortgage.application.attachments"
              :key="index"
              class="file_upload_row"
            >
              <div
                class="file-col"
                :class="Boolean(item.marked_as_deleted) === true ? 'is_line_througth' : ''"
              >
                <img
                  v-if="getExtention(item.name) === 'png' || getExtention(item.name) === 'jpg' || getExtention(item.name) === 'jpeg' || getExtention(item.name) === 'svg'"
                  :src="imgTumb"
                  alt="tumb"
                  class="file_thumb"
                >

                <img
                  v-if="getExtention(item.name) === 'pdf'"
                  :src="pdfTumb"
                  alt="tumb"
                  class="file_thumb"
                >

                <img
                  v-if="getExtention(item.name) === 'doc' || getExtention(item.name) === 'docx'"
                  :src="docTumb"
                  alt="tumb"
                  class="file_thumb"
                >

                <img
                  v-if="getExtention(item.name) === 'xls' || getExtention(item.name) === 'csv'"
                  :src="xlsTumb"
                  alt="tumb"
                  class="file_thumb"
                >
              </div>
              <div
                class="file-col"
                :class="Boolean(item.marked_as_deleted) === true ? 'is_line_througth' : ''"
              >
                <span class="text-primary">{{ item.name }}</span>
              </div>
              <div
                class="file-col"
                :class="Boolean(item.marked_as_deleted) === true ? 'is_line_througth' : ''"
              >
                <div class="d-flex align-items-center">
                  <!-- button avatar -->
                  <b-avatar
                    button
                    variant="primary"
                    :text="`${mortgage.first_name.substr(0, 1)}${mortgage.last_name.substr(
                      0,
                      1
                    )}`"
                    class="align-baseline mr-1"
                  />
                  <span>{{ mortgage.first_name }} {{ mortgage.last_name }}</span>

                  <!-- link avatar -->
                  <!-- <b-avatar
      href="#foobar"
      :src="require('@/assets/images/portrait/small/avatar-s-8.jpg')"
    />
    <span>Link Avatar</span> -->
                </div>
              </div>
              <div
                class="file-col"
                :class="Boolean(item.marked_as_deleted) === true ? 'is_line_througth' : ''"
              >
                <span>{{ date(item.created_at) }}</span>
              </div>
              <div class="file-col">
                <div class="action_view">
                  <span
                    v-if="Boolean(item.marked_as_deleted) === false"
                    v-b-tooltip.hover.top="'Rename file'"
                    @click="openRenameFileModal(item)"
                  ><feather-icon
                    class="icon-view text-primary"
                    icon="Edit3Icon"
                    size="22"
                  /></span>

                  <span
                    v-if="Boolean(item.marked_as_deleted) === false"
                    v-b-tooltip.hover.top="'Download file'"
                    @click="downloadFile(item)"
                  ><feather-icon
                    class="icon-view text-info"
                    icon="DownloadCloudIcon"
                    size="22"
                  /></span>

                  <span
                    v-b-tooltip.hover.top="Boolean(item.marked_as_deleted) === false ? 'Delete file' : 'Restore File'"
                  ><feather-icon
                    class="icon-view text-danger"
                    :icon="Boolean(item.marked_as_deleted) === false ? 'TrashIcon' : 'RotateCcwIcon'"
                    size="22"
                    @click="deleteAttachment(item)"
                  /></span>
                </div>
              </div>
            </div>
          </div>

          <b-modal
            id="rename-file-modal"
            title="Rename File"
            hide-footer
            size=""
          >
            <span>File Name</span>
            <b-form-input
              v-model="edited_file_name"
              placeholder="file name"
            />
            <div class="d-flex d-flex justify-content-between mt-3">

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="$bvModal.hide('rename-file-modal')"
              >
                Cancel
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-primary"
                @click="renameFile"
              >
                Save Changes
              </b-button>
            </div>
          </b-modal>

        </b-tab>
        <b-tab :disabled="is_application_change ? true : false">
          <template #title>
            Discussion<div
              v-if="mortgage.unread_messages !== 0"
              class="main_notify_box"
              style="margin-left:10px !important"
            >
              <feather-icon
                icon="BellIcon"
                size="22"
              />
              <div class="count_box">
                {{ mortgage.unread_messages }}
              </div>
            </div>
          </template>
          <Chat
            v-if="showChats"
            :mortgage_id="mortgage.id"
          />
        </b-tab>
        <b-tab
          title="Notes"
          :disabled="is_application_change ? true : false"
          @click="loadNote"
        >
          <Notes
            v-if="is_note_enable === true"
            :application="mortgage"
            :mortgage_id="mortgage.id"
          />
        </b-tab>
      </b-tabs>
    </b-modal>

    <!-- :hide-footer="!$can('update-mortgage-application','hwa')" -->
    <validation-observer
      ref="mortgageForm"
      v-slot="{ invalid }"
    >
      <hwa-modal
        ref="edit_mortgage_file"
        :title="`${updateMortgageMode ? 'Edit' : 'New'} Mortgage`"
        :show="mortgageModalOpened"
        size="md"
        :hide-footer="!$can('update-mortgage-application', 'hwa')"
        :show-overlay="mortgageLoading"
        :is_data_change="is_mortgage_change"
        @onClose="closeMortgageModal()"
      >
        <div>
          <hwa-select
            v-model="mortgage.borrower_id"
            label="Borrower"
            label-name="text"
            value-name="value"
            :options="borrowerOptions"
          />
        </div>
        <div class="mt-1">
          <hwa-date-picker
            v-model="mortgage.opened_date"
            label="Opened Date"
          />
        </div>
        <div class="mt-1">
          <hwa-input
            v-model="mortgage.amount"
            label="Mortgage Amount"
          />
        </div>
        <div class="mt-1">
          <hwa-select
            v-model="mortgage.loan_originator_id"
            label="Loan Originator"
            label-name="full_name"
            value-name="id"
            :options="loanOriginators"
          />
        </div>
        <div class="mt-1">
          <hwa-select
            v-model="mortgage.mortgage_status_id"
            label="Status"
            label-name="title"
            value-name="id"
            :options="mortgageStatuses"
          />
        </div>
        <div
          class="mt-1"
        >
          <b-alert
            v-if="mortgage.selected_licensee"
            variant="secondary"
            show
            class="mb-2"
          >
            <div class="alert-body">
              <div class="associte_user">
                <b-avatar variant="warning">
                  <feather-icon icon="UserIcon" />
                </b-avatar>
                <div class="tag-view">
                  <div class="text-item">
                    <h5>{{ licensee.full_name }}</h5>
                    <small class="email">{{ licensee.email }}</small>
                  </div>
                </div>
              </div>
              <hr>
            </div>
          </b-alert>
          <hwa-select
            v-model="selected_licensee"
            label="Associate Realestate Licensee"
            label-name="text"
            value-name="value"
            :options="options"
            class="mb-2"
            @change="setSelectedLicensee"
          />
        </div>
        <div
          v-if="updateMortgageMode"
          class="row mt-2"
        >
          <!-- <div class="col-12">
            <small> Actions </small>
          </div> -->
        </div>
        <template slot="modal-actions">
          <div
            style="display: flex; justify-content: space-between !important"
            class="full-width"
          >
            <hwa-button
              variant="secondary"
              :class="is_mortgage_change ? 'active-secondary' : ''"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="closeMortgageModal(e)"
            />
            <hwa-button
              v-if="!updateMortgageMode"
              variant="success"
              label="Create"
              :disabled="invalid || mortgageLoading"
              @onClick="createMortgage()"
            />

            <hwa-button
              v-else
              variant="success"
              :class="is_mortgage_change ? 'active-success' : ''"
              label="Save Changes"
              :disabled="invalid || mortgageLoading || !is_mortgage_change"
              @onClick="updateMortgage()
              "
            />
          </div>
        </template>
      </hwa-modal>
    </validation-observer>

    <!-- <b-modal
      id="open-chat"
      title="Chats View"
      hide-footer
      size="xl"
    >

    </b-modal> -->
    <!-- <b-modal
      id="application-modal"
      :title="`Application ${application_id}`"
      hide-footer
      size="xl"
    >

    </b-modal> -->

  </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import {
  BTable,
  BCard,
  BFormCheckbox,
  VBTooltip,
  BModal,
  BBadge,
  BAlert,
  BFormGroup,
  BFormRadioGroup,
  BRow,
  BFormSelect,
  BAvatar,
  BTabs,
  BTab,
  BButton,
  BFormInput,
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import HwaSelect from '@/layouts/components/HwaSelect.vue'
import HwaMultiSelect from '@/layouts/components/HwaMultiSelect.vue'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaDatePicker from '@/layouts/components/HwaDatePicker.vue'
import showToast from '@/mixins/showToast'
import Chat from '@/views/app/chat/Chat.vue'
import confirm from '@/mixins/confirm'
import TestGenerate from '@/views/app/staff/SectionOne'
import { ValidationObserver } from 'vee-validate'
import HwaModal from '@/layouts/components/HwaModal.vue'
import Applications from '@/views/app/client/Applications.vue'
import realestateLicensee from '@/mixins/realestateLicensee'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import moment from 'moment'
import imgTumb from '../../../assets/images/files/image.png'
import pdfTumb from '../../../assets/images/files/pdf.png'
import xlsTumb from '../../../assets/images/files/xls.png'
import docTumb from '../../../assets/images/files/doc.png'
import Notes from '../components/Notes.vue'
import bus from '../../../libs/bus'

export default {
  components: {
    TestGenerate,
    HwaButton,
    HwaSelect,
    HwaMultiSelect,
    BFormCheckbox,
    BFormRadioGroup,
    BRow,
    HwaDatePicker,
    ValidationObserver,
    HwaModal,
    BTable,
    BCard,
    BFormGroup,
    BFormSelect,
    HwaInput,
    BAvatar,
    BBadge,
    BModal,
    Chat,
    Applications,
    BAlert,
    BTabs,
    BTab,
    BButton,
    BFormInput,
    vueDropzone: vue2Dropzone,
    Notes,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [showToast, confirm, realestateLicensee],
  data() {
    return {
      imgTumb,
      pdfTumb,
      xlsTumb,
      docTumb,
      tabIndex: 0,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { 'My-Awesome-Header': 'header value' },
        addRemoveLinks: true,
      },
      selected_licensee: null,
      application_id: null,
      loanOriginators: [],
      fields: [
        { key: 'application.application_id', label: 'File Number' },
        { key: 'name', label: 'Borrower Name', sortable: true },
        { key: 'amount', label: 'Loan Amount', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'opened_date', label: 'Status Date', sortable: true },
      ],
      teams: [],
      team_name: '',
      team_id: null,
      teamLoading: false,
      downloading: false,
      updateApplicationMode: false,
      teamModalOpened: false,
      borrowers: [],
      other_borrowers: [],
      mortgageLoading: false,
      updateMortgageMode: false,
      mortgageModalOpened: false,
      mortgageDetailsOpened: false,
      selectedMortgage: null,
      mortgages: [],
      mortgageStatuses: [],
      mortgage: {
        id: null,
        borrower_id: null,
        loan_originator_id: null,
        other_borrower_1: null,
        other_borrower_2: null,
        other_borrower_3: null,
        other_borrower_4: null,
        opened_date: null,
        amount: null,
        mortgage_status_id: null,
        selected_licensee: null,
        application: {},
      },
      check_mortgage_change: false,
      is_mortgage_change: false,
      edited_file_name: '',
      file_extention: '',
      uploaded_file_details: {},
      is_note_enable: false,
    }
  },
  computed: {
    showChats() {
      return this.tabIndex === 3
    },
    allMortgages() {
      return this.$store.getters['chat/mortgages']
    },
    licensee() {
      return this.licensees.find(l => +l.id === this.selected_licensee)
    },
    check_application_change() {
      return this.$store.state.application.enable_application_view
    },
    is_application_change() {
      return this.$store.state.application.enable_application
    },
    filteredBorrowers() {
      return this.borrowers.filter(b => b.id != this.mortgage.borrower_id)
    },
    options() {
      return this.licensees.map(item => ({
        value: item.id,
        text: item.full_name,
      }))
    },
    filteredBorrowers() {
      return this.borrowers.filter(b => b.id != this.mortgage.borrower_id)
    },
    borrowerOptions() {
      return this.borrowers.map(item => ({
        value: item.id,
        text: item.full_name,
      }))
    },
  },
  watch: {
    tabIndex(val) {
      if (val === 3) {
        this.mortgage.unread_messages = 0
      }
    },
    allMortgages(val) {
      this.mortgages = val
      this.defaultObject = val
    },
    mortgage: {
      handler(oldValue, newValue) {
        if (this.check_mortgage_change === true) {
          this.is_mortgage_change = true
        }
      },
      deep: true,
    },
    selected_licensee: {
      handler(value) {
        if (this.check_mortgage_change === true) {
          this.is_mortgage_change = true
          this.mortgage.selected_licensee = value
        }
      },
      deep: true,
    },
  },
  destroyed() {
    bus.$off('new-message')
  },
  mounted() {
    bus.$on('new-message', id => {
      this.mortgages = this.mortgages.map(m => {
        if (+m.id === +id) {
          m.unread_messages++
        }
        return m
      })
    })
    this.getApplications()
    this.getBorrowers()
    this.getMortgageStatuses()
    this.getRealestateLicensees()
    this.getLoanOriginators()
  },
  methods: {
    loadNote() {
      this.is_note_enable = true
    },
    getExtention(name) {
      const extention = name.split('.')
      return extention[1]
    },
    formartCurrency(amount) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })

      const str = formatter.format(amount)
      const splitStr = str.split('.')
      return splitStr[0]
    },
    date(data) {
      return moment(data).format('ddd DD-MMM-YY')
    },
    handleMortgageFileModalClose() {
      this.$store.dispatch('application/enableApplicationView', false)
      this.$store.dispatch('application/enableApplication', false)
    },
    enableTabView() {
      this.$store.dispatch('application/enableApplicationView', true)
    },
    getLoanOriginators() {
      this.$http
        .get('/api/loan-originators')
        .then(res => {
          this.loanOriginators = res.data
        })
        .catch(err => {
          this.showError('Failed to load statuses')
        })
    },
    getMortgageStatuses() {
      this.$http
        .get('/api/mortgage-statuses')
        .then(res => {
          this.mortgageStatuses = res.data
        })
        .catch(err => {
          this.showError('Failed to load statuses')
        })
    },
    openChat() {
      this.$bvModal.show('open-chat')
    },
    handleBorrowerChanged(id) {
      if (id) {
        this.other_borrowers = this.other_borrowers.filter(b => b.id !== id)
      }
    },
    getBorrowers() {
      this.$http
        .get('/api/borrowers')
        .then(res => {
          this.borrowers = res.data
        })
        .catch(err => {
          this.downloading = false
          this.showError('Failed to load borrowers')
        })
    },
    onRowSelected(item) {
      this.$store.dispatch('application/enableApplication', false)
      this.mortgage = { ...item }
      this.mortgage.opened = !!item.opened
      this.updateMortgageMode = true
      this.$bvModal.show('mortgage-file-modal')
      const application = { ...this.mortgage.application }
      this.$store.commit('auth/setLoanOriginator', this.mortgage.loan_originator)
      this.$store.commit('auth/setLoanApplication', application)
      this.$store.commit('auth/setMortgageId', this.mortgage.id)
      this.$store.commit('auth/setMortgageStatus', this.mortgage.status)
      this.application_id = application.application_id
      this.selected_licensee = item.selected_licensee
      // eslint-disable-next-line prefer-destructuring
      if (this.mortgage.selected_licensee !== null) {
        // eslint-disable-next-line prefer-destructuring
        this.licensee = this.licensees.filter(elm => elm.id === this.mortgage.selected_licensee)[0]
      }
    },
    setSelectedLicensee(id) {
      // this.mortgage.selected_licensee = id
      // eslint-disable-next-line prefer-destructuring
      this.licensee = this.licensees.filter(item => item.id === id)[0]
    },
    openEditMorgage() {
      this.mortgageModalOpened = true
      this.mortgageDetailsOpened = true
      if (this.mortgageDetailsOpened === true) {
        this.check_mortgage_change = true
      }

      const dom = this
      setTimeout(() => {
        dom.is_mortgage_change = false
      }, 100)
      // this.$bvModal.show('application-modal')
    },
    resetForm() {
      this.mortgage = {
        id: null,
        borrower_id: null,
        other_borrower_1: null,
        other_borrower_2: null,
        other_borrower_3: null,
        other_borrower_4: null,
        opened_date: null,
        amount: null,
        selected_licensee: null,
        mortgage_status_id: null,
      }
      this.$refs.mortgageForm.reset()
    },
    openMortgageModal() {
      this.updateMortgageMode = false
      this.mortgageModalOpened = true
      this.resetForm()
      this.$refs.mortgageTable.clearSelected()
    },
    closeMortgageModal() {
      if (this.is_mortgage_change) {
        this.$swal({
          title: 'Do you want to save changes?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save changes!',
          cancelButtonText: 'Discard Changes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.mortgageModalOpened = false
            this.is_mortgage_change = false
            this.updateMortgage()
          } else if (result.dismiss === 'cancel') {
            this.mortgageModalOpened = false
            this.is_mortgage_change = false
            // this.closeMortgageModal()
          }
        })
      } else {
        this.mortgageModalOpened = false
        this.is_mortgage_change = false
      }
    },
    // eslint-disable-next-line consistent-return
    deleteMortgage() {
      if (!this.mortgage.id) {
        return this.showInfo('Select mortgage first')
      }
      this.confirmAction('Are you sure?').then(yes => {
        if (yes) {
          this.mortgage.marked_as_deleted = !this.mortgage.marked_as_deleted
          this.updateMortgage()
          // this.$bvModal.hide('mortgage-file-modal')
        }
      })
    },
    updateMortgage(mortgage) {
      this.is_mortgage_change = false
      this.mortgageLoading = true
      const data = { ...this.mortgage, application: null }
      this.$http
        .put(`/api/mortgages/${this.mortgage.id}`, data)
        .then(res => {
          this.mortgageLoading = false
          this.mortgages = this.mortgages.map(mort => {
            if (+mort.id === +res.data.id) {
              return { ...this.mortgage, ...res.data }
            }
            return mort
          })
          this.is_mortgage_change = false
          this.closeMortgageModal()
          this.showSuccess('Mortgage updated successfully')

          // t
          const dom = this
          setTimeout(() => {
            dom.mortgage = res.data
          }, 100)
        })
        .catch(err => {
          this.mortgageLoading = false
          this.showError('Failed to update mortgage')
        })
    },
    createMortgage() {
      this.mortgageLoading = true
      for (let i = 0; i < this.other_borrowers.length; i++) {
        this.mortgage[`other_borrower_${i + 1}`] = this.other_borrowers[i].id
      }
      this.$http
        .post('/api/mortgages', this.mortgage)
        .then(res => {
          this.mortgageLoading = false
          this.mortgages.unshift(res.data)
          this.mortgageModalOpened = false
          this.resetForm()
          this.showSuccess('Mortgage created successfully')
        })
        .catch(err => {
          this.mortgageLoading = false
          this.showError('Failed to create mortgage')
        })
    },
    downloadPdf() {
      this.downloading = true
      this.$http
        .get(`/api/download-application/${this.mortgage.application_id}`)
        .then(res => {
          this.downloading = false
          const url = res.data
          const link = document.createElement('a')
          link.href = url
          link.target = 'blank'
          link.setAttribute('download', 'Messages.pdf') // or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch(err => {
          this.downloading = false
          this.showError('Failed to download application')
        })
    },
    openDetails() {
      const application = { ...this.mortgage.application }
      this.$store.commit('auth/setLoanApplication', application)
      this.$store.commit('auth/setMortgageId', this.mortgage.id)
      this.$store.commit('auth/setMortgageStatus', this.mortgage.status)
      this.$bvModal.show('application-modal')
      this.application_id = application.application_id
      //   const application = this.mortgage.application;
      //   this.$store.commit('auth/setLoanApplication', application)
      //   this.$router.push({ path: `/staff/client-applications/${application.application_id}` })
      // },
    },
    openChats() {
      this.$store.commit('auth/setMortgageId', this.mortgage.id)
      this.$router.push({ name: 'chats' })
    },
    showConfirm(team) {
      this.confirmAction().then(res => {
        if (res) {
          this.deleteApplication(team.id)
        }
      })
    },
    updateApplication() { },
    getApplications() {
      this.$http
        .get('/api/all-mortgages')
        .then(res => {
          this.$store.commit('chat/setMortgages', res.data)
        })
        .catch(err => {
          this.showError('Failed to retrieve applications')
        })
    },
    downloadFile(file) {
      const url = `${process.env.VUE_APP_STORAGE_PATH}/${file.path}`
      axios({
        url, // File URL Goes Here
        method: 'GET',
        responseType: 'blob',
      }).then(res => {
        const FILE = window.URL.createObjectURL(new Blob([res.data]))

        const docUrl = document.createElement('a')
        docUrl.href = FILE
        docUrl.setAttribute('download', file.name)
        document.body.appendChild(docUrl)
        docUrl.click()
      })
    },
    deleteAttachment(item) {
      this.$swal({
        title: 'Are you sure?',
        text: Boolean(item.marked_as_deleted) === true ? '' : "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: Boolean(item.marked_as_deleted) === true ? 'Yes, restore it!' : 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const data = item
          data.marked_as_deleted = !data.marked_as_deleted
          this.$http.put(`api/mortgage/update-attachment-details/${data.id}`, data).then(res => {
            this.mortgage.application.attachments = res.data
            this.uploading = false
            this.showSuccess('attachment updated successfully')
          }).catch(err => {
            this.uploading = false
            this.showError('Failed to delete file')
          })
        }
      })
    },
    openRenameFileModal(item) {
      const getFileNameWithoutExtention = item.name.split('.')
      this.file_extention = this.getExtention(item.name)
      // eslint-disable-next-line prefer-destructuring
      this.edited_file_name = getFileNameWithoutExtention[0]
      this.uploaded_file_details = item
      this.$bvModal.show('rename-file-modal')
    },
    renameFile() {
      const data = this.uploaded_file_details
      data.name = `${this.edited_file_name}.${this.file_extention}`
      this.$http.put(`api/mortgage/update-attachment-details/${data.id}`, data).then(res => {
        this.mortgage.application.attachments = res.data
        this.$bvModal.hide('rename-file-modal')
        this.file_extention = ''
        this.edited_file_name = ''
        this.uploaded_file_details = {}
        this.showSuccess('file rename successfully')
      }).catch(err => {
        this.uploading = false
        this.showError('Failed to rename file')
      })
    },
  },
}
</script>
