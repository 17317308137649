<template>
  <div id="applicationForm">

    <h4>Uniform Residential Loan Application ({{ applicationId }})</h4>
    <p>
      Verify and complete the information on this application. If you are applying for this loan with others, each additional Borrower must provide
      information as directed by your Lender
    </p>
    <b-alert
      variant="danger"
      :show="allErrors.length>0"
    >
      <h4 class="alert-heading">
        Invalid data provided
      </h4>
      <div class="alert-body">
        <ul>
          <li
            v-for="(error,index) in allErrors"
            :key="index"
            class="text-danger text-bold"
          >
            {{ error }}
          </li>
        </ul>
      </div>
    </b-alert>
    <section-one />
    <section-two />
    <section-three />
    <section-four />
    <section-five />
    <section-six />
    <section-seven />
    <section-eight />
    <section-nine />
    <!-- <section-ten /> -->
    <!-- <div
        v-if="$store.getters['auth/isStaff']"
        class="row"
      >
        <div class="col-12 mt-1">
          <strong>Application Status</strong>
        </div>
        <div class="col-12">
          <hwa-select
            v-model="loanApplication.status"
            placeholder="Select Section Status"
            :options="applicationStatuses"
          />
        </div>
      </div> -->
    <div
      v-if="canUpdate"
      class="row mt-1"
    >
      <div
        v-if="!$store.getters['auth/isStaff']"
        class="col-12"
      >
        <div class="d-flex justify-content-between">
          <b-button
            v-ripple.400="'rgba(0, 207, 232, 0.15)'"
            variant="outline-info"
            @click="openChat"
          >
            <feather-icon icon="MessageCircleIcon" /> Open Chat
          </b-button>
          <hwa-button
            class="float-right"
            :label="'Submit Application'"
            :loading="submitting"
            @onClick="submitForReview()"
          />
        </div>

      </div>
    </div>
    <div

      class="d-flex justify-content-between align-items-center mt-4"
    >
      <hwa-button
        v-if="is_application_change"
        variant="danger"
        :loading="loading"
        :loading-text="'Cancel Changes'"
        :icon-only="false"
        label="Cancel Changes"
        @onClick="cancelChanges()"
      />
      <hwa-button
        variant="secondary"
        icon="DownloadCloudIcon"
        :loading="downloading"
        :loading-text="'Download'"
        :icon-only="false"
        label="Download"
        @onClick="downloadPdf()"
      />
      <div
        class=""
      >
        <hwa-button
          class="float-right"
          label="Save Changes"
          :loading="loading"
          :class="is_application_change ? 'active-success': ''"
          @onClick="saveChanges()"
        />
      </div>
    </div>

    <b-modal
      id="open-chat"
      title="Basic Modal"
      hide-footer
      size="xl"
    >
      <Chat :mortgage_id="mortgageId" />
    </b-modal>
  </div>
</template>

<script>
import showToast from '@/mixins/showToast'
import SectionOne from '@/views/app/client/applications/SectionOne.vue'
import SectionTwo from '@/views/app/client/applications/SectionTwo.vue'
import SectionThree from '@/views/app/client/applications/SectionThree.vue'
import SectionFour from '@/views/app/client/applications/SectionFour.vue'
import SectionFive from '@/views/app/client/applications/SectionFive.vue'
import SectionSix from '@/views/app/client/applications/SectionSix.vue'
import SectionSeven from '@/views/app/client/applications/SectionSeven.vue'
import SectionEight from '@/views/app/client/applications/SectionEight.vue'
import SectionNine from '@/views/app/client/applications/SectionNine.vue'
import Chat from '@/views/app/chat/Chat.vue'
import {
  BCard, BAlert, BModal,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SectionTen from '@/views/app/client/applications/SectionTen.vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import confirm from '@/mixins/confirm'
import HwaSelect from '@/layouts/components/HwaSelect.vue'
import appMixin from '@/views/app/client/applications/appMixin'

export default {
  name: 'Applications',
  components: {
    HwaSelect,
    HwaButton,
    SectionTen,
    SectionNine,
    SectionEight,
    SectionSeven,
    SectionSix,
    SectionFive,
    SectionFour,
    SectionThree,
    SectionTwo,
    SectionOne,
    BCard,
    BAlert,
    BButton,
    BModal,
    Chat,
  },
  directives: {
    Ripple,
  },
  mixins: [showToast, confirm, appMixin],
  props: ['application_id'],
  data: () => ({
    applicationId: '000',
    loading: false,
    submitting: false,
    validOne: false,
    validTwo: false,
    validThree: false,
    validFour: false,
    validFive: false,
    validSeven: false,
    validEight: false,
    allErrors: [],
    downloading: false,
  }),
  computed: {
    canUpdate() {
      return this.$store.getters['auth/mortgageStatus'] === 'Started'
    },
    mortgageId() {
      return this.$store.getters['auth/mortgageId']
    },
    application() {
      return this.$store.getters['auth/loanApplication']
    },
    check_application_change() {
      return this.$store.state.application.enable_application_view
    },
    is_application_change() {
      return this.$store.state.application.enable_application
    },
  },
  watch: {
    loanApplication: {
      handler(oldValue, newValue) {
        if (this.check_application_change === true) {
          this.$store.dispatch('application/enableApplication', true)
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.applicationId = this.application_id
    this.loanApplication = this.application
  },
  methods: {
    cancelChanges() {
      this.$store.dispatch('application/enableApplication', false)
    },
    openChat() {
      this.$bvModal.show('open-chat')
    },
    downloadPdf() {
      this.downloading = true
      this.$http
        .get(`/api/download-application/${this.applicationId}`)
        .then(res => {
          this.downloading = false
          const url = res.data
          const link = document.createElement('a')
          link.href = url
          link.target = 'blank'
          link.setAttribute('download', 'Messages.pdf') // or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch(err => {
          this.downloading = false
          this.showError('Failed to download application')
        })
    },
    cleanField(field) {
      return field.replaceAll('_', ' ').toUpperCase()
    },
    saveChanges() {
      this.$v.loanApplication.$touch()
      let text = ''
      Object.keys(this.$v.loanApplication).forEach(key => {
        if (typeof this.$v.loanApplication[key] === 'object') {
          if (this.$v.loanApplication[key]?.$invalid) {
            text += `\n${this.cleanField(key)}`
          }
        }
      })
      if (
        !this.$v.loanApplication.$invalid
      ) {
        this.loading = true
        this.$http.put('api/update-application-changes', this.loanApplication)
          .then(res => {
            this.loading = false
            this.showSuccess('Changes Saved')
            this.$store.dispatch('application/enableApplication', false)
            this.$store.commit('auth/setLoanApplication', res.data)
          })
          .catch(res => {
            this.loading = false
            this.showError('Failed to save changes')
          })
      } else {
        alert(`The following fields are invalid\n${text}`)
      }
    },
    async submitForReview() {
      this.$store.commit('auth/setLoanApplication', { ...this.loanApplication })
      this.$v.loanApplication.$touch()
      let text = ''
      Object.keys(this.$v.loanApplication).forEach(key => {
        if (typeof this.$v.loanApplication[key] === 'object') {
          if (this.$v.loanApplication[key]?.$invalid) {
            text += `\n${this.cleanField(key)}`
          }
        }
      })

      if (!this.$v.loanApplication.$invalid) {
        this.allErrors = []
        this.confirmAction('Are you sure?, you will not be able to update this form after submitting')
          .then(res => {
            if (res) {
              this.submitting = true
              this.$store.dispatch('auth/updateApplication', true)
                .then(res => {
                  this.submitting = false
                  this.showSuccess('Submitted successfully')
                  this.$store.commit('auth/setLoanApplication', res.data)
                  this.$store.dispatch('application/enableApplication', false)
                })
                .catch(err => {
                  this.submitting = false
                  this.showError('An error occurred, please try again.')
                  // if (err.status === 422) {
                  //   const errors = Object.values(err.data.errors).filter(err => err.length > 0).map(
                  //     err => err[0].replace('application.', ''),
                  //   )
                  //   this.allErrors = [...errors]
                  //   document.getElementById('applicationForm').scrollIntoView()
                  //   this.showError('Invalid data provided.')
                  // } else {
                  //
                  // }
                })
            }
          })
      } else {
        alert(`The following fields are invalid\n${text}`)
        // this.showError('Please make sure all fields are valid')
        // const errors = Object.values(this.$v.loanApplication.first_name).filter(err => err.length > 0).map(err => err[0])
        // this.allErrors = [...errors]
        // document.getElementById('applicationForm').scrollIntoView()
      }
    },
  },
}
</script>

<style scoped>

</style>
