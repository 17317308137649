import { render, staticRenderFns } from "./SectionTen.vue?vue&type=template&id=6f29efe1&scoped=true&"
import script from "./SectionTen.vue?vue&type=script&lang=js&"
export * from "./SectionTen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f29efe1",
  null
  
)

export default component.exports