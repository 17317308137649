<template>
  <app-collapse
    accordion
    type=""
  >
    <app-collapse-item
      title="Section 5: Declarations."
    >
      <b-alert
        :variant="getVariant(loanApplication.section_five_status)"
        :show="loanApplication.section_five_status!==null"
      >
        <h4 class="alert-heading">
          {{ loanApplication.section_five_status }}
        </h4>
        <div class="alert-body">
          <span>{{ loanApplication.section_five_comments||'Section '+loanApplication.section_five_status }}</span>
        </div>
      </b-alert>
      <p>
        This section asks you specific questions about the property, your funding, and your past
        financial history.
      </p>
      <b-tabs
        align="left"
      >
        <b-tab
          title="5a. About this Property and Your Money for this Loan"
        >
          <div class="row">
            <div class="col-12">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="sec_five_a"
                rules="required"
              >
                <b-form-group label="A. Will you occupy the property as your primary residence?">
                  <b-form-radio-group
                    v-model="loanApplication.sec_five_a"
                    @change="changeAns"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="have_ownership_interest"
                :rules="loanApplication.sec_five_a==='YES'?'required':''"
              >
                <b-form-group label="If YES, have you had an ownership interest in another property in the last three years?">
                  <b-form-radio-group
                    v-model="loanApplication.have_ownership_interest"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <b-overlay
            :show="!ownershipInterest"
            :opacity="0.5"
            class="col-12"
          >
            <template #overlay>
              <div />
            </template>
            <div class="row">
              <div class="col-12">
                If YES, complete (1) and (2) below:
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hwa-select
                  v-model="loanApplication.type_of_property_owned"
                  :rules="ownershipInterest?'required':''"
                  placeholder="Select One"
                  label="(1) What type of property did you own: primary residence (PR), FHA secondary residence (SR), second home (SH),
                 or investment property (IP)?"
                  :options="propertyTypes"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hwa-select
                  v-model="loanApplication.property_join_type"
                  :rules="ownershipInterest?'required':''"
                  placeholder="Select One"
                  label="(2) How did you hold title to the property: by yourself (S), jointly with your spouse (SP), or jointly with another person (O)?"
                  :options="joinTypes"
                />
              </div>
            </div>
          </b-overlay>
          <div class="row">
            <div class="col-12">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="sec_five_b"
                rules="required"
              >
                <b-form-group label="B. If this is a Purchase Transaction: Do you have a family relationship or business affiliation with the seller of the property?">
                  <b-form-radio-group
                    v-model="loanApplication.sec_five_b"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="sec_five_c"
                rules="required"
              >
                <b-form-group
                  label="C. Are you borrowing any money for this real estate transaction (e.g., money for your closing costs or down payment) or
obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this loan application?"
                >
                  <b-form-radio-group
                    v-model="loanApplication.sec_five_c"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <b-overlay
            :show="!borrowingMoney"
            :opacity="0.5"
            class="col-12"
          >
            <template #overlay>
              <div />
            </template>
            <div class="row">
              <div class="col-12">
                <hwa-input
                  v-model="loanApplication.amount_of_money"
                  :rules="borrowingMoney?'required|numeric':''"
                  placeholder="$"
                  label="If YES, what is the amount of this money?"
                />
              </div>
            </div>
          </b-overlay>

          <div class="row">
            <div class="col-12">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="sec_five_d"
                rules="required"
              >
                <b-form-group
                  label="D. 1. Have you or will you be applying for a mortgage loan on another property (not the property securing this loan) on or
before closing this transaction that is not disclosed on this loan application?"
                >
                  <b-form-radio-group
                    v-model="loanApplication.sec_five_d"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="sec_five_d_2"
                rules="required"
              >
                <b-form-group
                  label="2. Have you or will you be applying for any new credit (e.g., installment loan, credit card, etc.) on or before closing thisloan that
is not disclosed on this application?"
                >
                  <b-form-radio-group
                    v-model="loanApplication.sec_five_d_2"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="sec_five_e"
                rules="required"
              >
                <b-form-group
                  label="E. Will this property be subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien paid
                          through your property taxes (e.g., the Property Assessed Clean Energy Program)?"
                >
                  <b-form-radio-group
                    v-model="loanApplication.sec_five_e"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <b-tabs
        align="left"
      >
        <b-tab
          title="5b. About Your Finances"
        >
          <div class="row">
            <div class="col-12">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="sec_five_f"
                rules="required"
              >
                <b-form-group
                  label="F. Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application?"
                >
                  <b-form-radio-group
                    v-model="loanApplication.sec_five_f"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-12">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="sec_five_g"
                rules="required"
              >
                <b-form-group
                  label="G. Are there any outstanding judgments against you?"
                >
                  <b-form-radio-group
                    v-model="loanApplication.sec_five_g"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-12">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="sec_five_h"
                rules="required"
              >
                <b-form-group
                  label="H. Are you currently delinquent or in default on a Federal debt?"
                >
                  <b-form-radio-group
                    v-model="loanApplication.sec_five_h"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-12">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="sec_five_i"
                rules="required"
              >
                <b-form-group
                  label="I. Are you a party to a lawsuit in which you potentially have any personal financial liability?"
                >
                  <b-form-radio-group
                    v-model="loanApplication.sec_five_i"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-12">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="sec_five_j"
                rules="required"
              >
                <b-form-group
                  label="J. Have you conveyed title to any property in lieu of foreclosure in the past 7 years?"
                >
                  <b-form-radio-group
                    v-model="loanApplication.sec_five_j"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-12">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="sec_five_k"
                rules="required"
              >
                <b-form-group
                  label="K. Within the past 7 years, have you completed a pre-foreclosure sale or short sale, whereby the property was sold to a
third party and the Lender agreed to accept less than the outstanding mortgage balance due?"
                >
                  <b-form-radio-group
                    v-model="loanApplication.sec_five_k"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-12">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="sec_five_l"
                rules="required"
              >
                <b-form-group
                  label="L. Have you had property foreclosed upon in the last 7 years?"
                >
                  <b-form-radio-group
                    v-model="loanApplication.sec_five_l"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-12">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="sec_five_m"
                rules="required"
              >
                <b-form-group
                  label="M. Have you declared bankruptcy within the past 7 years?"
                >
                  <b-form-radio-group
                    v-model="loanApplication.sec_five_m"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <b-overlay
            :show="!declaredBankruptcy"
            :opacity="0.5"
            class="col-12"
          >
            <template #overlay>
              <div />
            </template>
            <div class="row">
              <div class="col-12">
                If YES, identify the type(s) of bankruptcy:
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <validation-provider
                  #default="{ errors }"
                  :immediate="true"
                  name="bankruptcy"
                  :rules="declaredBankruptcy?'required':''"
                >
                  <b-form-checkbox-group
                    v-model="loanApplication.bankruptcy"
                    :options="bankruptcies"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </div>
          </b-overlay>
        </b-tab>
      </b-tabs>
      <div
        v-if="$store.getters['auth/isStaff']"
        class="row"
      >
        <div class="col-12 mt-1">
          <strong>Staff Comments</strong>
        </div>
        <div class="col-12">
          <hwa-select
            v-model="loanApplication.section_five_status"
            placeholder="Select Section Status"
            :options="sectionStatuses"
          />
        </div>
        <div class="col-12">
          <hwa-text-area
            v-model="loanApplication.section_five_comments"
            placeholder="Comments"
          />
        </div>
      </div>
      <div
        v-if="canUpdate"
        class="row mt-1"
      >
        <div class="col-12">
          <hwa-button
            class="float-right"
            label="Save Changes"
            :loading="saving"
            @onClick="saveChangesToServer()"
          />
        </div>
      </div>
    </app-collapse-item>

  </app-collapse>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BTabs, BTab, BFormRadioGroup, BFormGroup, BFormCheckboxGroup, BAlert, BOverlay,
} from 'bootstrap-vue'
import showToast from '@/mixins/showToast'
import HwaInput from '@/layouts/components/HwaInput.vue'
import appMixin from '@/views/app/client/applications/appMixin'
import HwaButton from '@/layouts/components/HwaButton.vue'
import HwaSelect from '@/layouts/components/HwaSelect.vue'
import HwaTextArea from '@/layouts/components/HwaTextArea.vue'

export default {
  name: 'SectionFive',
  components: {
    HwaTextArea,
    HwaSelect,
    HwaInput,
    AppCollapse,
    AppCollapseItem,
    BTabs,
    BTab,
    BFormRadioGroup,
    BFormGroup,
    BFormCheckboxGroup,
    HwaButton,
    BAlert,
    BOverlay,
    ValidationProvider,
  },
  mixins: [showToast, appMixin],
  data: () => ({
    bankruptcies: [
      'Chapter 7',
      'Chapter 11',
      'Chapter 12',
      'Chapter 13',
    ],
    propertyTypes: [
      'IP',
      'PR',
      'SH',
      'SR',
    ],
    joinTypes: [
      'O',
      'S',
      'SP',
    ],
    loanApplication: {},
  }),
  computed: {
    declaredBankruptcy() {
      return this.loanApplication.sec_five_m === 'YES'
    },
    borrowingMoney() {
      return this.loanApplication.sec_five_c === 'YES'
    },
    ownershipInterest() {
      return this.loanApplication.have_ownership_interest === 'YES'
    },
    canUpdate() {
      return (this.application?.status === 'Started'
              || this.application?.section_five_status === 'Need more data'
              || this.application?.section_five_status === 'Has errors')
              && !this.$store.getters['auth/isStaff']
    },
    application() {
      return this.$store.getters['auth/loanApplication']
    },
  },
  watch: {
    declaredBankruptcy(bankrupt) {
      if (!bankrupt) {
        this.loanApplication.bankruptcy = []
      }
    },
    borrowingMoney(borrowing) {
      if (!borrowing) {
        this.loanApplication.amount_of_money = null
      }
    },
    ownershipInterest(interest) {
      if (!interest) {
        this.loanApplication.type_of_property_owned = null
        this.loanApplication.property_join_type = null
      }
    },
    loanApplication: {
      handler(newVal, oldVal) {
        this.$store.commit('auth/setLoanApplication', newVal)
      },
      deep: true,
    },
  },
  mounted() {
    this.loanApplication = this.application
  },
  methods: {
    changeAns(checked){
      if(checked==="NO"){
        this.loanApplication.have_ownership_interest = null;
      }
    }
  },
}
</script>
<style scoped>

</style>
