<template>
  <app-collapse
    accordion
    type=""
  >
    <app-collapse-item
      title="Section 8: Demographic Information."
    >
      <b-alert
        :variant="getVariant(loanApplication.section_eight_status)"
        :show="loanApplication.section_eight_status!==null"
      >
        <h4 class="alert-heading">
          {{ loanApplication.section_eight_status }}
        </h4>
        <div class="alert-body">
          <span>{{ loanApplication.section_eight_comments||'Section '+loanApplication.section_eight_status }}</span>
        </div>
      </b-alert>
      <div class="row">
        <div class="col-12">
          This section asks about your ethnicity, sex, and race.
        </div>
      </div>
      <b-tabs
        align="left"
      >
        <b-tab
          title="Demographic Information of Borrower"
        >

          <div class="row">
            <div class="col-12">
              The purpose of collecting this information is to help ensure that all applicants are treated
              fairly and that the housing needs of communities
              and neighborhoods are being fulfilled. For residential mortgage lending, Federal law
              requires that we ask applicants for their demographic
              information (ethnicity, sex, and race) in order to monitor our compliance with equal credit
              opportunity, fair housing, and home mortgage
              disclosure laws. You are not required to provide this information, but are encouraged to do
              so. You may select one or more designations for
              "Ethnicity" and one or more designations for "Race." The law provides that we may not
              discriminate on the basis of this information, or on
              whether you choose to provide it. However, if you choose not to provide the information and
              you have made this application in person, Federal
              regulations require us to note your ethnicity, sex, and race on the basis of visual
              observation or surname. The law also provides that we may not
              discriminate on the basis of age or marital status information you provide in this
              application. If you do not wish to provide some or all of this
              information, please check below
            </div>
          </div>
          <div class="row mb-3 mt-2">
            <div class="col-6">
              <div class="row">
                <validation-provider
                  #default="{ errors }"
                  :immediate="true"
                  name="ethnicity"
                >
                  <!--                  rules="required"-->
                  <div class="col-12">
                    Ethnicity: Check one or more
                  </div>
                  <div class="col-12 mb-1">
                    <b-form-checkbox
                      v-model="loanApplication.ethnicity"
                      value="latino"
                    >
                      Hispanic or Latino
                    </b-form-checkbox>
                  </div>
                  <b-overlay
                    :show="!hasLatino"
                    :opacity="0.5"
                    class="col-12"
                  >
                    <template #overlay>
                      <div />
                    </template>
                    <div class="row">
                      <validation-provider
                        #default="{ errors }"
                        :immediate="true"
                        name="input"
                      >
                        <!--                        :rules="hasLatino?'required':''"-->
                        <div class="col-12">
                          <b-form-checkbox-group
                            v-model="loanApplication.latino_ethnicity"
                            :options="latinoEthnicity"
                          />
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div class="row">
                      <div
                        class="col-12"
                        style="margin-top: -10px"
                      >
                        <hwa-input
                          v-model="loanApplication.other_ethnicity"
                          label=""
                          placeholder="Enter Response"
                          :read-only="!loanApplication.latino_ethnicity.includes('other')"
                        />
                      </div>
                      <div
                        class="col-12 mb-1"
                        style="margin-top: -15px"
                      >
                        <span style="font-style: italic;">For example: Argentinean, Colombian, Dominican, Nicaraguan,
                          Salvadoran, Spaniard, and so on.</span>
                      </div>
                    </div>
                  </b-overlay>

                  <div class="col-12 mt-1">
                    <b-form-checkbox
                      v-model="loanApplication.ethnicity"
                      value="not_latino"
                    >
                      Not Hispanic or Latino
                    </b-form-checkbox>
                  </div>
                  <div class="col-12 mt-1">
                    <b-form-checkbox
                      v-model="loanApplication.ethnicity"
                      value="none"
                    >
                      I do not wish to provide this information
                    </b-form-checkbox>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div class="col-12 mt-1">
                  <strong>Sex</strong>
                </div>
                <div class="col-12">
                  <b-form-checkbox-group
                    v-model="loanApplication.borrower_sex"
                    :options="sex"
                    stacked
                  />
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-12">
                  Race: Check one or more
                </div>
                <validation-provider
                  #default="{ errors }"
                  :immediate="true"
                  name="race"
                >
                  <!--                  rules="required"-->
                  <div class="col-12">
                    <b-form-checkbox
                      v-model="loanApplication.race"
                      value="american"
                    >
                      American Indian or Alaska Native – Print name of enrolled or principal tribe
                      :
                    </b-form-checkbox>
                  </div>
                  <div
                    class="col-12"
                    style="margin-top: -10px"
                  >
                    <hwa-input
                      v-model="loanApplication.principal_tribe"
                      :read-only="!hasAmerican"
                      label=""
                      placeholder="Enter Response"
                    />
                  </div>
                  <!--                  :rules="hasAmerican?'required':''"-->
                  <div class="col-12 mb-1">
                    <b-form-checkbox
                      v-model="loanApplication.race"
                      value="asian"
                    >
                      Asian
                    </b-form-checkbox>
                  </div>
                  <div
                    class="col-12"
                  >
                    <b-overlay
                      :show="!hasAsian"
                      :opacity="0.5"
                    >
                      <template #overlay>
                        <div />
                      </template>
                      <div class="row">
                        <validation-provider
                          #default="{ errors }"
                          :immediate="true"
                          name="input"
                        >
                          <!--:rules="hasAsian?'required':''"-->
                          <div class="col-12">
                            <b-form-checkbox-group
                              v-model="loanApplication.race_asian"
                              :options="asianRace"
                            />
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <div
                          v-if="loanApplication.race_asian.includes('Other Asian – Print race:')"
                          class="col-12"
                        >
                          <div class="row">
                            <div class="col-12">
                              <hwa-input
                                v-model="loanApplication.other_asian"
                                label=""
                                :show-label="false"
                              />
                            </div>
                            <div
                              class="col-12 mb-1"
                              style="margin-top: -10px"
                            >
                              For example: Hmong, Laotian, Thai, Pakistani, Cambodian, and
                              so on.
                            </div>
                          </div>
                        </div>

                      </div>
                    </b-overlay>
                  </div>
                  <div class="col-12 mb-1 mt-1">
                    <b-form-checkbox
                      v-model="loanApplication.race"
                      value="black"
                    >
                      Black or African American
                    </b-form-checkbox>
                  </div>
                  <div class="col-12 mb-1">
                    <b-form-checkbox
                      v-model="loanApplication.race"
                      value="pacific"
                    >
                      Native Hawaiian or Other Pacific Islander
                    </b-form-checkbox>
                  </div>
                  <div
                    class="col-12"
                  >
                    <b-overlay
                      :show="!hasPacific"
                      :opacity="0.5"
                    >
                      <template #overlay>
                        <div />
                      </template>
                      <div class="row">
                        <validation-provider
                          #default="{ errors }"
                          :immediate="true"
                          name="input"
                        >
                          <!--                          :rules="hasPacific?'required':''"-->
                          <div class="col-12">
                            <b-form-checkbox-group
                              v-model="loanApplication.race_hawain"
                              :options="hawainRace"
                            />
                          </div>
                          <div
                            class="col-12"
                          >
                            <div class="row">
                              <div class="col-12">
                                <hwa-input
                                  v-model="loanApplication.other_race"
                                  label=""
                                  :read-only="!hasOtherPacific"
                                  placeholder="Enter Race"
                                  :show-label="false"
                                />
                              </div>
                              <!--                              :rules="hasOtherPacific?'required':''"-->
                              <div
                                class="col-12"
                                style="margin-top: -10px"
                              >
                                For example: Fijian, Tongan, and so on.
                              </div>

                            </div>
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-overlay>
                  </div>
                  <div class="col-12 mt-1">
                    <b-form-checkbox
                      v-model="loanApplication.race"
                      value="white"
                    >
                      White
                    </b-form-checkbox>
                  </div>
                  <div class="col-12 mt-1">
                    <b-form-checkbox
                      v-model="loanApplication.race"
                      value="none"
                    >
                      I do not wish to provide this information
                    </b-form-checkbox>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12">
              To Be Completed by Financial Institution (for application taken in person):
            </div>
          </div>
          <b-overlay
            :show="true"
            :opacity="0.5"
          >
            <template #overlay>
              <div />
            </template>
            <div class="row">
              <div class="col-12">
                <b-form-group
                  label="Was the ethnicity of the Borrower collected on the basis of visual observation or surname?"
                >
                  <b-form-radio-group
                    v-model="loanApplication.ethnicity_observation"
                    :options="['NO','YES']"
                  />
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <b-form-group
                  label="Was the sex of the Borrower collected on the basis of visual observation or surname?"
                >
                  <b-form-radio-group
                    v-model="loanApplication.sex_observation"
                    :options="['NO','YES']"
                  />
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <b-form-group
                  label="Was the race of the Borrower collected on the basis of visual observation or surname?"
                >
                  <b-form-radio-group
                    v-model="loanApplication.race_observation"
                    :options="['NO','YES']"
                  />
                </b-form-group>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12">
                The Demographic Information was provided through:
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <b-form-group label="">
                  <b-form-radio-group
                    v-model="loanApplication.demographic_info_provided_through"
                    :options="democraticInfoMedium"
                  />
                </b-form-group>
              </div>
            </div>
          </b-overlay>
        </b-tab>
      </b-tabs>
      <div
        v-if="$store.getters['auth/isStaff']"
        class="row"
      >
        <div class="col-12 mt-1">
          <strong>Staff Comments</strong>
        </div>
        <div class="col-12">
          <hwa-select
            v-model="loanApplication.section_eight_status"
            placeholder="Select Section Status"
            :options="sectionStatuses"
          />
        </div>
        <div class="col-12">
          <hwa-text-area
            v-model="loanApplication.section_eight_comments"
            placeholder="Comments"
          />
        </div>
      </div>
      <div
        v-if="canUpdate"
        class="row mt-1"
      >
        <div class="col-12">
          <hwa-button
            class="float-right"
            label="Save Changes"
            :loading="saving"
            @onClick="saveChangesToServer()"
          />
        </div>
      </div>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BTabs, BTab, BFormRadioGroup, BFormGroup, BFormCheckbox, BFormCheckboxGroup, BAlert, BOverlay,
} from 'bootstrap-vue'
import showToast from '@/mixins/showToast'
import HwaInput from '@/layouts/components/HwaInput.vue'
import appMixin from '@/views/app/client/applications/appMixin'
import HwaButton from '@/layouts/components/HwaButton.vue'
import HwaSelect from '@/layouts/components/HwaSelect.vue'
import HwaTextArea from '@/layouts/components/HwaTextArea.vue'

export default {
  name: 'SectionEight',
  components: {
    HwaTextArea,
    HwaSelect,
    HwaInput,
    AppCollapse,
    AppCollapseItem,
    BTabs,
    BTab,
    BFormRadioGroup,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    HwaButton,
    BAlert,
    BOverlay,
    ValidationProvider,
  },
  mixins: [showToast, appMixin],
  data: () => ({
    democraticInfoMedium: [
      {
        value: 'face',
        text: 'Face-to-Face Interview (includes Electronic Media w/ Video Component)',
      },
      {
        value: 'phone',
        text: 'Telephone Interview',
      },
      {
        value: 'fax',
        text: 'Fax or Mail ',
      },
      {
        value: 'email',
        text: 'Email or Internet',
      },
    ],
    loanApplication: {},
    hawainRace: [
      { value: 'hawaiian', text: 'Native Hawaiian' },
      { value: 'chamorro', text: 'Guamanian or Chamorro' },
      { value: 'samoan', text: 'Samoan' },
      { value: 'other', text: 'Other Pacific Islander – Print race:' },
    ],
    asianRace: [
      { value: 'indian', text: 'Asian Indian' },
      { value: 'chinese', text: 'Chinese' },
      { value: 'filipino', text: 'Filipino' },
      { value: 'japanese', text: 'Japanese' },
      { value: 'korean', text: 'Korean' },
      { value: 'vietmanese', text: 'Vietnamese' },
      { value: 'other', text: 'Other Hispanic or Latino – Print origin:' },
    ],
    latinoEthnicity: [
      { value: 'mexican', text: 'Mexican' },
      { value: 'rican', text: 'Puerto Rican' },
      { value: 'cuban', text: 'Cuban' },
      { value: 'other', text: 'Other Hispanic or Latino – Print origin' },
    ],
    sex: [
      { value: 'female', text: 'Female' },
      { value: 'male', text: 'Male' },
      { value: 'refuse', text: 'I do not wish to provide this information' },
    ],
  }),
  computed: {
    hasLatino() {
      return this.loanApplication.ethnicity.includes('latino')
    },
    hasOtherPacific() {
      return this.loanApplication.race_hawain.includes('other')
    },
    hasPacific() {
      return this.loanApplication.race.includes('pacific')
    },
    hasAmerican() {
      return this.loanApplication.race.includes('american')
    },
    hasAsian() {
      return this.loanApplication.race.includes('asian')
    },
    canUpdate() {
      return (this.application?.status === 'Started'
          || this.application?.section_eight_status === 'Need more data'
          || this.application?.section_eight_status === 'Has errors')
          && !this.$store.getters['auth/isStaff']
    },
    application() {
      return this.$store.getters['auth/loanApplication']
    },
  },
  watch: {
    hasOtherPacific(pacific) {
      if (!pacific) {
        this.loanApplication.other_race = null
      }
    },
    hasPacific(pacific) {
      if (!pacific) {
        this.loanApplication.race_hawain = []
      }
    },
    hasLatino(latino) {
      if (!latino) {
        this.loanApplication.latino_ethnicity = []
      }
    },
    hasAsian(asian) {
      if (!asian) {
        this.loanApplication.race_asian = []
      }
    },
    hasAmerican(american) {
      if (!american) {
        this.loanApplication.principal_tribe = null
      }
    },
    loanApplication: {
      handler(newVal, oldVal) {
        this.$store.commit('auth/setLoanApplication', newVal)
      },
      deep: true,
    },
  },
  mounted() {
    this.loanApplication = this.application
  },
  methods: {},
}
</script>
<style scoped>

</style>
