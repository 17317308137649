<template>
  <app-collapse
    accordion
    type=""
  >
    <app-collapse-item
      title="Section 2: Financial Information — Assets and Liabilities."
    >
      <b-alert
        :variant="getVariant(loanApplication.section_two_status)"
        :show="loanApplication.section_two_status!==null"
      >
        <h4 class="alert-heading">
          {{ loanApplication.section_two_status }}
        </h4>
        <div class="alert-body">
          <span>{{ loanApplication.section_two_comments||'Section '+loanApplication.section_two_status }}</span>
        </div>
      </b-alert>
      <p>
        This section asks about things you own that
        are worth money and that you want considered to qualify for this loan. It then asks about your
        liabilities (or debts) that you pay
        each month, such as credit cards, alimony, or other expenses.
      </p>
      <b-tabs
        align="left"
      >
        <b-tab
          title="2a. Assets – Bank Accounts, Retirement, and Other Accounts You Have"
        >
          <div class="row mb-1">
            <div class="col-12">
              Include all accounts below. Under Account Type, choose from the types listed here:
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-3">
              • Checking
            </div>
            <div class="col-3">
              • Certificate of Deposit
            </div>
            <div class="col-3">
              • Stock Options
            </div>
            <div class="col-3">
              • Bridge Loan Proceeds
            </div>
            <div class="col-3">• Cash Value of Life Insurance
              (used for the transaction)</div>
            <div class="col-3">
              • Savings
            </div>
            <div class="col-3">
              • Mutual Fund
            </div>
            <div class="col-3">
              • Bonds
            </div>
            <div class="col-3">• Individual Development
              Account</div>
            <div class="col-3">
              • Money Market
            </div>
            <div class="col-3">
              • Stocks
            </div>
            <div class="col-3">
              • Retirement (e.g., 401k, IRA)
            </div>
            <div class="col-3">
              • Trust Account
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              Account Type
            </div>
            <div class="col-3">
              Financial Institution
            </div>
            <div class="col-3">
              Account Number
            </div>
            <div class="col-3">
              Cash or Market Value
            </div>
          </div>
          <div
            v-for="(bank, index) in loanApplication.assets"
            :key="index"
            class="row align-items-center"
          >
            <div class="col-3">
              <hwa-select
                v-model="bank.account_type"
                rules="required"
                :show-label="false"
                label=""
                :options="accountTypes"
              />
            </div>
            <div class="col-3">
              <hwa-input
                v-model="bank.financial_institution"
                rules="required"
                :show-label="false"
                label=""
              />
            </div>
            <div class="col-3">
              <hwa-input
                v-model="bank.account_number"
                rules="required|integer"
                :show-label="false"
                label=""
              />
            </div>
            <div class="col-2">
              <hwa-input
                v-model="bank.market_value"
                rules="required|numeric"
                :show-label="false"
                label="$"
              />
            </div>
            <div class="col-1 mb-1">
              <hwa-button
                :icon-only="true"
                icon="XIcon"
                variant="flat-danger"
                class="float-right"
                @onClick="removeAsset(index)"
              />
            </div>
          </div>
          <div
            v-if="loanApplication.assets.length>0"
            class="row align-items-center"
          >
            <div class="col-9">
              <b class="float-right">Provide TOTAL Amount Here</b>
            </div>
            <div class="col-3">
              <hwa-input
                v-model="loanApplication.total_account_cash_value"
                rules="required|numeric"
                :show-label="false"
                :read-only="true"
                label="$"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <hwa-button
                :icon-only="true"
                icon="PlusIcon"
                variant="outline-success"
                class="float-right"
                @onClick="addAsset()"
              />
            </div>
          </div>
        </b-tab>

      </b-tabs>
      <b-tabs
        align="left"
      >
        <template #tabs-start>
          <div class="mt-1 mr-1">
            <b-form-checkbox
              v-model="loanApplication.other_assets_applies"
            />
          </div>
        </template>
        <b-tab>
          <template slot="title">
            <b @click="loanApplication.other_assets_applies=!loanApplication.other_assets_applies">
              2b. Other Assets and Credits You Have
            </b>
          </template>
          <div v-if="loanApplication.other_assets_applies">
            <div class="row mb-1">
              <div class="col-12">
                Include all other assets and credits below.
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    Assets
                  </div>
                  <div class="col-4"> • Proceeds from Real Estate
                    Property to be sold on or
                    before closing</div>
                  <div class="col-4">  • Proceeds from Sale of
                    Non-Real Estate Asset</div>
                  <div class="col-4">
                    • Secured Borrowed Funds
                  </div>
                  <div class="col-4">
                    • Unsecured Borrowed Funds
                  </div>
                  <div class="col-4">
                    • Other
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    Credits
                  </div>
                  <div class="col-4">
                    • Earnest Money
                  </div>
                  <div class="col-4">
                    • Employer Assistance
                  </div>
                  <div class="col-4">
                    • Lot Equity
                  </div>
                  <div class="col-4">
                    • Relocation Funds
                  </div>
                  <div class="col-4">
                    • Rent Credit
                  </div>
                  <div class="col-4">
                    • Sweat Equity
                  </div>
                  <div class="col-4">
                    • Trade Equity
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-9">
                Asset or Credit Type
              </div>
              <div class="col-3">
                Cash or Market Value
              </div>
            </div>
            <div
              v-for="(asset,index) in loanApplication.other_assets"
              :key="index"
              class="row align-items-center"
            >
              <div class="col-9">
                <hwa-select
                  v-model="asset.asset_type"
                  rules="required"
                  :show-label="false"
                  label=""
                  placeholder="Select Asset"
                  label-name="label"
                  value-name="value"
                  :options="assetsAndCredits"
                />
              </div>
              <div class="col-2">
                <hwa-input
                  v-model="asset.cash_value"
                  rules="required|numeric"
                  :show-label="false"
                  label="$"
                />
              </div>
              <div class="col-1 mb-1">
                <hwa-button
                  :icon-only="true"
                  icon="XIcon"
                  variant="flat-danger"
                  class="float-right"
                  @onClick="removeOtherAsset(index)"
                />
              </div>
            </div>
            <div
              v-if="loanApplication.other_assets.length>0"
              class="row align-items-center"
            >
              <div class="col-9">
                <b class="float-right">Provide TOTAL Amount Here</b>
              </div>
              <div class="col-3">
                <hwa-input
                  v-model="loanApplication.total_asset_cash_value"
                  rules="required|numeric"
                  :show-label="false"
                  :read-only="true"
                  label="$"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hwa-button
                  :icon-only="true"
                  icon="PlusIcon"
                  variant="outline-success"
                  class="float-right"
                  @onClick="addOtherAsset()"
                />
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <b-tabs
        align="left"
      >
        <template #tabs-start>
          <div class="mt-1 mr-1">
            <b-form-checkbox
              v-model="loanApplication.liabilities_applies"
            />
          </div>
        </template>
        <b-tab>
          <template slot="title">
            <b @click="loanApplication.liabilities_applies=!loanApplication.liabilities_applies">
              2c. Liabilities – Credit Cards, Other Debts, and Leases that You Owe
            </b>
          </template>
          <div v-if="loanApplication.liabilities_applies">
            <div class="row">
              <div class="col-12">
                List all liabilities below (except real estate) and include deferred payments. Under Account Type, choose from the types listed here:
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-3">
                • Revolving (e.g., credit cards)
              </div>
              <div class="col-3">
                • Installment (e.g., car, student, personal loans)
              </div>
              <div class="col-3">
                • Open 30-Day (balance paid monthly)
              </div>
              <div class="col-2">
                • Lease (not real estate)
              </div>
              <div class="col-1">
                • Other
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                Account Type
              </div>
              <div class="col-2">
                Company Name
              </div>
              <div class="col-2">
                Account Number
              </div>
              <div class="col-2">
                Unpaid Balance
              </div>
             <div class="col-3">
               <div class="row">
                 <div class="col-4">
                   To be paid off at
                   or before closing
                 </div>
                 <div class="col-5">
                   Monthly Payment
                 </div>
                 <div class="col-4"></div>
               </div>
             </div>
            </div>
            <div
              v-for="(liability, index) in loanApplication.liabilities"
              :key="index"
              class="row align-items-center"
            >
              <div class="col-2">
                <hwa-select
                  v-model="liability.account_type"
                  rules="required"
                  :show-label="false"
                  label=""
                  :options="liabilityTypes"
                />
              </div>
              <div class="col-2">
                <hwa-input
                  v-model="liability.company_name"
                  rules="required"
                  :show-label="false"
                  label=""
                />
              </div>
              <div class="col-2">
                <hwa-input
                  v-model="liability.account_number"
                  rules="required|integer"
                  :show-label="false"
                  label=""
                />
              </div>
              <div class="col-2">
                <hwa-input
                  v-model="liability.unpaid_balance"
                  rules="required|numeric"
                  :show-label="false"
                  type="number"
                  label="$"
                />
              </div>
              <div class="col-4">
                <div class="row">
                  <div class="col-3">
                    <b-form-checkbox v-model="liability.paid_of_before_closing" />
                  </div>
                  <div class="col-5">
                    <hwa-input
                            v-model="liability.monthly_payment"
                            :rules="`required|numeric|max_value:${+liability.unpaid_balance}`"
                            :show-label="false"
                            label="$"
                    />
                  </div>
                  <div class="col-4 mb-1">
                    <hwa-button
                            :icon-only="true"
                            icon="XIcon"
                            variant="flat-danger"
                            class="float-right"
                            @onClick="removeLiability(index)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hwa-button
                  :icon-only="true"
                  icon="PlusIcon"
                  variant="outline-success"
                  class="float-right"
                  @onClick="addLiability()"
                />
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <b-tabs
        align="left"
      >
        <template #tabs-start>
          <div class="mt-1 mr-1">
            <b-form-checkbox
              v-model="loanApplication.other_liabilities_applies"
            />
          </div>
        </template>
        <b-tab>
          <template slot="title">
            <b @click="loanApplication.other_liabilities_applies=!loanApplication.other_liabilities_applies">
              2d. Other Liabilities and Expenses
            </b>
          </template>
          <div v-if="loanApplication.other_liabilities_applies">
            <div class="row mb-1">
              <div class="col-12">
                Include all other liabilities and expenses below. Choose from the types
                listed here:
              </div>
            </div>
            <div class="row">
              <div class="col-9">
                <div class="row">
                  <div class="col-2">
                    • Alimony
                  </div>
                  <div class="col-2">
                    • Child Support
                  </div>
                  <div class="col-2">
                    • Separate Maintenance
                  </div>
                  <div class="col-2">
                    • Job Related Expenses
                  </div>
                  <div class="col-2">
                    • Other
                  </div>
                </div>
              </div>
              <div class="col-3">
                Monthly Payment
              </div>
            </div>
            <div class="row">
              <div class="col-9">
                Type
              </div>
              <div class="col-3">
                Monthly Payment
              </div>
            </div>
            <div
              v-for="(liability,index) in loanApplication.other_liabilities"
              :key="index"
              class="row align-items-center"
            >
              <div class="col-9">
                <hwa-select
                  v-model="liability.type"
                  rules="required"
                  :show-label="false"
                  label=""
                  :options="expenseTypes"
                />
              </div>
              <div class="col-2">
                <hwa-input
                  v-model="liability.monthly_payment"
                  rules="required|numeric"
                  :show-label="false"
                  label="$"
                />
              </div>
              <div class="col-1 mb-1">
                <hwa-button
                  :icon-only="true"
                  icon="XIcon"
                  variant="flat-danger"
                  class="float-right"
                  @onClick="removeOtherLiability(index)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hwa-button
                  :icon-only="true"
                  icon="PlusIcon"
                  variant="outline-success"
                  class="float-right"
                  @onClick="addOtherLiability()"
                />
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <div
        v-if="$store.getters['auth/isStaff']"
        class="row"
      >
        <div class="col-12 mt-1">
          <strong>Staff Comments</strong>
        </div>
        <div class="col-12">
          <hwa-select
            v-model="loanApplication.section_two_status"
            placeholder="Select Section Status"
            :options="sectionStatuses"
          />
        </div>
        <div class="col-12">
          <hwa-text-area
            v-model="loanApplication.section_two_comments"
            placeholder="Comments"
          />
        </div>
      </div>
      <div
        v-if="canUpdate"
        class="row mt-1"
      >
        <div class="col-12">
          <hwa-button
            class="float-right"
            label="Save Changes"
            :loading="saving"
            @onClick="saveChangesToServer()"
          />
        </div>
      </div>
    </app-collapse-item>

  </app-collapse>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BTabs, BTab, BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import showToast from '@/mixins/showToast'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import appMixin from '@/views/app/client/applications/appMixin'
import HwaSelect from '@/layouts/components/HwaSelect.vue'
import HwaTextArea from '@/layouts/components/HwaTextArea.vue'

export default {
  name: 'SectionTwo',
  components: {
    HwaTextArea,
    HwaSelect,
    HwaButton,
    HwaInput,
    AppCollapse,
    AppCollapseItem,
    BTabs,
    BTab,
    BFormCheckbox,
    BAlert,
  },
  mixins: [showToast, appMixin],
  data: () => ({
    loanApplication: {},
    expenseTypes: [
      'Alimony',
      'Child Support',
      'Job Related Expenses',
      'Separate Maintenance',
      'Other',
    ],
    liabilityTypes: [
      'Installment (e.g., car, student, personal loans)',
      'Lease (not real estate)',
      'Open 30-Day (balance paid monthly)',
      'Revolving (e.g., credit cards)',
      'Other',
    ],
    accountTypes: [
      'Checking',
      'Certificate of Deposit',
      'Stock Options',
      'Bridge Loan Proceeds',
      'Cash Value of Life Insurance (used for the transaction)',
      'Savings',
      'Mutual Fund',
      'Bonds',
      'Individual Development Account',
      'Money Market',
      'Stocks',
      'Retirement (e.g., 401k, IRA)',
      'Trust Account',
    ].sort(),
    assetsAndCredits: [...[
      'Proceeds from Real Estate Property to be sold on or before closing',
      'Proceeds from Sale of Non-Real Estate Asset',
      'Secured Borrowed Funds',
      'Unsecured Borrowed Funds',
      'Earnest Money',
      'Employer Assistance',
      'Lot Equity',
      'Relocation Funds',
      'Rent Credit',
      'Sweat Equity',
      'Trade Equity',
    ].sort(), 'Other'],
  }),
  computed: {
    canUpdate() {
      return (this.application?.status === 'Started'
          || this.application?.section_two_status === 'Need more data'
          || this.application?.section_two_status === 'Has errors')
          && !this.$store.getters['auth/isStaff']
    },
    application() {
      return this.$store.getters['auth/loanApplication']
    },
  },
  watch: {
    // totalAsset(total) {
    //   this.loanApplication.total_account_cash_value = total
    // },
    loanApplication: {
      handler(newVal, oldVal) {
        let totalMarketValue = 0
        newVal.assets.forEach(ass => {
          totalMarketValue += +ass.market_value
        })
        newVal.total_account_cash_value = this.parseToFloat(totalMarketValue)
        let totalCashValue = 0
        newVal.other_assets.forEach(ass => {
          totalCashValue += +ass.cash_value
        })
        newVal.total_asset_cash_value = this.parseToFloat(totalCashValue)
        // newVal.total_lian = newVal.liabilities.map(ass => ass.cash_value).filter(ass => ass != null).reduce((pAss, ass) => +pAss + +ass)
        this.$store.commit('auth/setLoanApplication', newVal)
      },
      deep: true,
    },
  },
  mounted() {
    this.loanApplication = this.application
  },
  methods: {
    addLiability() {
      this.loanApplication.liabilities.push({
        account_type: null,
        company_name: null,
        account_number: null,
        unpaid_balance: null,
        paid_of_before_closing: false,
        monthly_payment: null,
      })
    },
    addOtherLiability() {
      this.loanApplication.other_liabilities.push({
        type: null,
        monthly_payment: null,
      })
    },
    addAsset() {
      this.loanApplication.assets.push({
        account_type: null,
        financial_institution: null,
        account_number: null,
        market_value: null,
      })
    },
    addOtherAsset() {
      this.loanApplication.other_assets.push({
        asset_type: null,
        cash_value: null,
      })
    },

    removeLiability(index) {
      this.loanApplication.liabilities.splice(index, 1)
    },
    removeOtherLiability(index) {
      this.loanApplication.other_liabilities.splice(index, 1)
    },
    removeAsset(index) {
      this.loanApplication.assets.splice(index, 1)
    },
    removeOtherAsset(index) {
      this.loanApplication.other_assets.splice(index, 1)
    },
  },
}
</script>

<style scoped>

</style>
