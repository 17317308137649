<template>
  <app-collapse
    accordion
    type=""
  >
    <app-collapse-item
      title="Section 1: Borrower Information"
    >
      <b-alert
        :variant="getVariant(loanApplication.section_one_status)"
        :show="loanApplication.section_one_status!==null"
      >
        <h4 class="alert-heading">
          {{ loanApplication.section_one_status }}
        </h4>
        <div class="alert-body">
          <span>{{ loanApplication.section_one_comments||'Section '+loanApplication.section_one_status }}</span>
        </div>
      </b-alert>
      <p>
        This section asks about your personal information and your income from
        employment and other sources, such as retirement, that you want considered to qualify for this loan.
      </p>
      <b-tabs
        align="left"
      >

        <b-tab
          title="1a. Personal Information"
        >
          <div
            class="my-2 row"
            style="border-bottom: 1px dashed gray"
          >
            <div class="col-6">

              <div class="row">
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.first_name"
                    label="First Name"
                    rules="required"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.middle_name"
                    label="Middle Name"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.last_name"
                    label="Last Name"
                    rules="required"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <hwa-input
                    v-model="loanApplication.alternative_names"
                    label="Alternate Names – List any names by which you are known or any names
under which credit was previously received (First, Middle, Last, Suffix)"
                    placeholder="Alternate Names"
                  />
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row align-items-center">
                <div class="col-10">
                  <hwa-input
                    v-model="loanApplication.social_security_number"
                    :masked="false"
                    :immediate="false"
                    mask="ssn"
                    :rules="`required|${loanApplication.is_tin?'tin':'ssn'}`"
                    placeholder="###-##-####"
                    :label="loanApplication.is_tin?'Individual Taxpayer Identification Number':'Social Security Number'"
                  />

                </div>
                <div class="col-2">
                  <validation-provider
                    #default="{ errors }"
                    :immediate="false"
                    vid="is_tin"
                    rules="required"
                  >
                    <b-form-checkbox
                      v-model="loanApplication.is_tin"
                    >
                      TIN
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col">
                  <hwa-date-picker
                    v-model="loanApplication.date_of_birth"
                    rules="required"
                    label="Date of Birth"
                  />
                </div>
                <div class="col">
                  <validation-provider
                    #default="{ errors }"
                    :immediate="false"
                    vid="citizenship"
                    rules="required"
                  >
                    <b-form-group>
                      <template #label>
                        <b>Citizenship</b>
                      </template>
                      <b-form-radio-group
                        v-model="loanApplication.citizenship"
                        :options="citizenship"
                        stacked
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
          <div
            class="my-2 row"
            style="border-bottom: 1px dashed gray"
          >
            <div class="col-6">
              <b-form-group>
                <template #label>
                  <b>Type of Credit</b>
                </template>
                <validation-provider
                  #default="{ errors }"
                  :immediate="false"
                  vid="credit_type"
                  rules="required"
                >
                  <b-form-radio-group
                    v-model="loanApplication.credit_type"
                    :options="typeOfCreditOptions"
                    value-field="value"
                    text-field="label"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <b-overlay
                  :show="!jointCredit"
                  :opacity="0.5"
                >
                  <template #overlay>
                    <div />
                  </template>
                  <div
                    class="mt-1"
                  >
                    <hwa-input
                      v-model="loanApplication.number_of_borrowers"
                      :rules="jointCredit?'required|integer':''"
                      label="Total Number of Borrowers"
                    />
                    <hwa-input
                      v-model="loanApplication.initials"
                      :rules="jointCredit?'required':''"
                      label="Each Borrower intends to apply for joint credit."
                      placeholder="Your initials"
                    />
                  </div>
                </b-overlay>

              </b-form-group>
            </div>
            <div class="col-6">
              <b-overlay
                :show="!jointCredit"
                :opacity="0.5"
              >
                <template #overlay>
                  <div />
                </template>
                <div class="row">
                  <div class="col-12">
                    <b>List Name(s)</b> of Other Borrower(s) Applying for this Loan
                    (First, Middle, Last, Suffix) – Use a separator between names
                  </div>
                </div>
                <hwa-text-area
                  v-model="loanApplication.other_borrower_names"
                  :rules="jointCredit?'required':''"
                  label=""
                  :rows="7"
                />
              </b-overlay>
            </div>
          </div>
          <div
            class="my-2 row"
            style="border-bottom: 1px dashed gray"
          >
            <div class="col-6">
              <div class="row align-items-center">
                <div class="col">
                  <validation-provider
                    #default="{ errors }"
                    :immediate="false"
                    vid="marital_status"
                    rules="required"
                  >
                    <b-form-group style="margin-top: -65px!important;">
                      <template #label>
                        <b>Marital Status</b>
                      </template>
                      <b-form-radio-group
                        v-model="loanApplication.marital_status"
                        :options="maritalStatuses"
                        stacked
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <div
                  class="col"
                  style="height: 300px; overflow-y: auto"
                >
                  <label for="">
                    <b>Dependents</b> (not listed by another Borrower)
                  </label>
                  <hwa-input
                    v-model="loanApplication.number_of_dependants"
                    rules="required|integer"
                    label="Number"
                  />
                  <div
                    v-for="(item,index) in getArray(+loanApplication.number_of_dependants)"
                    :key="index"
                    class=""
                  >
                    <hwa-input
                      v-model="loanApplication.dependants_ages[getIndex(index)].age"
                      rules="required"
                      label="Age"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col">
                  <b>Contact Information</b>
                </div>
              </div>
              <hwa-input
                v-model="loanApplication.applicant_home_phone"
                rules="required|phone"
                label="Home Phone"
                :masked="true"
                placeholder="###-###-####"
              />
              <hwa-input
                v-model="loanApplication.applicant_cell_phone"
                rules="required|phone"
                label="Cell Phone"
                :masked="true"
                placeholder="###-###-####"
              />
              <div class="row">
                <div class="col">
                  <hwa-input
                    v-model="loanApplication.applicant_work_phone"
                    rules="required|phone"
                    label="Work Phone"
                    :masked="true"
                    placeholder="###-###-####"
                  />
                </div>
                <div class="col">
                  <hwa-input
                    v-model="loanApplication.applicant_ext"
                    label="Ext"
                  />
                </div>
              </div>
              <hwa-input
                v-model="loanApplication.applicant_email"
                rules="required|email"
                label="Email"
              />
            </div>
          </div>
          <div
            class="my-2 row"
            style="border-bottom: 1px dashed gray"
          >
            <div class="col-12">
              <strong for="">
                Current Address
              </strong>
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.applicant_street"
                rules="required"
                label="Street"
              />
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.applicant_unit"
                label="Unit#"
              />
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.applicant_city"
                rules="required"
                label="City"
              />
            </div>
            <div class="col-4">
              <hwa-select
                v-model="loanApplication.applicant_state"
                rules="required"
                label="State"
                :options="states"
              />
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.applicant_zip"
                rules="required"
                label="ZIP"
              />
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.applicant_country"
                rules="required"
                label="Country"
              />
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <b>How long have you been living at this address?</b>
                </div>
                <div class="col-3">
                  <hwa-input
                    v-model="loanApplication.current_address_years"
                    rules="required|integer"
                    label="Years"
                  />
                </div>
                <div class="col-3">
                  <hwa-input
                    v-model="loanApplication.current_address_months"
                    rules="required|integer"
                    label="Months"
                  />
                </div>
                <div class="col-4">
                  <validation-provider
                    #default="{ errors }"
                    :immediate="false"
                    vid="housing"
                    rules="required"
                  >
                    <b-form-group>
                      <template #label>
                        <b>Housing</b>
                      </template>
                      <b-form-radio-group
                        v-model="loanApplication.housing"
                        :options="housings"
                        stacked
                        @change="clearHousing"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <div class="col-2">
                  <hwa-input
                    v-model="loanApplication.rent_per_month"
                    :rules="`${loanApplication.housing!=='rent'?'':'required'}|numeric`"
                    label="Rent Per Month"
                    placeholder="$"
                    :read-only="loanApplication.housing!=='rent'"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex">
              <div class="">
                <b-form-checkbox
                  v-model="loanApplication.former_address_applies"
                >
                  <b>If at Current Address for LESS than 2 years, list Former Address</b>
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div
            v-if="loanApplication.former_address_applies"
            class="my-2 row"
            style="border-bottom: 1px dashed gray"
          >
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.former_street"
                rules="required"
                label="Street"
              />
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.former_unit"
                label="Unit#"
              />
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.former_city"
                rules="required"
                label="City"
              />
            </div>
            <div class="col-4">
              <hwa-select
                v-model="loanApplication.former_state"
                rules="required"
                label="State"
                :options="states"
              />
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.former_zip"
                rules="required"
                label="ZIP"
              />
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.former_country"
                rules="required"
                label="Country"
              />
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <b>How long have you been living at this address?</b>
                </div>
                <div class="col-3">
                  <hwa-input
                    v-model="loanApplication.former_address_years"
                    rules="required|integer"
                    label="Years"
                  />
                </div>
                <div class="col-3">
                  <hwa-input
                    v-model="loanApplication.former_address_months"
                    rules="required|integer"
                    label="Months"
                  />
                </div>
                <div class="col-4">
                  <validation-provider
                    #default="{ errors }"
                    :immediate="true"
                    vid="housing"
                    rules="required"
                  >
                    <b-form-group>
                      <template #label>
                        <b>Housing</b>
                      </template>
                      <b-form-radio-group
                        v-model="loanApplication.former_housing"
                        :options="housings"
                        stacked
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <div class="col-2">
                  <hwa-input
                    v-model="loanApplication.former_rent_per_month"
                    :rules="`${loanApplication.former_housing!=='rent'?'':'required'}|numeric`"
                    label="Rent Per Month"
                    placeholder="$"
                    :read-only="loanApplication.former_housing!=='rent'"
                    @change="clearFormerHousing"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-1 mb-2">
            <div class="col-12 d-flex">
              <div class="">
                <b-form-checkbox
                  v-model="loanApplication.mailing_address_applies"
                >
                  <b>Mailing Address – if different from Current Address</b>
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div
            v-if="loanApplication.mailing_address_applies"
            class="row mb-2"
          >
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.mailing_street"
                rules="required"
                label="Street"
              />
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.mailing_unit"
                label="Unit#"
              />
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.mailing_city"
                rules="required"
                label="City"
              />
            </div>
            <div class="col-4">
              <hwa-select
                v-model="loanApplication.mailing_state"
                rules="required"
                :options="states"
                label="State"
              />
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.mailing_zip"
                rules="required"
                label="ZIP"
              />
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.mailing_country"
                rules="required"
                label="Country"
              />
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <b-tabs
        align="left"
      >
        <template #tabs-start>
          <div class="mt-1 mr-1">
            <b-form-checkbox
              v-model="loanApplication.current_employment_applies"
            />
          </div>
        </template>
        <b-tab>
          <template slot="title">
            <b @click="loanApplication.current_employment_applies=!loanApplication.current_employment_applies">
              1b. Current Employment/Self-Employment and Income
            </b>
          </template>
          <div
            v-if="loanApplication.current_employment_applies"
            class="row"
          >
            <div class="col-8">
              <div class="row">
                <div
                  class="col-12"
                  style="visibility: hidden"
                >
                  nothing
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-8">
                      <hwa-input
                        v-model="loanApplication.business_name"
                        rules="required"
                        label="Employer or Business Name"
                      />
                    </div>
                    <div class="col-4">
                      <hwa-input
                        v-model="loanApplication.business_phone"
                        rules="required|phone"
                        label="Phone"
                        :masked="true"
                        placeholder="###-###-####"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.business_street"
                    rules="required"
                    label="Street"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.business_unit"
                    label="Unit#"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.business_city"
                    rules="required"
                    label="City"
                  />
                </div>
                <div class="col-4">
                  <hwa-select
                    v-model="loanApplication.business_state"
                    rules="required"
                    :options="states"
                    label="State"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.business_zip"
                    rules="required"
                    label="ZIP"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.business_country"
                    rules="required"
                    label="Country"
                  />
                </div>
                <div
                  style="border-bottom: 1px dashed gray"
                  class="col-12 mb-2"
                />
                <div class="col-6">
                  <hwa-input
                    v-model="loanApplication.business_position"
                    rules="required"
                    label="Position or Title"
                  />
                </div>
                <div class="col-6">
                  <hwa-date-picker
                    v-model="loanApplication.business_start_date"
                    rules="required"
                    label="Start Date (mm/dd/yyyy)"
                    :show-label="true"
                  />
                </div>
                <div class="col-12">
                  <b>How long in this line of work?</b>
                </div>
                <div class="col-6">
                  <hwa-input
                    v-model="loanApplication.business_duration_years"
                    rules="required|integer"
                    label="Years"
                  />
                </div>
                <div class="col-6">
                  <hwa-input
                    v-model="loanApplication.business_duration_months"
                    rules="required|integer"
                    label="Months"
                  />
                </div>
                <div class="col-12">
                  <b>Check if this statement applies:</b>
                </div>
                <div class="col-12">
                  <b-form-checkbox
                    v-model="loanApplication.employed_by_family"
                  >
                    I am employed by a family member,
                    property seller, real estate agent, or other
                    party to the transaction.
                  </b-form-checkbox>
                </div>
                <div class="col-12">
                  <b-form-checkbox
                    v-model="loanApplication.business_owner"
                  >
                    Check if you are the Business
                    Owner or Self-Employed
                  </b-form-checkbox>
                </div>
                <b-overlay
                  :show="!isBusinessOwner"
                  :opacity="0.5"
                  class="col-12"
                >
                  <template #overlay>
                    <div />
                  </template>
                  <div class="row mt-1">
                    <div class="col">
                      <validation-provider
                        #default="{ errors }"
                        :immediate="false"
                        name="business stake"
                        :rules="isBusinessOwner?'required':''"
                      >
                        <b-form-group label="">
                          <b-form-radio-group
                            v-model="loanApplication.business_stake"
                            :options="stakeOptions"
                            stacked
                          />
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div class="col">
                      <hwa-input
                        v-model="loanApplication.business_monthly_income"
                        :rules="isBusinessOwner?'required|numeric':''"
                        label="Monthly Income (or Loss)"
                        :show-label="false"
                      />
                    </div>
                  </div>
                </b-overlay>
              </div>
            </div>
            <div class="col-4">
              <div class="row">
                <div class="col-12">
                  <b>Gross Monthly Income</b>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <hwa-input
                    v-model="loanApplication.business_base"
                    rules="required|numeric"
                    label=" Base $/month"
                  />
                  <hwa-input
                    v-model="loanApplication.business_overtime"
                    rules="required|numeric"
                    label="Overtime $/month"
                  />
                  <hwa-input
                    v-model="loanApplication.business_bonus"
                    rules="required|numeric"
                    label="Bonus $/month"
                  />
                  <hwa-input
                    v-model="loanApplication.business_commission"
                    rules="required|numeric"
                    label="Commission $/month"
                  />
                  <hwa-input
                    v-model="loanApplication.business_military_entitlement"
                    rules="required|numeric"
                    label="Military Entitlements $/month"
                  />
                  <hwa-input
                    v-model="loanApplication.business_other"
                    rules="required|numeric"
                    label="Other $/month"
                  />
                  <hwa-input
                    v-model="loanApplication.business_gross_income_total"
                    rules="required"
                    :read-only="true"
                    label="Total $/month"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-tab>

      </b-tabs>
      <b-tabs
        align="left"
      >
        <template #tabs-start>
          <div class="mt-1 mr-1">
            <b-form-checkbox
              v-model="loanApplication.additional_employment_applies"
            />
          </div>
        </template>
        <b-tab>
          <template slot="title">
            <b @click="loanApplication.additional_employment_applies=!loanApplication.additional_employment_applies">1c.
              IF APPLICABLE, Complete Information for Additional Employment/Self-Employment and Income</b>
          </template>
          <div
            v-if="loanApplication.additional_employment_applies"
            class="row"
          >
            <div class="col-8">
              <div class="row">
                <div
                  class="col-12"
                  style="visibility: hidden"
                >
                  nothing
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-8">
                      <hwa-input
                        v-model="loanApplication.additional_business_name"
                        rules="required"
                        label="Employer or Business Name"
                      />
                    </div>
                    <div class="col-4">
                      <hwa-input
                        v-model="loanApplication.additional_business_phone"
                        rules="required|phone"
                        label="Phone"
                        :masked="true"
                        placeholder="###-###-####"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.additional_business_street"
                    rules="required"
                    label="Street"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.additional_business_unit"
                    label="Unit#"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.additional_business_city"
                    rules="required"
                    label="City"
                  />
                </div>
                <div class="col-4">
                  <hwa-select
                    v-model="loanApplication.additional_business_state"
                    rules="required"
                    :options="states"
                    label="State"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.additional_business_zip"
                    rules="required"
                    label="ZIP"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.additional_business_country"
                    rules="required"
                    label="Country"
                  />
                </div>
                <div class="col-6">
                  <hwa-input
                    v-model="loanApplication.additional_business_title"
                    rules="required"
                    label="Position or Title"
                  />
                </div>
                <div class="col-6">
                  <hwa-date-picker
                    v-model="loanApplication.additional_business_start_date"
                    rules="required"
                    label="Start Date (mm/dd/yyyy)"
                    :show-label="true"
                  />
                </div>
                <div class="col-12">
                  <b>How long in this line of work?</b>
                </div>
                <div class="col-6">
                  <hwa-input
                    v-model="loanApplication.additional_business_duration_years"
                    rules="required|integer"
                    label="Years"
                  />
                </div>
                <div class="col-6">
                  <hwa-input
                    v-model="loanApplication.additional_business_duration_months"
                    rules="required|integer"
                    label="Months"
                  />
                </div>
                <div class="col-12">
                  <b>Check if this statement applies:</b>
                </div>
                <div class="col-12">
                  <b-form-checkbox
                    v-model="loanApplication.additional_business_employed_by_family"
                  >
                    I am employed by a family member,
                    property seller, real estate agent, or other
                    party to the transaction.
                  </b-form-checkbox>
                </div>
                <div class="col-12">
                  <b-form-checkbox
                    v-model="loanApplication.additional_business_owner"
                  >
                    Check if you are the Business
                    Owner or Self-Employed
                  </b-form-checkbox>
                </div>
                <b-overlay
                  :show="!isAdditionalBusinessOwner"
                  :opacity="0.5"
                  class="col-12"
                >
                  <template #overlay>
                    <div />
                  </template>
                  <div class="row mt-1">
                    <div class="col">
                      <validation-provider
                        #default="{ errors }"
                        :immediate="false"
                        name="business stake"
                        :rules="isAdditionalBusinessOwner?'required':''"
                      >
                        <b-form-group label="">
                          <b-form-radio-group
                            v-model="loanApplication.additional_business_stake"
                            :options="stakeOptions"
                            stacked
                          />
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div class="col">
                      <hwa-input
                        v-model="loanApplication.additional_business_monthly_income"
                        :rules="isAdditionalBusinessOwner?'required|numeric':''"
                        label="Monthly Income (or Loss)"
                        :show-label="false"
                      />
                    </div>
                  </div>
                </b-overlay>
              </div>
            </div>
            <div class="col-4">
              <div class="row">
                <div class="col-12">
                  <b>Gross Monthly Income</b>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <hwa-input
                    v-model="loanApplication.additional_business_base"
                    rules="required|numeric"
                    label=" Base $/month"
                  />
                  <hwa-input
                    v-model="loanApplication.additional_business_overtime"
                    rules="required|numeric"
                    label="Overtime $/month"
                  />
                  <hwa-input
                    v-model="loanApplication.additional_business_bonus"
                    rules="required|numeric"
                    label="Bonus $/month"
                  />
                  <hwa-input
                    v-model="loanApplication.additional_business_commission"
                    rules="required|numeric"
                    label="Commission $/month"
                  />
                  <hwa-input
                    v-model="loanApplication.additional_business_military_entitlement"
                    rules="required|numeric"
                    label="Military Entitlements $/month"
                  />
                  <hwa-input
                    v-model="loanApplication.additional_business_other"
                    rules="required|numeric"
                    label="Other $/month"
                  />
                  <hwa-input
                    v-model="loanApplication.additional_business_gross_income_total"
                    rules="required"
                    label="Total $/month"
                    :read-only="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <b-tabs
        align="left"
      >
        <template #tabs-start>
          <div class="mt-1 mr-1">
            <b-form-checkbox
              v-model="loanApplication.previous_employment_applies"
            />
          </div>
        </template>
        <b-tab>
          <template slot="title">
            <b @click="loanApplication.previous_employment_applies=!loanApplication.previous_employment_applies">1d.
              IF APPLICABLE, Complete Information for Previous Employment/Self-Employment and Income</b>
          </template>
          <div
            v-if="loanApplication.previous_employment_applies"
            class="row"
          >
            <div class="col-12 mb-1">
              Provide at least 2 years of current and previous employment and income.
            </div>
          </div>
          <div
            v-if="loanApplication.previous_employment_applies"
            class="row"
          >
            <div class="col-8">
              <div class="row">
                <div
                  class="col-12"
                  style="visibility: hidden"
                >
                  nothing
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                      <hwa-input
                        v-model="loanApplication.previous_business_name"
                        rules="required"
                        label="Employer or Business Name"
                      />
                    </div>
                  </div>
                </div>
                <!--                <div class="col-4">-->
                <!--                  <hwa-input-->
                <!--                    v-model="loanApplication.previous_business_phone"-->
                <!--                    rules="required|phone"-->
                <!--                    label="Phone"-->
                <!--                    :masked="true"-->
                <!--                    placeholder="###-###-####"-->
                <!--                  />-->
                <!--                </div>-->
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.previous_business_street"
                    label="Unit#"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.previous_business_unit"
                    rules="required"
                    label="City"
                  />
                </div>
                <div class="col-4">
                  <hwa-select
                    v-model="loanApplication.previous_business_city"
                    rules="required"
                    :options="states"
                    label="State"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.previous_business_state"
                    rules="required"
                    label="ZIP"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.previous_business_zip"
                    rules="required"
                    label="Country"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.previous_business_country"
                    rules="required"
                    label="Position or Title"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.previous_business_title"
                    rules="required"
                    label="Title"
                    :show-label="true"
                  />
                </div>
                <div class="col-4">
                  <hwa-date-picker
                    v-model="loanApplication.previous_business_start_date"
                    rules="required"
                    label="Start Date"
                    name="start_date"
                  />
                </div>
                <div class="col-4">
                  <hwa-date-picker
                    v-model="loanApplication.previous_business_end_date"
                    rules="required"
                    label="End Date"
                    name="end_date"
                  />
                </div>
                <div class="col-12 mb-2">
                  <b-form-checkbox
                    v-model="loanApplication.previous_business_owner"
                  >
                    Check if you were the Business
                    Owner or Self-Employed
                  </b-form-checkbox>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row">
                <div class="col-12">
                  <b>Previous Gross Monthly</b>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <hwa-input
                    v-model="loanApplication.previous_business_income"
                    rules="required|numeric"
                    label=" Income $/month"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <b-tabs
        align="left"
      >
        <template #tabs-start>
          <div class="mt-1 mr-1">
            <b-form-checkbox
              v-model="loanApplication.other_income_source_applies"
            />
          </div>
        </template>
        <b-tab>
          <template slot="title">
            <b @click="loanApplication.other_income_source_applies=!loanApplication.other_income_source_applies">1e.
              Income from Other Sources</b>
          </template>
          <div v-if="loanApplication.other_income_source_applies">
            <div class="row">
              <div class="col-12 mb-1">
                Include income from other sources below. Under Income Source, choose from the sources
                listed here:
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                • Alimony
              </div>
              <div class="col-3">
                • Child Support
              </div>
              <div class="col-3">
                • Interest and Dividends
              </div>
              <div class="col-3">
                • Notes Receivable
              </div>
              <div class="col-3">
                • Royalty Payments
              </div>
              <div class="col-3">
                • Unemployment Benefits
              </div>
              <div class="col-3">
                • Automobile Allowance
              </div>
              <div class="col-3">
                • Disability
              </div>
              <div class="col-3">
                • Mortgage Credit Certificate
              </div>
              <div class="col-3">
                • Public Assistance
              </div>
              <div class="col-3">
                • Separate Maintenance
              </div>
              <div class="col-3">
                • VA Compensation
              </div>
              <div class="col-3">
                • Boarder Income
              </div>
              <div class="col-3">
                • Foster Care
              </div>
              <div class="col-3"> • Mortgage Differential
                Payments
              </div>
              <div class="col-3"> • Retirement
                (e.g., Pension, IRA)
              </div>
              <div class="col-3">
                • Social Security
              </div>
              <div class="col-3">
                • Other
              </div>
              <div class="col-3">
                • Capital Gains
              </div>
              <div class="col-3">
                • Housing or Parsonage
              </div>
              <div class="col-3">
                • Trust
              </div>
            </div>
            <div class="row mb-1 mt-1">
              <div class="col-12">
                <b>NOTE:</b> Reveal alimony, child support, separate maintenance, or other income ONLY IF you
                want it considered in determining your qualification
                for this loan.
              </div>
            </div>
            <div class="row justify-content-start">
              <div class="col-7">
                <b>Income Source </b>– use list above
              </div>
              <div class="col-4">
                Monthly Income
              </div>
              <div class="col-1" />
            </div>
            <div
              v-for="(income_source,index) in loanApplication.other_income_sources "
              :key="index"
              class="row"
            >
              <div class="col-7">
                <hwa-select
                  v-model="income_source.source"
                  rules="required"
                  label=""
                  :show-label="false"
                  :options="incomeSources"
                />
              </div>
              <div class="col-4">
                <hwa-input
                  v-model="income_source.income"
                  rules="required|numeric"
                  label="$"
                  :show-label="false"
                />
              </div>
              <div class="col-1">
                <hwa-button
                  :icon-only="true"
                  icon="XIcon"
                  variant="flat-danger"
                  class="float-right"
                  @onClick="removeItem(index)"
                />
              </div>
            </div>
            <div
              v-if="loanApplication.other_income_sources.length>0"
              class="row align-items-center"
            >
              <div class="col-7">
                <b class="float-right">Provide TOTAL Amount Here</b>
              </div>
              <div class="col-5">
                <hwa-input
                  v-model="loanApplication.total_monthly_income"
                  rules="required|numeric"
                  :show-label="false"
                  :read-only="true"
                  label="$"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hwa-button
                  :icon-only="true"
                  icon="PlusIcon"
                  variant="outline-success"
                  class="float-right"
                  @onClick="addIncomeSource()"
                />
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <div
        v-if="$store.getters['auth/isStaff']"
        class="row"
      >
        <div class="col-12 mt-1">
          <strong>Staff Comments</strong>
        </div>
        <div class="col-12">
          <hwa-select
            v-model="loanApplication.section_one_status"
            placeholder="Select Section Status"
            :options="sectionStatuses"
          />
        </div>
        <div class="col-12">
          <hwa-text-area
            v-model="loanApplication.section_one_comments"
            placeholder="Comments"
          />
        </div>
      </div>
      <div
        v-if="canUpdate"
        class="row mt-1"
      >
        <div class="col-12">
          <hwa-button
            class="float-right"
            label="Save Changes"
            :loading="saving"
            @onClick="saveChangesToServer()"
          />
        </div>
      </div>
    </app-collapse-item>
  </app-collapse>
</template>
<script>
import { ValidationProvider } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BTabs, BTab, BFormRadioGroup, BFormGroup, BFormCheckbox, BAlert, BOverlay,
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import showToast from '@/mixins/showToast'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaTextArea from '@/layouts/components/HwaTextArea.vue'
import HwaDatePicker from '@/layouts/components/HwaDatePicker.vue'
import appMixin from '@/views/app/client/applications/appMixin'
import HwaSelect from '@/layouts/components/HwaSelect.vue'

export default {
  name: 'SectionOne',
  components: {
    HwaSelect,
    HwaTextArea,
    HwaInput,
    HwaDatePicker,
    HwaButton,
    AppCollapse,
    AppCollapseItem,
    BTabs,
    BTab,
    BFormRadioGroup,
    BFormGroup,
    BFormCheckbox,
    BAlert,
    BOverlay,
    ValidationProvider,
  },
  mixins: [showToast, appMixin],
  data: () => ({
    justMounted: false,
    collapseType: 'margin',
    housings: [
      { value: 'primary', text: 'No primary housing expense' },
      { value: 'own', text: 'Own' }, { value: 'rent', text: 'Rent' },
    ],
    occupancies: [
      'Primary Residence',
      'Second Home',
      'Investment Property',
    ],
    loanPurposes: [
      'Purchase',
      'Refinance',
      'Other (specify)',
    ],
    citizenship: [
      { value: 'us', text: 'U.S. Citizen' },
      { value: 'permanent', text: 'Permanent Resident Alien' },
      { value: 'non_permanent', text: 'Non-Permanent Resident Alien' },
    ],
    typeOfCreditOptions: [
      {
        value: 'individual',
        label: 'I am applying for individual credit.',
      },
      {
        value: 'joint',
        label: 'I am applying for joint credit',
      },
    ],
    selectedMaritalStatus: null,
    maritalStatuses: [
      'Married',
      'Separated',
      'Unmarried(Single, Divorced, Widowed, Civil Union, Domestic Partnership, '
        + 'Registered Reciprocal Beneficiary Relationship)',
    ],
    stakeOptions: [
      { value: 'less', text: 'I have an ownership share of less than 25%.' },
      {
        value: 'more',
        text: 'I have an ownership share of 25% or more.',
      },
    ],
    incomeSources: [...[
      'Alimony',
      'Child Support',
      'Interest and Dividends',
      'Notes Receivable',
      'Royalty Payments',
      'Unemployment Benefits',
      'Automobile Allowance',
      'Disability',
      'Mortgage Credit Certificate',
      'Public Assistance',
      'Separate Maintenance',
      'VA Compensation',
      'Boarder Income',
      'Foster Care',
      'Mortgage Differential Payments',
      'Retirement (e.g., Pension, IRA)',
      'Social Security',
      'Capital Gains',
      'Housing or Parsonage',
      'Trust',
    ].sort(), 'Other'],
  }),
  computed: {
    errors() {
      return this.$v.loanApplication
    },
    canUpdate() {
      return (this.application?.status === 'Started'
          || this.application?.section_one_status === 'Need more data'
          || this.application?.section_one_status === 'Has errors')
          && !this.$store.getters['auth/isStaff']
    },
    totalIncome() {
      return this.parseToFloat(
        (
          +this.loanApplication.business_base
                      + +this.loanApplication.business_overtime
                      + +this.loanApplication.business_bonus
                      + +this.loanApplication.business_commission
                      + +this.loanApplication.business_military_entitlement
                      + +this.loanApplication.business_other
        ),
      )
    },
    additionalTotalIncome() {
      return this.parseToFloat(
              (
                      +this.loanApplication.additional_business_base
                      + +this.loanApplication.additional_business_overtime
                      + +this.loanApplication.additional_business_bonus
                      + +this.loanApplication.additional_business_commission
                      + +this.loanApplication.additional_business_military_entitlement
                      + +this.loanApplication.additional_business_other
              )
      )
    },
    application() {
      return this.$store.getters['auth/loanApplication']
    },
    jointCredit() {
      return this.loanApplication.credit_type === 'joint'
    },
    isBusinessOwner() {
      return this.loanApplication.business_owner
    },
    isAdditionalBusinessOwner() {
      return this.loanApplication.additional_business_owner
    },
  },
  watch: {
    isBusinessOwner(owner) {
      if (!owner) {
        this.loanApplication.business_stake = []
        this.loanApplication.business_monthly_income = null
      }
    },
    isAdditionalBusinessOwner(owner) {
      if (!owner) {
        this.loanApplication.additional_business_stake = []
        this.loanApplication.additional_business_monthly_income = null
      }
    },
    jointCredit(joint) {
      if (!joint) {
        this.loanApplication.number_of_borrowers = null
        this.loanApplication.initials = null
        this.loanApplication.other_borrower_names = null
      }
    },
    totalIncome(total) {
      this.loanApplication.business_gross_income_total = this.parseToFloat(total)
    },
    additionalTotalIncome(total) {
      this.loanApplication.additional_business_gross_income_total = this.parseToFloat(total)
    },
    application() {
      if (!this.justMounted) {
        this.$v.loanApplication.$touch()
      }
    },
    loanApplication: {
      handler(newValue, oldValue) {
        if (!this.justMounted) {
          this.$v.loanApplication.$touch()
        }

        let totalIncome = 0
        newValue.other_income_sources.forEach(ass => {
          totalIncome += +ass.income
        })
        newValue.total_monthly_income = this.parseToFloat(totalIncome)
        this.justMounted = false
        this.$store.commit('auth/setLoanApplication', newValue)
      },
      deep: true,
    },
  },
  mounted() {
    this.loanApplication = this.application
    this.justMounted = true
  },
  methods: {
    clearHousing(e) {
      if (this.loanApplication.housing !== 'rent') {
        this.loanApplication.rent_per_month = ''
      }
    },
    clearFormerHousing(e) {
      if (this.loanApplication.former_housing !== 'rent') {
        this.loanApplication.former_rent_per_month = ''
      }
    },
    handleSectionOneValid() {
    },
    getArray(length) {
      const arr = []
      for (let i = 0; i < length; i++) {
        arr.push(i)
      }
      return arr
    },
    getIndex(index) {
      // const newIndex = index - 1
      if (index <= this.loanApplication?.dependants_ages?.length - 1) {
        return index
      }
      this.loanApplication?.dependants_ages?.push({ age: null })
      return index
    },
    removeItem(index) {
      this.loanApplication.other_income_sources.splice(index, 1)
    },
    addIncomeSource() {
      this.loanApplication.other_income_sources.push({
        income: null,
        source: null,
      })
    },
  },
}
</script>
