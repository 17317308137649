var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mev-chat-section"},[_c('div',{staticClass:"chat-side-bar"},[_c('div',{staticClass:"top-bar"},[(_vm.appBarData.photo !== null)?_c('b-avatar',{attrs:{"src":_vm.getImage(_vm.appBarData.photo)}}):_c('b-avatar',{attrs:{"text":("" + (_vm.appBarData.first_name.substr(
        0,
        1
      )) + (_vm.appBarData.last_name.substr(0, 1))),"variant":"info"}}),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1),_c('b-form-input',{attrs:{"placeholder":"Search"}})],1)],1),_c('div',{staticClass:"contact-view"},[_vm._m(0),_c('div',{staticClass:"contact-list-box"},_vm._l((_vm.contacts),function(item){return _c('div',{key:item.id,staticClass:"contact-list",class:_vm.active_contact === item.id ? 'active' : '',on:{"click":function($event){return _vm.getMessage(item)}}},[(item.avatar !== null)?_c('b-avatar',{attrs:{"src":_vm.getImage(item.avatar)}}):_c('b-avatar',{attrs:{"text":("" + (item.first_name.substr(0, 1)) + (item.last_name.substr(
            0,
            1
          ))),"variant":"success"}}),_c('div',{staticClass:"contact-content"},[_c('div',{staticClass:"name-date-view"},[_c('h5',[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))]),_c('span',{},[_c('b-badge',{staticClass:"badge-glow",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(item.role)+" ")])],1)]),_c('small',[_vm._v(_vm._s(_vm.date(item.created_at)))])])],1)}),0)])]),_c('div',{staticClass:"chat-body-content"},[_c('div',{staticClass:"body-content",attrs:{"id":"chat-body"}},_vm._l((_vm.chats),function(item){return _c('div',{key:item.id,staticClass:"chat-content-box"},[(item.sender_id !== _vm.appBarData.id)?_c('div',{staticClass:"client-chat-box"},[(item.avatar !== null)?_c('b-avatar',{attrs:{"src":_vm.getImage(item.avatar)}}):_c('b-avatar',{attrs:{"text":("" + (item.first_name.substr(0, 1)) + (item.last_name.substr(
            0,
            1
          ))),"variant":"warning"}}),_c('div',[_c('div',{staticClass:"message-box"},[_vm._v(" "+_vm._s(item.message)+" ")]),_c('span',{staticClass:"float-left message-fullname-view"},[_vm._v(_vm._s(item.full_name)+" "),_c('span',{},[_c('b-badge',{staticClass:"badge-glow",attrs:{"variant":"warning"}},[_vm._v(" "+_vm._s(item.role)+" ")])],1)])])],1):_vm._e(),(item.sender_id === _vm.appBarData.id)?_c('div',{staticClass:"user-chat-box"},[_c('div',[_c('div',{staticClass:"message-box"},[_vm._v(" "+_vm._s(item.message)+" ")]),_c('span',{staticClass:"float-right message-fullname-view"},[_c('span',{},[_c('b-badge',{staticClass:"badge-glow",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(item.role)+" ")])],1),_vm._v(" "+_vm._s(item.full_name))])]),(item.avatar !== null)?_c('b-avatar',{attrs:{"src":_vm.getImage(item.avatar)}}):_c('b-avatar',{attrs:{"text":("" + (item.first_name.substr(
            0,
            1
          )) + (item.last_name.substr(0, 1))),"variant":"primary"}})],1):_vm._e()])}),0),(_vm.$can('participate-in-mortgage-application-chats', 'hwa'))?_c('div',{staticClass:"form-box"},[_c('b-form-input',{attrs:{"id":"basicInput","placeholder":"Enter message here..."},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendMessage($event)}},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('div',{staticClass:"button-view"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":_vm.sendMessage}},[_vm._v(" Send Message ")])],1)],1):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-heading"},[_c('h4',{staticClass:"text-primary"},[_vm._v("Participants")])])}]

export { render, staticRenderFns }