<template>
  <app-collapse
    accordion
    type=""
  >
    <app-collapse-item
      title="Section 10: Application Attachments."
    >
      <div class="row">
        <div class="col-12">
          Add attachments to your application here, they will be reviewed and approved.
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-12 mt-2">
                  <strong>Attachments</strong>
                </div>
              </div>
              <div class="row">
                <div
                  v-for="(attachment,index) in loanApplication.attachments"
                  :key="index"
                  class="col-12 mb-1"
                >
                  <b-badge
                    variant="light-primary"
                  >
                    <feather-icon
                      icon="PaperclipIcon"
                      class="mr-1"
                    />
                    {{ attachment.name }}
                  </b-badge>
                  <hwa-button
                    class="ml-1"
                    variant="flat-primary"
                    icon="DownloadCloudIcon"
                    label="Download"
                    @onClick="downloadAttachment(attachment)"
                  />
                  <hwa-button
                    v-if="canUpdate || (attachment.status !=='Approved' && attachment.status && !$store.getters['auth/isStaff'])"
                    class="ml-1"
                    variant="flat-danger"
                    icon="XIcon"
                    :icon-only="true"
                    @onClick="removeFile(application['application_id'],attachment.id,'purchase')"
                  />
                  <div
                    v-if="(attachment.status !=='Approved' && attachment.status)"
                    class="row"
                  >
                    <div class="col-12">
                      <b-alert
                        :variant="attachment.status==='Approved'?'success':'danger'"
                        :show="!!attachment.status"
                      >
                        <h4 class="alert-heading">
                          {{ attachment.status }}
                        </h4>
                        <div class="alert-body">
                          <span>{{ attachment.comments||'Attachment '+attachment.status }}.</span>
                          <br>
                          <b-badge
                            v-if="!$store.getters['auth/isStaff']"
                            variant="danger"
                          >
                            {{ 'Please upload a replacement before removing this attachment' }}
                          </b-badge>
                        </div>
                      </b-alert>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div
                        v-if="$store.getters['auth/isStaff']"
                        class="row"
                      >
                        <div class="col-12">
                          <hwa-select
                            v-model="attachment.status"
                            placeholder="Select an Action"
                            :options="attachmentsStatuses"
                          />
                        </div>
                        <div class="col-12">
                          <hwa-text-area
                            v-model="attachment.comments"
                            placeholder="Comments"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="canUpdate || canUpdateAttachments"
              class="col-12"
            >
              <div class="row">
                <div class="col-12 mt-2">
                  <hwa-file-button
                    label="Attachments"
                    :multiple="true"
                    variant="outline-primary"
                    :loading="uploading"
                    @onFileUpload="(files)=>{uploadFiles(files)}"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import showToast from '@/mixins/showToast'
import appMixin from '@/views/app/client/applications/appMixin'
import HwaButton from '@/layouts/components/HwaButton.vue'
import HwaFileButton from '@/layouts/components/HwaFileButton.vue'
import HwaSelect from '@/layouts/components/HwaSelect.vue'
import HwaTextArea from '@/layouts/components/HwaTextArea.vue'
import { BAlert, BBadge } from 'bootstrap-vue'

export default {
  name: 'SectionTen',
  components: {
    HwaTextArea,
    HwaSelect,
    HwaFileButton,
    AppCollapse,
    AppCollapseItem,
    HwaButton,
    BAlert,
    BBadge,
  },
  mixins: [showToast, appMixin],
  data: () => ({
    loanApplication: {},
    uploading: false,
    attachmentsStatuses: ['Approved', 'Rejected'],
  }),
  computed: {
    canUpdate() {
      return this.application?.status === 'Started'
    },
    application() {
      return this.$store.getters['auth/loanApplication']
    },
    canUpdateAttachments() {
      return this.loanApplication.attachments?.some(attachment => (attachment.status !== 'Approved' && attachment.status))
    },
  },
  watch: {
    loanApplication: {
      handler(newVal, oldVal) {
        this.$store.commit('auth/setLoanApplication', newVal)
      },
      deep: true,
    },
  },
  mounted() {
    this.loanApplication = this.application
  },
  methods: {
    downloadAttachment(attachment) {
      const link = document.createElement('a')
      link.download = attachment.name
      link.href = `${process.env.VUE_APP_STORAGE_PATH_MORTGAGE}/${attachment.path}`
      link.target = '_blank'
      link.click()
    },
    removeFile(id, attachmentId) {
      this.$http.delete(`api/mortgage/remove_attachments/${attachmentId}`).then(res => {
        this.loanApplication.attachments = this.loanApplication.attachments.filter(at => +at.id !== attachmentId)
        this.showSuccess('Attachment was removed successfully')
      }).catch(err => {
        this.showError('Failed to remove file')
      })
    },
    uploadFiles(files) {
      this.uploading = true
      const attachments = new FormData()
      attachments.append('application_id', this.loanApplication.id)
      files.forEach(file => {
        attachments.append('attachments[]', file)
      })
      this.$http.post('api/mortgage/attachments', attachments).then(res => {
        this.loanApplication.attachments = res.data
        this.uploading = false
        this.showSuccess('Uploaded attachment successfully')
      }).catch(err => {
        this.uploading = false
        this.showError('Failed to upload files')
      })
    },
  },
}
</script>

<style scoped>

</style>
