<template>
  <div id="section_one">
      <!-- Define header and footer blocks before your content -->
      <header>
        <div style="position: relative">
          <div
            style="
            text-align: left;
            position: absolute;
            top: 5px;
            left: 10px;
            font-style: italic;
          "
          >
            To be completed by the <strong>Lender:</strong>
          </div>
          <div
            style="
            text-align: left;
            position: absolute;
            top: 18px;
            left: 10px;
            right: 10px;
            width: 100%;
          "
          >
            <table>
              <tr>
                <td>Lender Loan No./Universal Loan Identifier</td>
                <td />
                <td
                  style="width: 50%"
                  class="outline-input"
                />
                <td>Agency Case No</td>
                <td />
                <td
                  style="width: 14%"
                  class="outline-input"
                >
                  <!-- <div style="border-bottom:1px solid;"></div> -->
                </td>
              </tr>
            </table>
          </div>
        </div>
      </header>

      <footer>
        <p style="margin-top: 5px !important;">
          Uniform Residential Loan Application
        </p>
        <p>Freddie Mac Form 65 • Fannie Mae Form 1003</p>
        <p style="font-style:italic;">
          Effective 1/2021
        </p>
      </footer>
      <!-- Wrap the content of your PDF inside a main tag -->
      <main>
        <div style="position: relative; width: 100%">
          <div
            style="position: absolute; left: 0px; top: -10px"
            class="header-numbering"
          >
            <strong> Uniform Residential Loan Application </strong>
          </div>
          <div
            style="
            position: absolute;
            left: 0px;
            top: 0px;
            font-size: 13px;
            border-bottom: 2px solid black;
          "
          >
            <p>
              <strong>Verify and complete the information on this application.</strong>
              If you are applying for this loan with others, each additional
              Borrower must provide information as directed by your Lender.
            </p>
          </div>
          <div style="position: absolute; top: 65px">
            <div>
              <strong class="header-numbering">Section 1: Borrower Information.</strong>
              <span style="font-size: 13px">This section asks about your personal information and your income
                from employment and other sources, such as retirement, that you
                want considered to qualify for this loan.</span>
            </div>
          </div>
          <div style="position: absolute; top: 120px; width: 100%">
            <div style="position: relative">
              <div
                style="
                border-radius: 10px 10px 0px 0px;
                position: absolute;
                top: 0;
                font-size: 14px;
                color: white;
                width: 150px;
                height: 15px;
                background-color: black;
                padding: 5px;
              "
              >
                <strong>1a. Personal Information</strong>
              </div>
              <div
                style="
                top: 24px;
                position: absolute;
                border-bottom: 1px solid black;
                width: 100%;
              "
              />
            </div>
          </div>
          <div
            style="
            position: absolute;
            top: 120px;
            width: 100%;
            border-bottom: 1px solid black;
            height: 150px;
          "
          >
            <div style="position: relative">
              <div
                style="
                position: absolute;
                top: 30px;
                font-size: 14px;
                width: 430px;
              "
              >
                <table>
                  <tr>
                    <strong>Name</strong>
                    (First, Middle, Last, Suffix)
                  </tr>
                  <tr>
                    <div style="height: 25px; background-color: #f1f4ff" />
                  </tr>
                  <tr>
                    <strong>Alternate Names – </strong>
                    List any names by which you are known or any names under which
                    credit was previously received (First, Middle, Last, Suffix)
                  </tr>
                  <tr>
                    <div style="height: 25px; background-color: #f1f4ff" />
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div
            style="
            position: absolute;
            left: 58%;
            top: 145px;
            border-bottom: 1px solid black;
            height: 125px;
            border-left: 1px solid black;
          "
          >
            <div style="position: relative">
              <div
                style="
                position: absolute;
                top: 0px;
                font-size: 14px;
                width: 350px;
              "
              >
                <table>
                  <tr>
                    <td>
                      <strong style="font-size: 13px; width: 10px">Social Security Number</strong>
                    </td>
                    <td>
                      <div
                        style="width: 50px; height: 18px"
                        class="outline-input"
                      />
                    </td>
                    <td><strong>-</strong></td>
                    <td>
                      <div
                        style="width: 50px; height: 18px"
                        class="outline-input"
                      />
                    </td>
                    <td><strong>-</strong></td>
                    <td>
                      <div
                        style="width: 50px; height: 18px"
                        class="outline-input"
                      />
                    </td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <td>(or Individual Taxpayer Identification Number)</td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <td style="width: 150px">
                      <strong>Date of Birth</strong>
                    </td>
                    <td><strong>Citizenship</strong></td>
                  </tr>
                  <tr>
                    <td>(mm/dd/yyyy)</td>
                    <td style="display: flex">
                      <div class="circle" />
                      <span style="margin-top: 1px">U.S. Citizen</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 150px">
                      <div style="display: flex">
                        <div
                          style="width: 40px; height: 18px"
                          class="outline-input"
                        />
                        <strong style="margin-top: 5px">/</strong>
                        <div
                          style="width: 40px; height: 18px"
                          class="outline-input"
                        />
                        <strong style="margin-top: 5px">/</strong>
                        <div
                          style="width: 40px; height: 18px"
                          class="outline-input"
                        />
                      </div>
                    </td>
                    <td style="display: flex">
                      <div class="circle" />
                      <span style="margin-top: 1px">
                        Permanent Resident Alien</span>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td style="display: flex; margin-top: -5px">
                      <div class="circle" />
                      <span style="margin-top: 1px">
                        Non-Permanent Resident Alien</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div
            style="
            position: absolute;
            top: 240px;
            width: 100%;
            border-bottom: 1px solid black;
            height: 150px;
          "
          >
            <div style="position: relative">
              <div
                style="
                position: absolute;
                top: 30px;
                font-size: 14px;
                width: 430px;
              "
              >
                <p>
                  <strong> Type of Credit</strong>
                </p>
                <table style="margin-top: 5px">
                  <tr>
                    <td style="display: flex">
                      <div class="circle" />
                      <span style="margin-top: -1px; font-size: 13px">I am applying for <strong>individual credit</strong>.
                      </span>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td style="display: flex">
                      <div class="circle" />
                      <span style="margin-top: -1px; font-size: 13px">I am applying for joint credit. Total Number of
                        Borrowers:
                      </span>
                    </td>
                    <td>
                      <div
                        style="width: 50px; height: 18px"
                        class="outline-input"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style="display: flex">
                      <span style="margin-top: 1px; font-size: 13px">Each Borrower intends to apply for joint credit.
                        <strong>Your initials:</strong>
                      </span>
                    </td>
                    <td>
                      <div
                        style="width: 50px; height: 18px"
                        class="outline-input"
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div
            style="
            position: absolute;
            left: 58%;
            top: 260px;
            border-bottom: 1px solid black;
            height: 125px;
          "
          >
            <div style="position: relative">
              <div
                style="
                position: absolute;
                top: 0px;
                font-size: 13px;
                width: 350px;
              "
              >
                <p>
                  <strong>
                    List Name(s) of Other Borrower(s) Applying for this
                    Loan</strong>
                  (First, Middle, Last, Suffix) – Use a separator between namesbr
                </p>
                <div
                  style="width: 300px; height: 60px"
                  class="outline-input"
                />
              </div>
            </div>
          </div>
          <div
            style="
            position: absolute;
            top: 365px;
            width: 100%;
            border-bottom: 1px solid black;
            height: 150px;
          "
          >
            <div style="position: relative">
              <div
                style="
                position: absolute;
                top: 30px;
                font-size: 14px;
                width: 430px;
              "
              >
                <table>
                  <tr>
                    <td style="width: 150px">
                      <strong>Marital Status</strong>
                    </td>
                    <td style="width: 200px">
                      <strong>Dependents</strong> (not listed by another Borrower)
                    </td>
                  </tr>
                  <tr>
                    <td style="display: flex">
                      <div class="circle" />
                      <span style="margin-top: 1px">Married</span>
                    </td>
                    <td>Number</td>
                  </tr>
                  <tr>
                    <td style="display: flex">
                      <div class="circle" />
                      <span style="margin-top: 1px"> Separated</span>
                    </td>
                    <td style="width: 150px">
                      Ages
                    </td>
                  </tr>
                  <tr>
                    <td style="display: flex; margin-top: 1px">
                      <div class="circle" />
                      <span style="margin-top: 1px"> Unmarried</span>
                    </td>
                    <td />
                  </tr>
                </table>

                <table>
                  <tr style="width: 300px">
                    <td style="display: flex; margin-top: 1px">
                      <span style="margin-top: 1px">
                        (Single, Divorced, Widowed, Civil Union, Domestic
                        Partnership, Registered Reciprocal Beneficiary
                        Relationship)</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div
            style="
            position: absolute;
            left: 58%;
            top: 390px;
            border-bottom: 1px solid black;
            height: 125px;
            border-left: 1px solid black;
          "
          >
            <div style="position: relative">
              <div
                style="
                position: absolute;
                top: 5px;
                left: 2px;
                font-size: 14px;
                width: 350px;
              "
              >
                <strong style="margin-left: 2px;"> Contact Information </strong>
                <table>
                  <tr>
                    <td>
                      <strong style="font-size: 13px; width: 10px">Home </strong>
                      Phone
                    </td>
                    <td>
                      <div
                        style="width: 50px; height: 18px"
                        class="outline-input"
                      />
                    </td>
                    <td><strong>-</strong></td>
                    <td>
                      <div
                        style="width: 50px; height: 18px"
                        class="outline-input"
                      />
                    </td>
                    <td><strong>-</strong></td>
                    <td>
                      <div
                        style="width: 50px; height: 18px"
                        class="outline-input"
                      />
                    </td>
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>
                      <strong style="font-size: 13px; width: 10px">Cell </strong>
                      Phone
                    </td>
                    <td>
                      <div
                        style="width: 50px; height: 18px"
                        class="outline-input"
                      />
                    </td>
                    <td><strong>-</strong></td>
                    <td>
                      <div
                        style="width: 50px; height: 18px"
                        class="outline-input"
                      />
                    </td>
                    <td><strong>-</strong></td>
                    <td>
                      <div
                        style="width: 50px; height: 18px"
                        class="outline-input"
                      />
                    </td>
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>
                      <strong style="font-size: 13px; width: 10px">Work </strong>
                      Phone
                    </td>
                    <td>
                      <div
                        style="width: 50px; height: 18px"
                        class="outline-input"
                      />
                    </td>
                    <td><strong>-</strong></td>
                    <td>
                      <div
                        style="width: 50px; height: 18px"
                        class="outline-input"
                      />
                    </td>
                    <td><strong>-</strong></td>
                    <td>
                      <div
                        style="width: 50px; height: 18px"
                        class="outline-input"
                      />
                    </td>
                    <td><strong>Ext.</strong></td>
                    <td>
                      <div
                        style="width: 50px; height: 18px"
                        class="outline-input"
                      />
                    </td>
                  </tr>

                </table>
                <table>
                  <tr>
                    <td><strong style=" font-size: 14px;">Email</strong></td>
                    <td>
                      <div
                        style="width: 300px; height: 18px"
                        class="outline-input"
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div
            style="
          position: absolute;
          top: 500px;
          width: 100%;
          border-bottom: 1px solid black;
          height: 130px;
        "
          >
            <div style="position: relative">
              <div
                style="
              position: absolute;
              top: 30px;
              font-size: 14px;
              width: 100%;
            "
              >
                <strong>Current Address</strong>
                <table>
                  <tr style="width: 100%;">
                    <td>Street</td>
                    <td /><td
                      style="width: 800px"
                      class="outline-input"
                    />
                    </td>
                    <td>Unit#</td>
                    <td /><td
                      style="width: 100px"
                      class="outline-input"
                    />
                    </td>
                  </tr>
                </table>
                <table style="margin-top: 10px;">
                  <tr style="width: 100%;">
                    <td>City</td>
                    <td /><td
                      style="width: 600px"
                      class="outline-input"
                    />
                    </td>
                    <td>State</td>
                    <td /><td
                      style="width: 100px"
                      class="outline-input"
                    />
                    </td>
                    <td>Zip</td>
                    <td /><td
                      style="width: 100px"
                      class="outline-input"
                    />
                    </td>
                    <td>Country</td>
                    <td /><td
                      style="width: 100px"
                      class="outline-input"
                    />
                    </td>
                  </tr>
                </table>
                <table style="margin-top: 10px;">
                  <tr style="display: flex; width: 100%;">
                    <td style="width:150px">
                      How Long at Current Address?
                    </td>
                    <td /><td
                      style="width: 50px"
                      class="outline-input"
                    />
                    </td>
                    <td>Years</td>
                    <td /><td
                      style="width: 50px"
                      class="outline-input"
                    />
                    </td>
                    <td>Months</td>
                    <td
                      style="width: 50px"
                      class="outline-input"
                    />
                    <td style="width: 50px;" />
                    <td style="width: 50px">
                      <strong>Housing</strong>
                    </td>
                    <td style="width:150px;display: flex">
                      <div class="circle" />
                      <span style="margin-top: 1px"> No primary housing expense</span>
                    </td>

                    <td style="display: flex; width: 70px;">
                      <div class="circle" />
                      <span style="margin-top: 1px"> Own</span>
                    </td>
                    <td style="display: flex; width: 70px;">
                      <div class="circle" />
                      <span style="margin-top: 1px"> Rent</span>
                    </td>
                    <td>$</td>
                    <td /><td
                      style="width: 50px"
                      class="outline-input"
                    />
                    </td>
                    <td><strong>/</strong></td>
                    <td>Month</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div
            style="
        position: absolute;
        top: 610px;
        width: 100%;
        border-bottom: 1px solid black;
        height: 110px;
      "
          >
            <div style="position: relative">
              <div
                style="
            position: absolute;
            top: 30px;
            font-size: 14px;
            width: 100%;
          "
              >
                <table>
                  <tr style="width: 100%;">
                    <td style="width: 250px;">
                      <strong>Mailing Address</strong> – if different from Current Address
                    </td>
                    <td style="display: flex; width: 100px;">
                      <div class="square" />
                      <span style="margin-top: 1px"> <strong> Does not apply</strong></span>
                    </td>
                  </tr>
                </table>
                <table>
                  <tr style="width: 100%;">
                    <td>Street</td>
                    <td /><td
                      style="width: 800px"
                      class="outline-input"
                    />
                    </td>
                    <td>Unit#</td>
                    <td /><td
                      style="width: 100px"
                      class="outline-input"
                    />
                    </td>
                  </tr>
                </table>
                <table style="margin-top: 10px;">
                  <tr style="width: 100%;">
                    <td>City</td>
                    <td /><td
                      style="width: 600px"
                      class="outline-input"
                    />
                    </td>
                    <td>State</td>
                    <td /><td
                      style="width: 100px"
                      class="outline-input"
                    />
                    </td>
                    <td>Zip</td>
                    <td /><td
                      style="width: 100px"
                      class="outline-input"
                    />
                    </td>
                    <td>Country</td>
                    <td /><td
                      style="width: 100px"
                      class="outline-input"
                    />
                    </td>
                  </tr>
                </table>

              </div>
            </div>

            <div style="position: absolute; top: 120px; width: 100%">
              <div style="position: relative">
                <div
                  style="
              border-radius: 10px 10px 0px 0px;
              position: absolute;
              top: 0;
              font-size: 14px;
              color: white;
              width: 350px;
              height: 15px;
              background-color: black;
              padding: 5px;
            "
                >
                  <strong>1b. Current Employment/Self-Employment and Income</strong>
                </div>
                <div style=" position: absolute; left:400px; top:5px; display: flex; width: 100px;">
                  <div class="square" />
                  <span style="margin-top: 1px"> <strong> Does not apply</strong></span>
                </div>
                <div
                  style="
              top: 24px;
              position: absolute;
              border-bottom: 1px solid black;
              width: 100%;
            "
                />
              </div>
            </div>
            <div
              style="
          position: absolute;
          top: 120px;
          width: 100%;
          border-bottom: 1px solid black;
          height: 230px;
        "
            >
              <div style="position: relative">
                <div
                  style="
              position: absolute;
              top: 30px;
              font-size: 14px;
              width: 600px;
            "
                >
                  <table>
                    <tr>
                      <td style="width: 150px;">
                        <strong>Employer or Business Name</strong>
                      </td>
                      <td
                        style="width: 250px"
                        class="outline-input"
                      />
                      <td style="width: 20px;" />
                      <td style="width: 30px;">
                        <strong>Phone</strong>
                      </td>
                      <td>(</td>
                      <td
                        style="width: 50px"
                        class="outline-input"
                      />
                      <td>)</td>
                      <td
                        style="width: 50px"
                        class="outline-input"
                      />
                      <td style="width: 10px;">
                        <strong>-</strong>
                      </td>
                      <td
                        style="width: 150px"
                        class="outline-input"
                      />
                    </tr>
                  </table>
                  <table style="margin-top: 10px;">
                    <tr style="width: 100%;">
                      <td>Street</td>
                      <td /><td
                        style="width: 800px"
                        class="outline-input"
                      />
                      </td>
                      <td>Unit#</td>
                      <td /><td
                        style="width: 100px"
                        class="outline-input"
                      />
                      </td>
                    </tr>
                  </table>
                  <table style="margin-top: 10px;">
                    <tr style="width: 100%;">
                      <td>City</td>
                      <td /><td
                        style="width: 600px"
                        class="outline-input"
                      />
                      </td>
                      <td>State</td>
                      <td /><td
                        style="width: 100px"
                        class="outline-input"
                      />
                      </td>
                      <td>Zip</td>
                      <td /><td
                        style="width: 100px"
                        class="outline-input"
                      />
                      </td>
                      <td>Country</td>
                      <td /><td
                        style="width: 100px"
                        class="outline-input"
                      />
                      </td>
                    </tr>
                  </table>
                  <div style="position:relative; border-top: 1px solid black; margin-top: 10px; width: 608px;">
                    <div style="position: absolute;">
                      <table>
                        <tr>
                          <td><strong style="font-size: 13px;">Position or Title</strong></td>
                          <td
                            style="width: 240px"
                            class="outline-input"
                          />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                      </table>
                      <table>
                        <tr>
                          <td><strong style="font-size: 13px;">Start Date</strong></td>
                          <td
                            style="width: 50px"
                            class="outline-input"
                          />
                          <td>/</td>
                          <td
                            style="width: 50px"
                            class="outline-input"
                          />
                          <td>/</td>
                          <td
                            style="width: 70px"
                            class="outline-input"
                          />
                          <td>(mm/dd/yyyy)</td>
                        </tr>
                      </table>
                      <table>
                        <tr>
                          <td style="font-size: 13px;">
                            How long in this line of work?
                          </td>
                          <td
                            style="width: 30px"
                            class="outline-input"
                          />
                          <td>Years</td>
                          <td
                            style="width: 30px"
                            class="outline-input"
                          />
                          <td>Months</td>
                        </tr>
                      </table>
                    </div>
                    <div
                      style="position: absolute; top: 0; left: 350px; border-left: 1px solid black; height: 65px; padding: 5px;"
                    >
                      <strong>Check if this statement applies:</strong>
                      <td>
                        <div style="display: flex;">
                          <div
                            style="
                        height: 10px;
                        margin-right: 3px;
                        width: 25px;
                        border: 1px solid black;"
                          />
                          <span style="margin-top: -1.5px">I am employed by a family member,
                            property seller, real estate agent, or other
                            party to the transaction.</span>
                        </div>
                      </td>
                    </div>
                  </div>
                  <div style="position:relative; border-top: 1px solid black; margin-top: 75px; width: 608px;">
                    <div style="position:absolute; top:10px;">
                      <table>
                        <tr>
                          <td style="width: 150px;">
                            <div style="display: flex;">
                              <div
                                style="
                          height: 10px;
                          margin-right: 3px;
                          width: 15px;
                          border: 1px solid black;"
                              />
                              <span style="margin-top: 0px"><strong>Check if you are the Business
                                Owner or Self-Employed</strong></span>
                            </div>
                          </td>
                          <td style="display: flex; width:400px;">
                            <div class="circle" />
                            <span style="margin-top: 1px; margin-right: 10px !important;">I have an ownership share of less
                              than 25%. </span> <strong style="margin-top: 1px;">Monthly Income (or Loss)</strong>
                          </td>
                          <td />
                        </tr>
                      </table>
                      <table style="margin-top: -13px;">
                        <tr>
                          <td style="width: 150px;" />
                          <td style="display: flex; width:200px;">
                            <div class="circle" />
                            <span style="margin-top: 1px; margin-right: 10px !important;">I have an ownership share of 25%
                              or more. </span>
                          </td>
                          <td>$</td>
                          <td
                            style="width: 50px"
                            class="outline-input"
                          />
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style="
          position: absolute;
          left: 70%;
          top: 145px;
          border-bottom: 1px solid black;
          height: 205px;
          border-left: 1px solid black;"
            >
              <div style="position: relative">
                <div
                  style="
              position: absolute;
              top: 0px;
              font-size: 14px;
              width: 350px;
            "
                >
                  <strong style="margin-top: 2px; margin-left: 2px;">
                    Gross Monthly Income
                  </strong>
                  <table>
                    <tr>
                      <td style="width: 70px;font-size: 14px;">
                        Base
                      </td>
                      <td><strong>$</strong></td>
                      <td
                        style="width: 50px"
                        class="outline-input"
                      />
                      <td>/</td>
                      <td style="width: 70px;">
                        month
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 70px;">
                        Overtime
                      </td>
                      <td><strong>$</strong></td>
                      <td
                        style="width: 50px"
                        class="outline-input"
                      />
                      <td>/</td>
                      <td style="width: 70px;">
                        month
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 70px;">
                        Bonus
                      </td>
                      <td><strong>$</strong></td>
                      <td
                        style="width: 50px"
                        class="outline-input"
                      />
                      <td>/</td>
                      <td style="width: 70px;">
                        month
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 70px;">
                        Commission
                      </td>
                      <td><strong>$</strong></td>
                      <td
                        style="width: 50px"
                        class="outline-input"
                      />
                      <td>/</td>
                      <td style="width: 70px;">
                        month
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 70px;">
                        Military Entitlements
                      </td>
                      <td><strong>$</strong></td>
                      <td
                        style="width: 50px"
                        class="outline-input"
                      />
                      <td>/</td>
                      <td style="width: 70px;">
                        month
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 70px;">
                        Other
                      </td>
                      <td><strong>$</strong></td>
                      <td
                        style="width: 50px"
                        class="outline-input"
                      />
                      <td>/</td>
                      <td style="width: 70px;">
                        month
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 70px;">
                        <strong>TOTAL</strong>
                      </td>
                      <td><strong>$</strong></td>
                      <td
                        style="width: 50px"
                        class="outline-input"
                      />
                      <td>/</td>
                      <td style="width: 70px;">
                        <strong>month</strong>
                      </td>
                    </tr>
                  </table>
                </div>

              </div>

            </div>
          </div>
        </div>
      </main>
  </div>
</template>

<script>
export default {
  name: 'TestGenerate',
  methods: {
    generate() {

    },
  },
}
</script>

<style scoped>
    @page {
        margin: 20px 30px;
    }

    /** Define now the real margins of every page in the PDF **/
    body {
        margin-top: 2cm;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        font-size: 11px !important;
    }

    /** Define the header rules **/
    header {
        position: fixed;
        top: 0cm;
        left: 0cm;
        right: 0cm;
        height: 1.2cm;

        /** Extra personal styles **/
        background-color: #e6e6e6;
        color: rgb(4, 4, 4);
    }

    table td {
        font-size: 10px !important;
    }

    .outline-input {
        border-bottom: 0.7px solid;
        background-color: #f1f4ff;
    }

    .header-numbering {
        font-size: 20px;
    }

    .circle {
        height: 10px;
        width: 10px;
        border: 1px solid black;
        border-radius: 50%;
        margin-right: 3px;
    }
    .selected{
        /* border: 4px solid black; */
        background-color: black;
    }
    .square {
        height: 10px;
        width: 10px;
        border: 1px solid black;
        margin-right: 3px;
    }

    /* table {
        border-spacing: 0;
        border-collapse: collapse;
      }
      table td {
        padding: 0 !important;
        margin: 0 !important;
      } */
    footer {
        position: fixed;
        bottom: 0cm;
        left: 0cm;
        right: 0cm;
        height: 2cm;

        /** Extra personal styles **/
        background-color: #fff;
        border-top: 2px solid black;
        color: black;
        text-align: left;
    }
    footer p{
        margin: 0 !important;
        padding: 0 !important;
    }
</style>
