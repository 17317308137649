<template>
  <validation-provider
    #default="{ errors }"
    :vid="name"
    :name="formatName(name)"
    :rules="rules"
  >
    <label
      v-if="label"
      :for="name"
    >{{ label }}</label>
    <multiselect
      :value="value"
      :multiple="true"
      :label="labelName"
      :track-by="valueName"
      :options="options"
      :placeholder="placeholder"
      :close-on-select="true"
      hide-selected
      @input="handleSelectChange"
    />
    <small class="text-danger">{{ errors[0] }}</small>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import Multiselect from 'vue-multiselect'

export default {
  name: 'HwaMultiSelect',
  components: {
    ValidationProvider,
    Multiselect,
  },
  props: {
    options: { type: Array, default: () => [] },
    value: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: 'input',
    },
    labelName: {
      type: String,
      default: 'label',
    },
    valueName: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select One',
    },
    rules: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // selected: this.value,
    }
  },
  computed: {
    // selected() {
    //   return this.value
    // },
  },
  watch: {
    // selected(val) {
    //   this.handleSelectChange(val)
    // },
  },
  methods: {
    formatName(name) {
      return name.replaceAll('_', ' ')
    },
    handleSelectChange(e) {
      this.$emit('input', e)
    },
    // handleRemove(e){
    //   console.log(e,'remove')
    // },
    // handleSelect(e){
    //   console.log(e,'select')
    // }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
