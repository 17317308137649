<template>
  <app-collapse
    accordion
    type=""
  >
    <app-collapse-item
      title="Section 3: Financial Information — Real Estate."
    >
      <b-alert
        :variant="getVariant(loanApplication.section_three_status)"
        :show="loanApplication.section_three_status!==null"
      >
        <h4 class="alert-heading">
          {{ loanApplication.section_three_status }}
        </h4>
        <div class="alert-body">
          <span>{{ loanApplication.section_three_comments||'Section '+loanApplication.section_three_status }}</span>
        </div>
      </b-alert>
      <p>
        This section asks you to list all properties you currently own
        and what you owe on them.
      </p>
      <b-form-checkbox
        v-model="loanApplication.i_own_a_property"
        class="mb-1"
      >
        I own real estate
      </b-form-checkbox>
      <div v-if="loanApplication.i_own_a_property">
        <b-tabs
          align="left"
        >
          <b-tab
            title="3a. Property You Own"
          >
            <div>
              <div class="row">
                <div class="col-12">
                  <label for="">
                    Address
                  </label>
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.property_address"
                    rules="required"
                    label="Street"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.property_unit"
                    label="Unit#"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.property_city"
                    rules="required"
                    label="City"
                  />
                </div>
                <div class="col-4">
                  <hwa-select
                    v-model="loanApplication.property_state"
                    rules="required"
                    :options="states"
                    label="State"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.property_zip"
                    rules="required"
                    label="ZIP"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.property_country"
                    rules="required"
                    label="Country"
                  />
                </div>
              </div>
              <div
                class="row align-items-end mt-1"
              >
                <div class="col-2">
                  Property Value
                </div>
                <div class="col-2">
                  Status
                </div>
                <div class="col-2">Intended Occupancy
                </div>
                <div class="col-2">Monthly Insurance,Taxes,
                  Association Dues, etc.
                  if not included in Monthly
                  Mortgage Payment
                </div>
                <div class="col-2">Monthly Rental
                  Income
                </div>
                <div class="col-2">For LENDER to calculate:
                  Net Monthly Rental Income
                </div>
              </div>
              <div class="row">
                <div class="col-2">
                  <hwa-input
                    v-model="loanApplication.property_value"
                    rules="required"
                    label="$"
                    :show-label="false"
                  />
                </div>
                <div class="col-2">
                  <hwa-select
                    v-model="loanApplication.property_status"
                    rules="required"
                    label=""
                    :show-label="false"
                    :options="propertyStatuses"
                  />
                </div>
                <div class="col-2">
                  <hwa-select
                    v-model="loanApplication.property_intended_occupancy"
                    rules="required"
                    label=""
                    :show-label="false"
                    :options="intendedOccupancies"
                  />
                </div>
                <div class="col-2">
                  <hwa-input
                    v-model="loanApplication.property_monthly_insurance"
                    rules="required|numeric"
                    label="$"
                    :show-label="false"
                  />
                </div>
                <div class="col-2">
                  <hwa-input
                    v-model="loanApplication.property_monthly_rental_income"
                    :rules="`${loanApplication.property_intended_occupancy==='Investment'?'required':''}|numeric`"
                    label="$"
                    :show-label="false"
                  />
                </div>
                <div class="col-2">
                  <hwa-input
                    v-model="loanApplication.property_net_monthly_rental_income"
                    rules="required|numeric"
                    label="$"
                    :show-label="false"
                    :read-only="true"
                  />
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-12  mb-2 d-flex">

                  <b-form-checkbox
                    v-model="loanApplication.property_loan_applies"
                  >
                    <b> Mortgage Loans on this Property</b>
                  </b-form-checkbox>
                </div>
              </div>
              <div v-if="loanApplication.property_loan_applies">
                <div
                  class="row align-items-end"
                >
                  <div class="col-2">
                    Creditor Name
                  </div>
                  <div class="col-2">
                    Account Number
                  </div>
                  <div class="col-3">
                    <div class="row">
                      <div class="col">Monthly
                        Mortgage
                        Payment
                      </div>
                      <div class="col">
                        Unpaid Balance
                      </div>
                    </div>
                  </div>
                  <div class="col-1">To be paid off at
                    or before closing
                  </div>
                  <div class="col-2">Type: FHA, VA,
                    Conventional,
                    USDA-RD, Other
                  </div>
                  <div class="col-2">
                    Credit Limit
                    (if applicable)
                  </div>
                </div>
                <div
                  v-for="(loan,index) in loanApplication.property_loans"
                  :key="index"
                  class="row align-items-center"
                >
                  <div class="col-2">
                    <hwa-input
                      v-model="loan.creditor_name"
                      rules="required"
                      :show-label="false"
                      label=""
                    />
                  </div>
                  <div class="col-2">
                    <hwa-input
                      v-model="loan.account_number"
                      rules="required|integer"
                      :show-label="false"
                      label=""
                    />
                  </div>
                   <div class="col-3">
                     <div class="row">
                       <div class="col">
                         <hwa-input
                                 v-model="loan.monthly_mortgage_payment"
                                 rules="required|numeric"
                                 :show-label="false"
                                 type="number"
                                 label="$"
                         />
                       </div>
                       <div class="col">
                         <hwa-input
                                 v-model="loan.unpaid_balance"
                                 rules="required|numeric"
                                 :show-label="false"
                                 type="number"
                                 label="$"
                         />
                       </div>
                     </div>
                   </div>
                  <div class="col-1">
                    <b-form-checkbox v-model="loan.to_be_paid_off_before_closing" />
                  </div>
                  <div class="col-2">
                    <hwa-select
                      v-model="loan.type"
                      :show-label="false"
                      label=""
                      rules="required"
                      :options="loan_types"
                    />
                  </div>
                  <div class="col-1">
                    <hwa-input
                      v-model="loan.credit_limit"
                      rules="numeric"
                      :show-label="false"
                      type="number"
                      label="$"
                    />
                  </div>
                  <div class="col-1">
                    <hwa-button
                      :icon-only="true"
                      icon="XIcon"
                      variant="flat-danger"
                      class="float-right"
                      @onClick="removePropertyLoan(index)"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <hwa-button
                      :icon-only="true"
                      icon="PlusIcon"
                      variant="outline-success"
                      class="float-right"
                      @onClick="addPropertyLoan()"
                    />
                  </div>
                </div>
              </div>
            </div>

          </b-tab>
          <template #tabs-end>
            <div class="mt-1 mr-1">
              If you are refinancing, list the property you are refinancing FIRST
            </div>
          </template>
        </b-tabs>
        <b-tabs
          align="left"
        >
          <template #tabs-start>

            <div class="mt-1 mr-1">
              <b-form-checkbox
                v-model="loanApplication.property_one_applies"
              />
            </div>
          </template>
          <b-tab>
            <template slot="title">
              <b @click="loanApplication.property_one_applies=!loanApplication.property_one_applies">3b. IF APPLICABLE, Complete Information for Additional Property</b>
            </template>
            <div v-if="loanApplication.property_one_applies">
              <div class="row">
                <div class="col-12">
                  <label for="">
                    Address
                  </label>
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.property_one_address"
                    rules="required"
                    label="Street"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.property_one_unit"
                    label="Unit#"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.property_one_city"
                    rules="required"
                    label="City"
                  />
                </div>
                <div class="col-4">
                  <hwa-select
                    v-model="loanApplication.property_one_state"
                    rules="required"
                    :options="states"
                    label="State"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.property_one_zip"
                    rules="required"
                    label="ZIP"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.property_one_country"
                    rules="required"
                    label="Country"
                  />
                </div>
              </div>
              <div class="row align-items-end">
                <div class="col-2">
                  Property Value
                </div>
                <div class="col-2"> Status
                </div>
                <div class="col-2">Intended Occupancy
                </div>
                <div class="col-2">Monthly Insurance,Taxes,
                  Association Dues, etc.
                  if not included in Monthly
                  Mortgage Payment
                </div>
                <div class="col-2">Monthly Rental
                  Income
                </div>
                <div class="col-2">For LENDER to calculate:
                  Net Monthly Rental Income
                </div>
              </div>
              <div class="row">
                <div class="col-2">
                  <hwa-input
                    v-model="loanApplication.property_one_value"
                    rules="required"
                    label="$"
                    :show-label="false"
                  />
                </div>
                <div class="col-2">
                  <hwa-select
                    v-model="loanApplication.property_one_status"
                    rules="required"
                    label=""
                    :show-label="false"
                    :options="propertyStatuses"
                  />
                </div>
                <div class="col-2">
                  <hwa-select
                    v-model="loanApplication.property_one_intended_occupancy"
                    rules="required"
                    label=""
                    :show-label="false"
                    :options="intendedOccupancies"
                  />
                </div>
                <div class="col-2">
                  <hwa-input
                    v-model="loanApplication.property_one_monthly_insurance"
                    rules="required|numeric"
                    label="$"
                    :show-label="false"
                  />
                </div>
                <div class="col-2">
                  <hwa-input
                    v-model="loanApplication.property_one_monthly_rental_income"
                    :rules="`${loanApplication.property_one_intended_occupancy==='Investment'?'required':''}|numeric`"
                    label="$"
                    :show-label="false"
                  />
                </div>
                <div class="col-2">
                  <hwa-input
                    v-model="loanApplication.property_one_net_monthly_rental_income"
                    rules="required"
                    label="$"
                    :show-label="false"
                    :read-only="true"
                  />
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-12  mb-2 d-flex">

                  <b-form-checkbox
                    v-model="loanApplication.property_one_loan_applies"
                  >
                    <b>Mortgage Loans on this Property</b>
                  </b-form-checkbox>
                </div>
              </div>
              <div v-if="loanApplication.property_one_loan_applies">
                <div class="row align-items-end">
                  <div class="col-2">
                    Creditor Name
                  </div>
                  <div class="col-2">
                    Account Number
                  </div>
                 <div class="col-3">
                   <div class="row">
                     <div class="col">Monthly
                       Mortgage
                       Payment
                     </div>
                     <div class="col">
                       Unpaid Balance
                     </div>
                   </div>
                 </div>
                  <div class="col-1">To be paid off at
                    or before closing
                  </div>
                  <div class="col-2">Type: FHA, VA,
                    Conventional,
                    USDA-RD, Other
                  </div>
                  <div class="col-2">
                    Credit Limit
                    (if applicable)
                  </div>
                </div>
                <div
                  v-for="(loan,index) in loanApplication.property_one_loans"
                  :key="index"
                  class="row align-items-center"
                >
                  <div class="col-2">
                    <hwa-input
                      v-model="loan.creditor_name"
                      rules="required"
                      :show-label="false"
                      label=""
                    />
                  </div>
                  <div class="col-2">
                    <hwa-input
                      v-model="loan.account_number"
                      rules="required|integer"
                      :show-label="false"
                      label=""
                    />
                  </div>
                  <div class="col-3">
                    <div class="row">
                      <div class="col">
                        <hwa-input
                          v-model="loan.monthly_mortgage_payment"
                          rules="required|numeric"
                          :show-label="false"
                          type="number"
                          label="$"
                        />
                      </div>
                      <div class="col">
                        <hwa-input
                          v-model="loan.unpaid_balance"
                          rules="required|numeric"
                          :show-label="false"
                          type="number"
                          label="$"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-1">
                    <b-form-checkbox v-model="loan.to_be_paid_off_before_closing" />
                  </div>
                  <div class="col-2">
                    <hwa-select
                      v-model="loan.type"
                      :show-label="false"
                      label=""
                      rules="required"
                      :options="loan_types"
                    />
                  </div>
                  <div class="col-1">
                    <hwa-input
                      v-model="loan.credit_limit"
                      rules="numeric"
                      :show-label="false"
                      type="number"
                      label="$"
                    />
                  </div>
                  <div class="col-1">
                    <hwa-button
                      :icon-only="true"
                      icon="XIcon"
                      variant="flat-danger"
                      class="float-right"
                      @onClick="removePropertyOneLoan(index)"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <hwa-button
                      :icon-only="true"
                      icon="PlusIcon"
                      variant="outline-success"
                      class="float-right"
                      @onClick="addPropertyOneLoan()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-tab>

        </b-tabs>
        <b-tabs
          align="left"
        >
          <template #tabs-start>

            <div class="mt-1 mr-1">
              <b-form-checkbox
                v-model="loanApplication.property_two_applies"
              />
            </div>
          </template>
          <b-tab>
            <template slot="title">
              <b @click="loanApplication.property_two_applies=!loanApplication.property_two_applies">3c. IF APPLICABLE, Complete Information for Additional Property</b>
            </template>
            <div v-if="loanApplication.property_two_applies">
              <div class="row">
                <div class="col-12">
                  <label for="">
                    Address
                  </label>
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.property_two_address"
                    rules="required"
                    label="Street"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.property_two_unit"
                    label="Unit#"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.property_two_city"
                    rules="required"
                    label="City"
                  />
                </div>
                <div class="col-4">
                  <hwa-select
                    v-model="loanApplication.property_two_state"
                    rules="required"
                    :options="states"
                    label="State"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.property_two_zip"
                    rules="required"
                    label="ZIP"
                  />
                </div>
                <div class="col-4">
                  <hwa-input
                    v-model="loanApplication.property_two_country"
                    rules="required"
                    label="Country"
                  />
                </div>
              </div>
              <div class="row align-items-end">
                <div class="col-2">
                  Property Value
                </div>
                <div class="col-2"> Status
                </div>
                <div class="col-2">Intended Occupancy
                </div>
                <div class="col-2">Monthly Insurance,Taxes,
                  Association Dues, etc.
                  if not included in Monthly
                  Mortgage Payment
                </div>
                <div class="col-2">Monthly Rental
                  Income
                </div>
                <div class="col-2">For LENDER to calculate:
                  Net Monthly Rental Income
                </div>
              </div>
              <div class="row">
                <div class="col-2">
                  <hwa-input
                    v-model="loanApplication.property_two_value"
                    rules="required"
                    label="$"
                    :show-label="false"
                  />
                </div>
                <div class="col-2">
                  <hwa-select
                    v-model="loanApplication.property_two_status"
                    rules="required"
                    label=""
                    :show-label="false"
                    :options="propertyStatuses"
                  />
                </div>
                <div class="col-2">
                  <hwa-select
                    v-model="loanApplication.property_two_intended_occupancy"
                    rules="required"
                    label=""
                    :show-label="false"
                    :options="intendedOccupancies"
                  />
                </div>
                <div class="col-2">
                  <hwa-input
                    v-model="loanApplication.property_two_monthly_insurance"
                    rules="required|numeric"
                    label="$"
                    :show-label="false"
                  />
                </div>
                <div class="col-2">
                  <hwa-input
                    v-model="loanApplication.property_two_monthly_rental_income"
                    :rules="`${loanApplication.property_two_intended_occupancy==='Investment'?'required':''}|numeric`"
                    label="$"
                    :show-label="false"
                  />
                </div>
                <div class="col-2">
                  <hwa-input
                    v-model="loanApplication.property_two_net_monthly_rental_income"
                    rules="required"
                    label="$"
                    :show-label="false"
                    :read-only="true"
                  />
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-12  mt-1 d-flex">
                  <b-form-checkbox
                    v-model="loanApplication.property_two_loan_applies"
                  >
                    <b>Mortgage Loans on this Property</b>
                  </b-form-checkbox>
                </div>
              </div>
              <div v-if="loanApplication.property_two_loan_applies">
                <div class="row align-items-end">
                  <div class="col-2">
                    Creditor Name
                  </div>
                  <div class="col-2">
                    Account Number
                  </div>
                  <div class="col-3">
                    <div class="row">
                      <div class="col">Monthly
                        Mortgage
                        Payment
                      </div>
                      <div class="col">
                        Unpaid Balance
                      </div>
                    </div>
                  </div>
                  <div class="col-1">To be paid off at
                    or before closing
                  </div>
                  <div class="col-2">Type: FHA, VA,
                    Conventional,
                    USDA-RD, Other
                  </div>
                  <div class="col-2">
                    Credit Limit
                    (if applicable)
                  </div>
                </div>
                <div
                  v-for="(loan,index) in loanApplication.property_two_loans"
                  :key="index"
                  class="row align-items-center"
                >
                  <div class="col-2">
                    <hwa-input
                      v-model="loan.creditor_name"
                      rules="required"
                      :show-label="false"
                      label=""
                    />
                  </div>
                  <div class="col-2">
                    <hwa-input
                      v-model="loan.account_number"
                      rules="required|integer"
                      :show-label="false"
                      label=""
                    />
                  </div>
                  <div class="col-3">
                    <div class="row">
                      <div class="col">
                        <hwa-input
                          v-model="loan.monthly_mortgage_payment"
                          rules="required|numeric"
                          :show-label="false"
                          label="$"
                          type="number"
                        />
                      </div>
                      <div class="col">
                        <hwa-input
                          v-model="loan.unpaid_balance"
                          rules="required|numeric"
                          :show-label="false"
                          type="number"
                          label="$"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-1">
                    <b-form-checkbox v-model="loan.to_be_paid_off_before_closing" />
                  </div>
                  <div class="col-2">
                    <hwa-select
                      v-model="loan.type"
                      :show-label="false"
                      label=""
                      rules="required"
                      :options="loan_types"
                    />
                  </div>
                  <div class="col-1">
                    <hwa-input
                      v-model="loan.credit_limit"
                      rules="numeric"
                      :show-label="false"
                      type="number"
                      label="$"
                    />
                  </div>
                  <div class="col-1">
                    <hwa-button
                      :icon-only="true"
                      icon="XIcon"
                      variant="flat-danger"
                      class="float-right"
                      @onClick="removePropertyTwoLoan(index)"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <hwa-button
                      :icon-only="true"
                      icon="PlusIcon"
                      variant="outline-success"
                      class="float-right"
                      @onClick="addPropertyTwoLoan()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-tab>

        </b-tabs>
        <div
          v-if="$store.getters['auth/isStaff']"
          class="row"
        >
          <div class="col-12 mt-1">
            <strong>Staff Comments</strong>
          </div>
          <div class="col-12">
            <hwa-select
              v-model="loanApplication.section_three_status"
              placeholder="Select Section Status"
              :options="sectionStatuses"
            />
          </div>
          <div class="col-12">
            <hwa-text-area
              v-model="loanApplication.section_three_comments"
              placeholder="Comments"
            />
          </div>
        </div>
        <div
          v-if="canUpdate"
          class="row mt-1"
        >
          <div class="col-12">
            <hwa-button
              class="float-right"
              label="Save Changes"
              :loading="saving"
              @onClick="saveChangesToServer()"
            />
          </div>
        </div>
      </div>
    </app-collapse-item>

  </app-collapse>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BTabs, BTab, BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import showToast from '@/mixins/showToast'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaSelect from '@/layouts/components/HwaSelect.vue'
import appMixin from '@/views/app/client/applications/appMixin'
import HwaTextArea from '@/layouts/components/HwaTextArea.vue'

export default {
  name: 'SectionThree',
  components: {
    HwaTextArea,
    HwaSelect,
    HwaInput,
    HwaButton,
    AppCollapse,
    AppCollapseItem,
    BTabs,
    BTab,
    BFormCheckbox,
    BAlert,
  },
  mixins: [showToast, appMixin],
  data: () => ({
    loanApplication: {},
    loan_types: ['Conventional', 'FHA', 'USDA-RA', 'VA', 'Other'],
    propertyStatuses: [
      'Pending Sale',
      'Retained',
      'Sold',
    ],
    intendedOccupancies: [
      'Investment',
      'Primary Residence',
      'Second Home',
      'Other',
    ],
  }),
  computed: {
    total() {
      return ((+this.loanApplication.property_monthly_rental_income) - (+this.loanApplication.property_monthly_insurance))
    },
    totalOne() {
      return ((+this.loanApplication.property_one_monthly_rental_income) - (+this.loanApplication.property_one_monthly_insurance))
    },
    totalTwo() {
      return ((+this.loanApplication.property_two_monthly_rental_income) - (+this.loanApplication.property_two_monthly_insurance))
    },
    propertyOneApplies() {
      return this.loanApplication.property_one_applies
    },
    propertyTwoApplies() {
      return this.loanApplication.property_two_applies
    },
    ownProperty() {
      return this.loanApplication.i_own_a_property
    },
    canUpdate() {
      return (this.application?.status === 'Started'
          || this.application?.section_three_status === 'Need more data'
          || this.application?.section_three_status === 'Has errors')
          && !this.$store.getters['auth/isStaff']
    },
    application() {
      return this.$store.getters['auth/loanApplication']
    },
  },
  watch: {
    total(total) {
      this.loanApplication.property_net_monthly_rental_income = this.parseToFloat(total)
    },
    totalOne(total) {
      this.loanApplication.property_one_net_monthly_rental_income = this.parseToFloat(total)
    },
    totalTwo(total) {
      this.loanApplication.property_two_net_monthly_rental_income = this.parseToFloat(total)
    },
    loanApplication: {
      handler(newVal, oldVal) {
        this.$store.commit('auth/setLoanApplication', newVal)
      },
      deep: true,
    },
    ownProperty(own) {
      if (!own) {
        this.loanApplication.property_loan_applies = false
        this.loanApplication.property_one_applies = false
        this.loanApplication.property_two_applies = false
      }
    },
    propertyOneApplies(applies) {
      if (!applies) {
        this.loanApplication.property_one_loan_applies = false
      }
    },
    propertyTwoApplies(applies) {
      if (!applies) {
        this.loanApplication.property_two_loan_applies = false
      }
    },
  },
  mounted() {
    this.loanApplication = this.application
  },
  methods: {
    addPropertyLoan() {
      this.loanApplication.property_loans.push({
        creditor_name: null,
        account_number: null,
        monthly_mortgage_payment: null,
        unpaid_balance: null,
        type: null,
        credit_limit: null,
        to_be_paid_off_before_closing: false,
      })
    },
    removePropertyLoan(index) {
      this.loanApplication.property_loans.splice(index, 1)
    },
    addPropertyOneLoan() {
      this.loanApplication.property_one_loans.push({
        creditor_name: null,
        account_number: null,
        monthly_mortgage_payment: null,
        unpaid_balance: null,
        type: null,
        credit_limit: null,
        to_be_paid_off_before_closing: false,
      })
    },
    removePropertyOneLoan(index) {
      this.loanApplication.property_one_loans.splice(index, 1)
    },
    addPropertyTwoLoan() {
      this.loanApplication.property_two_loans.push({
        creditor_name: null,
        account_number: null,
        monthly_mortgage_payment: null,
        unpaid_balance: null,
        type: null,
        credit_limit: null,
        to_be_paid_off_before_closing: false,
      })
    },
    removePropertyTwoLoan(index) {
      this.loanApplication.property_two_loans.splice(index, 1)
    },
  },
}
</script>

<style scoped>

</style>
