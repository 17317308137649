<template>
  <app-collapse
    accordion
    type=""
  >

    <app-collapse-item
      title="Section 6: Acknowledgments and Agreements."
    >
      <p>
        This section tells you about your legal obligations when
        you sign this application.
      </p>
      <b-tabs
        align="left"
      >
        <b-tab
          title="Acknowledgments and Agreements"
        >
          <div class="row">
            <div class="col-6">
              <strong>Definitions</strong>
              <p /><p>
                • "Lender" includes the Lender’s agents, service providers, and any of
                their successors and assigns.
              </p>
              <p>
                • "Other Loan Participants" includes (i) any actual or potential owners of
                a loan resulting from this application (the “Loan”), (ii) acquirers of
                any beneficial or other interest in the Loan, (iii) any mortgage insurer,
                (iv) any guarantor, (v) any servicer of the Loan, and (vi) any of these
                parties' service providers, successors or assigns.
              </p>
              <p>
                <strong>I agree to, acknowledge, and represent the following:</strong>
              </p>
              <p><strong>(1) The Complete Information for this Application</strong></p>
              <p>• The information I have provided in this application is true, accurate,
                and complete as of the date I signed this application.</p>
              <p>
                • If the information I submitted changes or I have new information
                before closing of the Loan, I must change and supplement this
                application, including providing any updated/supplemented real
                estate sales contract.
              </p>
              <p>
                • For purchase transactions: The terms and conditions of any real
                estate sales contract signed by me in connection with this application
                are true, accurate, and complete to the best of my knowledge and
                belief. I have not entered into any other agreement, written or oral, in
                connection with this real estate transaction.
              </p>
              <p>• The Lender and Other Loan Participants may rely on the information
                contained in the application before and after closing of the Loan.</p>
              <p>
                • Any intentional or negligent misrepresentation of information may
                result in the imposition of:
              </p>
              <ul>
                <li>
                  (a) civil liability on me, including monetary damages, if a
                  person suffers any loss because the person relied on any
                  misrepresentation that I have made on this application, and/or
                </li>
                <li>
                  (b) criminal penalties on me including, but not limited to, fine or
                  imprisonment or both under the provisions of Federal law
                  (18 U.S.C. §§ 1001 et seq.).
                </li>
              </ul>

              <p><strong>(2) The Property’s Security</strong></p>
              <p>The Loan I have applied for in this application will be secured by
                a mortgage or deed of trust which provides the Lender a security
                interest in the property described in this application.</p>
              <p><strong>(3) The Property’s Appraisal, Value, and Condition</strong></p>
              <p>
                • Any appraisal or value of the property obtained by the Lender is
                for use by the Lender and Other Loan Participants.
              </p>
              <p>
                • The Lender and Other Loan Participants have not made any
                representation or warranty, express or implied, to me about the
                property, its condition, or its value.
              </p>
              <p><strong>(4) Electronic Records and Signatures</strong></p>
              <p>• The Lender and Other Loan Participants may keep any paper record
                and/or electronic record of this application, whether or not the Loan
                is approved.</p>
              </p>
            </div>
            <div class="col-6">
              <p /><p>
                • If this application is created as (or converted into) an “electronic
                application”, I consent to the use of “electronic records” and
                “electronic signatures” as the terms are defined in and governed by
                applicable Federal and/or state electronic transactions laws.
              </p>
              <p>• I intend to sign and have signed this application either using my:</p>
              <ul>
                <li>(a) electronic signature; or</li>
                <li>
                  (b) a written signature and agree that if a paper version of this
                  application is converted into an electronic application, the
                  application will be an electronic record, and the representation
                  of my written signature on this application will be my binding
                  electronic signature.
                </li>
              </ul>
              <p>• I agree that the application, if delivered or transmitted to the Lender
                or Other Loan Participants as an electronic record with my electronic
                signature, will be as effective and enforceable as a paper application
                signed by me in writing.</p>
              <p><strong>(5) Delinquency</strong></p>
              <p>• The Lender and Other Loan Participants may report information about
                my account to credit bureaus. Late payments, missed payments, or
                other defaults on my account may be reflected in my credit report and
                will likely affect my credit score.</p>
              <p>• If I have trouble making my payments I understand that I may contact
                a HUD-approved housing counseling organization for advice about
                actions I can take to meet my mortgage obligations.</p>
              <p><strong>(6) Authorization for Use and Sharing of Information</strong></p>
              <p>
                By signing below, in addition to the representations and agreements
                made above, I expressly authorize the Lender and Other Loan
                Participants to obtain, use, and share with each other (i) the loan
                application and related loan information and documentation, (ii) a
                consumer credit report on me, and (iii) my tax return information, as
                necessary to perform the actions listed below, for so long as they have
                an interest in my loan or its servicing:
              </p>
              <ul>
                <li>(a) process and underwrite my loan;</li>
                <li>(b) verify any data contained in my consumer credit report, my
                  loan application and other information supporting my loan
                  application;</li>
                <li>
                  (c) inform credit and investment decisions by the Lender
                  and Other Loan Participants;
                </li>
                <li>

                  (d) perform audit, quality control, and legal compliance analysis
                  and reviews;
                </li>
                <li>
                  (e) perform analysis and modeling for risk assessments;
                </li>
                <li>

                  (f) monitor the account for this loan for potential delinquencies and
                  determine any assistance that may be available to me; and
                </li>
                <li>
                  (g) other actions permissible under applicable law.
                </li>
              </ul>
              </p>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import showToast from '@/mixins/showToast'

export default {
  name: 'SectionSix',
  components: {
    AppCollapse,
    AppCollapseItem,
    BTabs,
    BTab,
  },
  mixins: [showToast],
  methods: {
  },
}
</script>

<style scoped>

</style>
