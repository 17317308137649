<template>
  <app-collapse
    accordion
    type=""
  >
    <app-collapse-item
      title="Section 9: Loan Originator Information."
    >
      <b-overlay :show="$can('hwa','view-mortgage-applications')" :opacity="0.5">
        <template #overlay>
          <div />
        </template>
        <div>
          <p>
            To be completed by your Loan Originator.
          </p>
          <b-tabs
            align="left"
          >
            <b-tab
              title="Loan Originator Information"
            >
              <div class="row">
                <div class="col-6">
                  <hwa-select
                    @input="handleOrganizationChange"
                    :options="companies"
                    value-name="id"
                    label-name="name"
                    label="Loan Originator Organization Name"
                  />
                </div>
                <div class="col-6">
                  <hwa-input
                    v-model="loanApplication.organization_address"
                    label="Address"
                    :read-only="readOnly"
                  />
                </div>
                <div class="col-6">
                  <hwa-input
                  :read-only="readOnly"
                    v-model="loanApplication.loan_originator"
                    label="Loan Originator Organization NMLSR ID#"
                  />
                </div>
                <div class="col-6">
                  <hwa-input
                  :read-only="readOnly"
                    v-model="loanApplication.state_license"
                    label="State License ID#"
                  />
                </div><div class="col-6">
                  <hwa-input
                  :read-only="readOnly"
                    v-model="loanApplication.loan_originator_name"
                    label="Loan Originator Name"
                  />
                </div><div class="col-6">
                  <hwa-input
                  :read-only="readOnly"
                    v-model="loanApplication.loan_originator_one"
                    label="Loan Originator NMLSR ID#"
                  />
                </div>
                <div class="col-6">
                  <hwa-input
                  :read-only="readOnly"
                    v-model="loanApplication.state_license_one"
                    label="State License ID#"
                  />
                </div>
                <div class="col-6">
                  <hwa-input
                  :read-only="readOnly"
                    v-model="loanApplication.organization_email"
                    label="Email"
                  />
                </div>
                <div class="col-6">
                  <hwa-input
                    :read-only="readOnly"
                    v-model="loanApplication.organization_phone"
                    label="Phone"
                    :masked="false"
                    placeholder="###-###-####"
                  />
                </div>
              </div>
            </b-tab></b-tabs></div></b-overlay>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BTabs, BTab, BOverlay,
} from 'bootstrap-vue'
import showToast from '@/mixins/showToast'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaSelect from '@/layouts/components/HwaSelect.vue'
export default {
  name: 'SectionNine',
  components: {
    HwaInput,
    AppCollapse,
    AppCollapseItem,
    BTabs,
    BTab,
    BOverlay,
    HwaSelect,
  },
  mixins: [showToast],
  data: () => ({
    companies: [],
    readOnly: true,
    loanApplication: {},
    liens: ['First Lien', 'Subordinate Lien'],
    occupancies: [
      'Primary Residence',
      'Second Home',
      'Investment Property',
    ],
    loanPurposes: [
      'Purchase',
      'Refinance',
      'Other (specify)',
    ],
    depositTypes: ['Deposited', 'Not Deposited'],
  }),
  computed: {
    application() {
      return this.$store.getters['auth/loanApplication']
    },
    loanOriginator() {
      return this.$store.getters['auth/loanOriginator']
    },
  },
  watch: {
    loanApplication: {
      handler(newVal, oldVal) {
        this.$store.commit('auth/setLoanApplication', newVal)
      },
      deep: true,
    },
  },
  mounted() {
    this.getCompanies()
    this.loanApplication = this.application
    this.loanApplication.loan_originator_name = this.loanOriginator?.full_name
    this.loanApplication.loan_originator_one = this.loanOriginator?.nmlsr_id_no
    this.loanApplication.state_license_one = this.loanOriginator?.state_license_id
    this.loanApplication.organization_email = this.loanOriginator?.email
    this.loanApplication.organization_phone = this.loanOriginator?.phone
  },
  methods: {
    getCompanies() {
      this.$http.get('/api/companies')
        .then(res => {
          this.companies = res.data
        })
        .catch(err => {
          this.showError('Can not load companies')
        })
    },
    handleOrganizationChange(orgId){
      const org = this.companies.find((o)=>+o.id===+orgId)
      if(org){
        this.loanApplication.organization_name = org.name
      this.loanApplication.organization_address = org.address
      this.loanApplication.loan_originator = org.nmlsr_id_no
      this.loanApplication.state_license = org.state_license_id
      }else{
        this.loanApplication.organization_name = null;
      this.loanApplication.organization_address = null;
      this.loanApplication.loan_originator = null;
      this.loanApplication.state_license = null;
      }

    },
  }
}
</script>

<style scoped>

</style>
