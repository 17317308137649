import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
  methods: {
    confirmAction(message = 'Are you sure ?',title="Please Confirm") {
      return this.$bvModal
        .msgBoxConfirm(message, {
          title,
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
    },
  },
}
