<template>
  <validation-provider
    #default="{ errors }"
    :vid="name"
    :name="formatName(name)"
    :rules="rules"
  >
    <b-form-group>
      <label
        v-if="label"
        :for="name"
      >{{ label }}</label>
      <b-form-select
        :id="name"
        v-model="selected"
        :name="name"
        :disabled="disabled"
      >
        <b-form-select-option :value="null">
          {{ placeholder }}
        </b-form-select-option>
        <b-form-select-option
          v-for="(option,i) in options"
          :key="i"
          :value="getValue(option)"
        >
          {{ getLabel(option) }}
        </b-form-select-option>
      </b-form-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { BFormSelect, BFormGroup, BFormSelectOption } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'HwaSelect',
  components: {
    BFormSelect,
    BFormGroup,
    BFormSelectOption,
    ValidationProvider,
  },
  props: {
    options: { type: Array, default: () => [] },
    name: {
      type: String,
      default: 'input',
    },
    valueName: {
      type: String,
      default: 'value',
    },
    labelName: {
      type: String,
      default: 'label',
    },
    label: {
      type: String,
      default: null,
    },
    allowNullValues: {
      type: Boolean,
      default: false,
    },
    immediate: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Select One',
    },
    rules: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    // selected() {
    //   return this.value
    // },
  },
  watch: {
    selected(val) {
      this.handleSelectChange(val)
    },
    value(val) {
      this.selected = val
    },
  },
  mounted() {
    this.selected = this.value
  },
  methods: {
    formatName(name) {
      return name.replaceAll('_', ' ')
    },
    getValue(option) {
      if (this.allowNullValues && !option[this.valueName]) {
        return null
      }
      return option[this.valueName] || option
    },
    getLabel(option) {
      return option[this.labelName] || option
    },
    handleSelectChange(e) {
      this.$emit('input', e)
    },
  },
}
</script>
<style scoped>
  select {
    color:black;
  }
</style>
