<template>
  <app-collapse
    accordion
    type=""
  >
    <app-collapse-item
      title="Section 4: Loan and Property Information."
    >
      <b-alert
        :variant="getVariant(loanApplication.section_four_status)"
        :show="loanApplication.section_four_status!==null"
      >
        <h4 class="alert-heading">
          {{ loanApplication.section_four_status }}
        </h4>
        <div class="alert-body">
          <span>{{ loanApplication.section_four_comments||'Section '+loanApplication.section_four_status }}</span>
        </div>
      </b-alert>
      <p>
        This section asks about the loan’s purpose and the property you
        want to purchase or refinance.
      </p>
      <b-tabs
        align="left"
      >
        <b-tab
          title="4a. Loan and Property Information"
        >
          <div class="row">
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.loan_amount"
                rules="required|numeric"
                placeholder="$"
                label="Loan Amount"
              />
            </div>
            <div class="col-4">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="loan_purpose"
                rules="required"
              >
                <b-form-group label="Loan Purpose">
                  <b-form-radio-group
                    v-model="loanApplication.loan_purpose"
                    :options="loanPurposes"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div
              class="col-4"
              style="margin-top: -6px"
            >
              <hwa-input
                v-model="loanApplication.purpose"
                :read-only="!otherLoanPurpose"
                :rules="otherLoanPurpose?'required':''"
                label="Specify (Loan Purpose)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label for="">
                Property Address
              </label>
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.property_address_street"
                rules="required"
                label="Street"
              />
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.property_address_unit"
                label="Unit#"
              />
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.property_address_city"
                rules="required"
                label="City"
              />
            </div>
            <div class="col-4">
              <hwa-select
                v-model="loanApplication.property_address_state"
                rules="required"
                :options="states"
                label="State"
              />
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.property_address_zip"
                rules="required"
                label="ZIP"
              />
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.property_address_country"
                rules="required"
                label="Country"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.property_number_of_units"
                rules="required"
                label="Number of Units"
              />
            </div>
            <div class="col-4">
              <hwa-input
                v-model="loanApplication.loan_property_value"
                rules="required|numeric"
                label="Property Value $"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="occupancy"
                rules="required"
              >
                <b-form-group label="Occupancy">
                  <b-form-radio-group
                    v-model="loanApplication.occupancy"
                    :options="occupancies"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-4 mt-2 d-flex">
              <span class="mr-1"> FHA Secondary Residence</span>
              <b-form-checkbox v-model="loanApplication.fha_sec_residence" />
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              1. Mixed-Use Property. If you will occupy the property, will you set aside space within the property to operate
              your own business? <span style="font-style: italic;">(e.g., daycare facility, medical office, beauty/barber shop)</span>
            </div>
            <div class="col-3">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="use_property_space_for_business"
                rules="required"
              >
                <b-form-group label="">
                  <b-form-radio-group
                    v-model="loanApplication.use_property_space_for_business"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-9">
              2. Manufactured Home. Is the property a manufactured home? <span style="font-style: italic;">(e.g., a factory built dwelling built on a permanent chassis)</span>
            </div>
            <div class="col-3">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="is_manufactured_home"
                rules="required"
              >
                <b-form-group label="">
                  <b-form-radio-group
                    v-model="loanApplication.is_manufactured_home"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <b-tabs
        align="left"
      >
        <template #tabs-start>
          <div class="mt-1 mr-1">
            <b-form-checkbox
              v-model="loanApplication.other_new_mortgage_applies"
            />
          </div>
        </template>
        <b-tab>
          <template slot="title">
            <b @click="loanApplication.other_new_mortgage_applies=!loanApplication.other_new_mortgage_applies">
              4b. Other New Mortgage Loans on the Property You are Buying or Refinancing
            </b>
          </template>
          <div v-if="loanApplication.other_new_mortgage_applies">
            <div class="row">
              <div class="col-3">
                Creditor Name
              </div>
              <div class="col-3">
                Lien Type
              </div>
              <div class="col-2">
                Monthly Payment
              </div>
              <div class="col-2">Loan Amount/
                Amount to be Drawn</div>
              <div class="col-2">Credit Limit
                (if applicable)</div>
            </div>
            <div class="row">
              <div class="col-2">
                <hwa-input
                  v-model="loanApplication.creditor_name_one"
                  rules="required"
                  :show-label="false"
                  label=""
                />
              </div>
              <div
                class="col-4"
                style="margin-top: 5px;"
              >
                <validation-provider
                  #default="{ errors }"
                  :immediate="true"
                  name="input"
                  rules="required"
                >
                  <b-form-group label="">
                    <b-form-radio-group
                      v-model="loanApplication.list_type_one"
                      :options="liens"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="col-2">
                <hwa-input
                  v-model="loanApplication.loan_monthly_payment_one"
                  rules="required"
                  :show-label="false"
                  label="$"
                />
              </div>
              <div class="col-2">
                <hwa-input
                  v-model="loanApplication.amount_to_be_withdrawn_one"
                  rules="required|numeric"
                  :show-label="false"
                  label="$"
                />
              </div>
              <div class="col-2">
                <hwa-input
                  v-model="loanApplication.loan_credit_limit_one"
                  rules="required|numeric"
                  :show-label="false"
                  label="$"
                />
              </div>
            </div>
            <div class="row" v-if="extraLoan">
              <div class="col-2">
                <hwa-input
                  v-model="loanApplication.creditor_name_two"
                  :show-label="false"
                  label=""
                />
              </div>
              <div
                class="col-4"
                style="margin-top: 5px;"
              >
                <validation-provider
                  #default="{ errors }"
                  :immediate="true"
                  name="input"
                >
                  <b-form-group label="">
                    <b-form-radio-group
                      v-model="loanApplication.list_type_two"
                      :options="liens"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="col-2">
                <hwa-input
                  v-model="loanApplication.loan_monthly_payment_two"
                  rules="numeric"
                  :show-label="false"
                  label="$"
                />
              </div>
              <div class="col-2">
                <hwa-input
                  v-model="loanApplication.amount_to_be_withdrawn_two"
                  rules="numeric"
                  :show-label="false"
                  label="$"
                />
              </div>
              <div class="col-2">
                <hwa-input
                  v-model="loanApplication.loan_credit_limit_two"
                  rules="numeric"
                  :show-label="false"
                  label="$"
                />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 float-right">
                <b-form-checkbox v-model="extraLoan">
                  Add Extra
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </b-tab>

      </b-tabs>
      <b-tabs
        align="left"
      >
        <template #tabs-start>
          <div class="mt-1 mr-1">
            <b-form-checkbox
              v-model="loanApplication.rental_income_applies"
            />
          </div>
        </template>
        <b-tab>
          <template slot="title">
            <b @click="loanApplication.rental_income_applies=!loanApplication.rental_income_applies">4c. Rental Income on the Property You Want to Purchase</b>
          </template>
          <div v-if="loanApplication.rental_income_applies">
            <div class="row mb-1">
              <div class="col-7">
                Complete if the property is a 2-4 Unit Primary Residence or an Investment Property
              </div>
              <div class="col-3">
                Amount
              </div>
            </div>
            <div class="row">
              <div class="col-7">
                Expected Monthly Rental Income
              </div>
              <div class="col-3">
                <hwa-input
                  v-model="loanApplication.expected_monthly_rental_income"
                  rules="required|numeric"
                  :show-label="false"
                  label="$"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-7">
                For LENDER to calculate: Expected Net Monthly Rental Income
              </div>
              <div class="col-3">
                <hwa-input
                  v-model="loanApplication.expected_net_monthly_rental_income"
                  rules="numeric"
                  :show-label="false"
                  label="$"
                />
              </div>
            </div>
          </div>
        </b-tab>
        <template #tabs-end>
          <div class="row">
            <div class="mt-1 ml-2">
              For Purchase Only
            </div>
          </div>
        </template>
      </b-tabs>
      <b-tabs
        align="left"
      >
        <template #tabs-start>
          <div class="mt-1 mr-1">
            <b-form-checkbox v-model="loanApplication.gifts_applies" />
          </div>
        </template>
        <b-tab>
          <template slot="title">
            <b @click="loanApplication.gifts_applies=!loanApplication.gifts_applies">4d. Gifts or Grants You Have Been Given or Will Receive for this Loan</b>
          </template>
          <div v-if="loanApplication.gifts_applies">
            <div class="row">
              <div class="col-12">
                Include all gifts and grants below. Under Source, choose from the sources listed here:
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                • Community Nonprofit
              </div>
              <div class="col-3">
                • Employer
              </div>

              <div class="col-3">
                • Federal Agency
              </div>
              <div class="col-3">
                • Local Agency
              </div>

              <div class="col-3">
                • Relative
              </div>
              <div class="col-3">
                • Religious Nonprofit
              </div>

              <div class="col-3">
                • State Agency
              </div>
              <div class="col-3">
                • Unmarried Partner
              </div>

              <div class="col-3">
                • Lender
              </div>
              <div class="col-3">
                • Other
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-3">
                Asset Type
              </div>
              <div class="col-4">
                Deposited/Not Deposited
              </div>
              <div class="col-3">
                Source – use list above
              </div>
              <div class="col-2">
                Cash or Market Value
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <hwa-select
                  v-model="loanApplication.gifts_asset_type_one"
                  rules="required"
                  :show-label="false"
                  label=""
                  :options="assetTypes"
                />
              </div>
              <div
                class="col-4"
                style="margin-top: 5px;"
              >
                <validation-provider
                  #default="{ errors }"
                  :immediate="true"
                  name="deposit type"
                  rules="required"
                >
                  <b-form-group label="">
                    <b-form-radio-group
                      v-model="loanApplication.gifts_deposit_type_one"
                      :options="depositTypes"
                    />
                  </b-form-group>
                  <small>{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="col-3">
                <hwa-select
                  v-model="loanApplication.gifts_sources_one"
                  rules="required"
                  :show-label="false"
                  label=""
                  :options="incomeSources"
                />
              </div>
              <div class="col-2">
                <hwa-input
                  v-model="loanApplication.gifts_cash_value_one"
                  rules="required|numeric"
                  :show-label="false"
                  label="$"
                />
              </div>
            </div>
            <div
              v-if="extraGifts"
              class="row"
            >
              <div class="col-3">
                <hwa-select
                  v-model="loanApplication.gifts_asset_type_two"
                  :show-label="false"
                  label=""
                  :options="assetTypes"
                />
              </div>
              <div
                class="col-4"
                style="margin-top: 5px;"
              >
                <validation-provider
                  #default="{ errors }"
                  :immediate="true"
                  name="deposit type"
                >
                  <b-form-group label="">
                    <b-form-radio-group
                      id="gifts_deposit"
                      v-model="loanApplication.gifts_deposit_type_two"
                      :options="depositTypes"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="col-3">
                <hwa-select
                  v-model="loanApplication.gifts_sources_two"
                  :show-label="false"
                  label=""
                  :options="incomeSources"
                />
              </div>
              <div class="col-2">
                <hwa-input
                  v-model="loanApplication.gifts_cash_value_two"
                  rules="numeric"
                  :show-label="false"
                  label="$"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 float-right">
                <b-form-checkbox v-model="extraGifts">
                  Add Extra
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <div
        v-if="$store.getters['auth/isStaff']"
        class="row"
      >
        <div class="col-12 mt-1">
          <strong>Staff Comments</strong>
        </div>
        <div class="col-12">
          <hwa-select
            v-model="loanApplication.section_four_status"
            placeholder="Select Section Status"
            :options="sectionStatuses"
          />
        </div>
        <div class="col-12">
          <hwa-text-area
            v-model="loanApplication.section_four_comments"
            placeholder="Comments"
          />
        </div>
      </div>
      <div
        v-if="canUpdate"
        class="row mt-1"
      >
        <div class="col-12">
          <hwa-button
            class="float-right"
            label="Save Changes"
            :loading="saving"
            @onClick="saveChangesToServer()"
          />
        </div>
      </div>
    </app-collapse-item>

  </app-collapse>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BTabs, BTab, BFormRadioGroup, BFormGroup, BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import showToast from '@/mixins/showToast'
import HwaInput from '@/layouts/components/HwaInput.vue'
import appMixin from '@/views/app/client/applications/appMixin'
import HwaButton from '@/layouts/components/HwaButton.vue'
import HwaSelect from '@/layouts/components/HwaSelect.vue'
import HwaTextArea from '@/layouts/components/HwaTextArea.vue'

export default {
  name: 'SectionFour',
  components: {
    HwaTextArea,
    HwaSelect,
    HwaInput,
    AppCollapse,
    AppCollapseItem,
    BTabs,
    BTab,
    BFormRadioGroup,
    BFormGroup,
    BFormCheckbox,
    HwaButton,
    BAlert,
    ValidationProvider,
  },
  mixins: [showToast, appMixin],
  data: () => ({
    loanApplication: {},
    extraGifts: false,
    extraLoan: false,
    liens: ['First Lien', 'Subordinate Lien'],
    occupancies: [
      'Primary Residence',
      'Second Home',
      'Investment Property',
    ],
    incomeSources: [
      'Community Nonprofit',
      'Employer',
      'Federal Agency',
      'Local Agency',
      'Relative',
      'Religious Nonprofit',
      'State Agency',
      'Unmarried Partner',
      'Lender',
      'Other',
    ],
    loanPurposes: [
      'Purchase',
      'Refinance',
      'Other',
    ],
    depositTypes: ['Deposited', 'Not Deposited'],
    assetTypes: [
      'Cash Gift',
      'Gift of Equity',
      'Grant',
    ],

  }),
  computed: {
    otherLoanPurpose() {
      return this.loanApplication.loan_purpose === 'Other'
    },
    canUpdate() {
      return (this.application?.status === 'Started'
              || this.application?.section_four_status === 'Need more data'
              || this.application?.section_four_status === 'Has errors')
              && !this.$store.getters['auth/isStaff']
    },
    application() {
      return this.$store.getters['auth/loanApplication']
    },
  },
  watch: {
    extraGifts(checked) {
      if (!checked) {
        this.loanApplication.gifts_asset_type_two = null
        this.loanApplication.gifts_deposit_type_two = null
        this.loanApplication.gifts_sources_two = null
        this.loanApplication.gifts_cash_value_two = null
      }
    },
    extraLoan(checked) {
      if (!checked) {
        this.loanApplication.creditor_name_two = null
        this.loanApplication.list_type_two = null
        this.loanApplication.loan_monthly_payment_two = null
        this.loanApplication.amount_to_be_withdrawn_two = null
        this.loanApplication.loan_credit_limit_two = null
      }
    },
    otherLoanPurpose(other) {
      if (!other) {
        this.loanApplication.purpose = null
      }
    },
    loanApplication: {
      handler(newVal, oldVal) {
        this.$store.commit('auth/setLoanApplication', newVal)
      },
      deep: true,
    },
  },
  mounted() {
    this.loanApplication = this.application
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
