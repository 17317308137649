<template>
  <section class="mev-chat-section">
    <div class="chat-side-bar">
      <div class="top-bar">
        <b-avatar v-if="appBarData.photo !== null" :src="getImage(appBarData.photo)" />
        <b-avatar v-else :text="`${appBarData.first_name.substr(
          0,
          1
        )}${appBarData.last_name.substr(0, 1)}`" variant="info" />

        <!-- basic search -->
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input placeholder="Search" />
        </b-input-group>
      </div>
      <div class="contact-view">
        <div class="contact-heading">
          <h4 class="text-primary">Participants</h4>
        </div>
        <div class="contact-list-box">
          <div v-for="item in contacts" :key="item.id" class="contact-list"
            :class="active_contact === item.id ? 'active' : ''" @click="getMessage(item)">
            <b-avatar v-if="item.avatar !== null" :src="getImage(item.avatar)" />
            <b-avatar v-else :text="`${item.first_name.substr(0, 1)}${item.last_name.substr(
              0,
              1
            )}`" variant="success" />
            <div class="contact-content">
              <div class="name-date-view">
                <h5>{{ item.first_name }} {{ item.last_name }}</h5>
                <span class="">
                  <b-badge variant="primary" class="badge-glow">
                    {{ item.role }}
                  </b-badge></span>
              </div>
              <small>{{ date(item.created_at) }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-body-content">
      <!-- <div
        v-if="single_chats.length > 0"
        class="top-bar"
      >
        <div class="top-bar-content">
          <b-avatar
            v-if="contact.avatar !== null"
            :src="getImage(contact.avatar)"
          />
          <b-avatar
            v-else
            :text="`${contact.first_name.substr(0,1)}${contact.last_name.substr(0,1)}`"
            variant="success"
          />
          <span>{{ contact.first_name }} {{ contact.last_name }}</span>
        </div>

        <feather-icon
          class="mr-1"
          icon="SearchIcon"
        />
      </div> -->
      <div class="body-content" id="chat-body">
        <!-- <div
          v-if="chats.length < 1"
          class="chat_emptyp_data"
        >
          <div class="icon-view">  <feather-icon
            size="50"
            icon="MessageCircleIcon"
          /></div>
          <div class="text-view">
            Start Conversation
          </div>
        </div> -->
        <div v-for="item in chats" :key="item.id" class="chat-content-box">
          <div v-if="item.sender_id !== appBarData.id" class="client-chat-box">
            <b-avatar v-if="item.avatar !== null" :src="getImage(item.avatar)" />
            <b-avatar v-else :text="`${item.first_name.substr(0, 1)}${item.last_name.substr(
              0,
              1
            )}`" variant="warning" />
            <div>
              <div class="message-box">
                {{ item.message }}
              </div>
              <span class="float-left message-fullname-view">{{ item.full_name }}
                <span class="">
                  <b-badge variant="warning" class="badge-glow">
                    {{ item.role }}
                  </b-badge></span>
              </span>
            </div>
          </div>

          <div v-if="item.sender_id === appBarData.id" class="user-chat-box">
            <div>
              <div class="message-box">
                {{ item.message }}
              </div>
              <span class="float-right message-fullname-view">
                <span class="">
                  <b-badge variant="primary" class="badge-glow">
                    {{ item.role }}
                  </b-badge></span>
                {{ item.full_name }}</span>
            </div>

            <b-avatar v-if="item.avatar !== null" :src="getImage(item.avatar)" />
            <b-avatar v-else :text="`${item.first_name.substr(
              0,
              1
            )}${item.last_name.substr(0, 1)}`" variant="primary" />
          </div>
        </div>
      </div>
      <div class="form-box" v-if="$can('participate-in-mortgage-application-chats', 'hwa')">
        <b-form-input @keypress.enter="sendMessage" id="basicInput" v-model="message" placeholder="Enter message here..." />
        <div class="button-view">
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="sendMessage">
            Send Message
          </b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BAvatar,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BButton,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import chatSound from "../../../assets/audio/chat.mp3";
import { echo } from "../../../libs/socket";

export default {
  components: {
    BAvatar,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BBadge,
  },

  directives: {
    Ripple,
  },
  props: ["mortgage_id"],
  data() {
    return {
      active_contact: "",
      user: {},
      message: "",
      to: null,
      contact: {},
      chatSound,
      users_viewing_chat: [],
    };
  },
  computed: {
    mortgageId() {
      return this.$store.getters["auth/mortgageId"];
    },
    chats: {
      get() {
        return this.$store.getters["chat/getChats"];
      },
      set(value) {
        return value
      },
    },
    contacts() {
      return this.$store.getters["chat/getContacts"];
    },
    single_chats: {
      get() {
        return this.$store.state.chat.single_chats;
      },
      set(value) {
        return value;
      },
    },
    appBarData() {
      return this.$store.getters["auth/appBarData"];
    },
  },
  destroyed() {
    echo.leave(`chats.${this.mortgage_id}`);
  },
  mounted() {
    this.scrollToBottom()
      this.fetchChats();
    this.clearChat();
    this.listenForNewMessage();
  },
  methods: {
    date(data) {
      return moment(data).format("Do, MMM YYYY, h:mm a");
    },
    fetchChats() {
      this.$store.dispatch("chat/fetchChatsAndContacts", {
        mortgage_id: this.mortgage_id,
      });
      this.$store.commit("chat/clearMortgagesUnreadCount", this.mortgage_id)
    },
    clearChat() {
      this.$store.dispatch("chat/clearSingleChat");
    },
    getMessage() {
      // const data = item
      // this.contact = data
      // this.active_contact = data.id
      // if (this.appBarData.id === item.from) {
      //   this.to = data.to
      // } else {
      //   this.to = data.from
      // }
      // data.user_id = this.appBarData.id
      // this.clearChat()
      // this.$store.dispatch('chat/getSingleChat', data)
      // const chatItems = this.single_chats
      // this.single_chats = chatItems.push(message.chat)
      // const audio = new Audio(this.chatSound)
      // audio.play()
      // this.fetchChats()
      // echo.private(`chats.${this.to}`)
      //   .listen('NewChatMessage', message => {
      //     if (this.to === message.chat.from) {
      //     }
      //   })
    },
    listenForNewMessage() {
      this.$store.commit("auth/setSocketId", echo.socketId());
      if (this.mortgage_id !== null) {
        echo
          .join(`chats.${this.mortgage_id}`)
          .here((users)=>{
            this.users_viewing_chat = users
          })
          .joining((user)=>{
            this.users_viewing_chat.push(user)
          })
          .leaving((user)=>{
            this.users_viewing_chat = this.users_viewing_chat.filter((u)=>u.id!==user.id)
          })
          .listen("NewChatMessage", (e) => {
            this.$store.commit("chat/updateChats", e);
          })
      }
    },
    sendMessage() {
      if (this.message !== "") {
        this.$store.dispatch("chat/sendMessage", {
          message: this.message,
          mortgage_id: this.mortgage_id,
          users_viewing_chat: this.users_viewing_chat,
        }).then((res) => {
          this.$store.commit("chat/updateSingleChat", res.data)
        });
        const message = {
          sender_id: this.appBarData.id,
          message: this.message,
          mortgage_id: this.mortgage_id,
          avatar: this.appBarData.photo,
          role: this.appBarData.role,
          first_name: this.appBarData.first_name,
          last_name: this.appBarData.last_name,
          full_name: `${this.appBarData.first_name} ${this.appBarData.last_name}`,
        };
        const contact = {
          id: this.appBarData.id,
          avatar: this.appBarData.avatar,
          role: this.appBarData.role,
          first_name: this.appBarData.first_name,
          last_name: this.appBarData.last_name,
        }
        this.message = "";
        this.$store.commit("chat/updateChats", { chat: { contact, message } })
        this.scrollToBottom()
        const audio = new Audio(this.chatSound);
        audio.play();
      }
    },
    scrollToBottom(){
      const chatBox = document.getElementById("chat-body")
      chatBox.scrollTop = chatBox.scrollHeight - chatBox.clientHeight
    },
    getImage(image) {
      return `${process.env.VUE_APP_STORAGE_PATH}/${image}`;
    },
  },
};
</script>

<style>

</style>
