<template>
  <app-collapse
    accordion
    type=""
  >
    <app-collapse-item
      title="Section 7: Military Service."
    >
      <b-alert
        :variant="getVariant(loanApplication.section_seven_status)"
        :show="loanApplication.section_seven_status!==null"
      >
        <h4 class="alert-heading">
          {{ loanApplication.section_seven_status }}
        </h4>
        <div class="alert-body">
          <span>{{ loanApplication.section_seven_comments||'Section '+loanApplication.section_seven_status }}</span>
        </div>
      </b-alert>
      <div class="row">
        <div class="col-12">
          This section asks questions about your (or your deceased spouse's) military service.
        </div>
      </div>
      <b-tabs
        align="left"
      >
        <b-tab
          title=" Military Service of Borrower"
        >
          <div class="row">
            <div class="col-12">
              <validation-provider
                #default="{ errors }"
                :immediate="true"
                vid="military_service"
                rules="required"
              >
                <b-form-group label="Military Service – Did you (or your deceased spouse) ever serve, or are you currently serving, in the United States Armed Forces?">
                  <b-form-radio-group
                    v-model="loanApplication.military_service"
                    :options="['NO','YES']"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <b-overlay
            :show="!militaryService"
            :opacity="0.5"
          >
            <template #overlay>
              <div />
            </template>
            <div class="row">
              <div class="col-12">
                If YES, check all that apply:
              </div>
            </div>

            <div class="row">
              <div class="col-12 d-flex">
                <b-form-checkbox v-model="loanApplication.serving_on_active_duty">
                  Currently serving on active duty with projected expiration date of service/tour
                </b-form-checkbox>
                <div
                  class="ml-1"
                  style="margin-top: -7px"
                >
                  <b-overlay
                    :show="!servingOnActiveDuty"
                    :opacity="0.5"
                  >
                    <template #overlay>
                      <div />
                    </template>
                    <hwa-date-picker
                      v-model="loanApplication.duty_expiration_date"
                      :show-label="false"
                      :rules="servingOnActiveDuty?'required':''"
                    />
                  </b-overlay>
                </div>
              </div>
            </div>
            <div
              class="row"
              style="margin-top: -25px"
            >
              <div class="col-12">
                <b-form-checkbox v-model="loanApplication.retired">
                  Currently retired, discharged, or separated from service
                </b-form-checkbox>
              </div>
              <div class="col-12">
                <b-form-checkbox v-model="loanApplication.national_guard">
                  Only period of service was as a non-activated member of the Reserve or National Guard
                </b-form-checkbox>
              </div>
              <div class="col-12">
                <b-form-checkbox v-model="loanApplication.serving_spouse">
                  Surviving spouse
                </b-form-checkbox>
              </div>
            </div>
          </b-overlay>
        </b-tab>
      </b-tabs>
      <div
        v-if="$store.getters['auth/isStaff']"
        class="row"
      >
        <div class="col-12 mt-1">
          <strong>Staff Comments</strong>
        </div>
        <div class="col-12">
          <hwa-select
            v-model="loanApplication.section_seven_status"
            placeholder="Select Section Status"
            :options="sectionStatuses"
          />
        </div>
        <div class="col-12">
          <hwa-text-area
            v-model="loanApplication.section_seven_comments"
            placeholder="Comments"
          />
        </div>
      </div>
      <div
        v-if="canUpdate"
        class="row mt-1"
      >
        <div class="col-12">
          <hwa-button
            class="float-right"
            label="Save Changes"
            :loading="saving"
            @onClick="saveChangesToServer()"
          />
        </div>
      </div>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BTabs, BTab, BFormRadioGroup, BFormGroup, BFormCheckbox, BAlert, BOverlay,
} from 'bootstrap-vue'
import showToast from '@/mixins/showToast'
import HwaDatePicker from '@/layouts/components/HwaDatePicker.vue'
import appMixin from '@/views/app/client/applications/appMixin'
import HwaButton from '@/layouts/components/HwaButton.vue'
import HwaSelect from '@/layouts/components/HwaSelect'
import HwaTextArea from '@/layouts/components/HwaTextArea'

export default {
  name: 'SectionSeven',
  components: {
    HwaTextArea,
    HwaSelect,
    HwaDatePicker,
    AppCollapse,
    AppCollapseItem,
    BTabs,
    BTab,
    BFormRadioGroup,
    BFormGroup,
    BFormCheckbox,
    HwaButton,
    BAlert,
    BOverlay,
    ValidationProvider,
  },
  mixins: [showToast, appMixin],
  data: () => ({
    loanApplication: {},
  }),
  computed: {
    servingOnActiveDuty() {
      return this.loanApplication.serving_on_active_duty
    },
    militaryService() {
      return this.loanApplication.military_service === 'YES'
    },
    canUpdate() {
      return (this.application?.status === 'Started'
              || this.application?.section_seven_status === 'Need more data'
              || this.application?.section_seven_status === 'Has errors')
              && !this.$store.getters['auth/isStaff']
    },
    application() {
      return this.$store.getters['auth/loanApplication']
    },
  },
  watch: {
    servingOnActiveDuty(serving) {
      if (!serving) {
        this.loanApplication.duty_expiration_date = null
      }
    },
    militaryService(ms) {
      if (!ms) {
        this.loanApplication.serving_on_active_duty = false
        this.loanApplication.retired = false
        this.loanApplication.national_guard = false
        this.loanApplication.serving_spouse = false
      }
    },
    loanApplication: {
      handler(newVal, oldVal) {
        this.$store.commit('auth/setLoanApplication', newVal)
      },
      deep: true,
    },
  },
  mounted() {
    this.loanApplication = this.application
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
